import React, { useEffect, useState } from "react";
import { Col, Form, Row, Button } from "react-bootstrap";
import { genders, confirmMessage } from "../../utils/constants";
import Select from "react-select";
import { Link, useHistory, Prompt } from "react-router-dom";
import { ArrowIcon, TrashIcon } from "../../components/SvgIcons";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { EmptyLocalStorage } from "../../services/auth/auth.service";
import { FadeLoader } from "react-spinners";
import { GetBodyTypes } from "../../services/body_types.service";
import { GetCategories } from "../../services/categories.service";
import { GetInjuries } from "../../services/injurys.service";
import { CreateProgram } from "../../services/programs.service";
import { GetTests } from "../../services/tests.service";
import VideoInput from "../exercise/VideoInput";
import MultipleVideosInput from "../exercise/MultipleVideosInput";
import { upload } from "../../utils/helpers";
import ThemeModal from "../../components/ThemeModal";
import VideoCommon from "./video/VideoCommon";
import FullPageLoader from "../../components/FullPageLoader";
function AddNewProgramForm(props) {
  const {
    register,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: "onBlur",
  });

  let history = useHistory();
  const [loader, setLoader] = useState(false);
  const [disableButton, setDisableButton] = useState(false);
  const [bodyTypeFileVideos, setbodyTypeFileVideos] = useState(null);
  const [isBodyTypeVideo, setIsBodyTypeVideo] = useState(false);
  const [isBodyAboutTypeVideo, setIsBodyAboutTypeVideo] = useState(false);
  const [subCategories, setSubCategories] = useState([]);
  const [bodyTypes, setBodyTypes] = useState([]);
  const [injuryPrograms, setInjuryPrograms] = useState([]);
  const [selectedTests, setSelectedTests] = useState([]);
  const [tests, setTests] = useState([]);
  const [injuryProgram, setInjuryProgram] = useState("");
  const [subCategory, setSubCategory] = useState("");
  const [bodyType, setBodyType] = useState("");
  const [name, setName] = useState("");
  const [isPageDirty, setPageDirty] = useState(false);

  //Welcome Video Upload
  const [welcomeVideo, setWelcomeVideo] = useState(null);
  const [welcomeFileVideo, setWelcomeFileVideo] = useState(null);

  //Single BodyType Video Upload
  const [bodyTypeVideo, setBodyTypeVideo] = useState(null);
  const [bodyTypeFileVideo, setBodyTypeFileVideo] = useState(null);

  //Multi Body Types Video Upload
  const [bodyMultiTypeVideos, setBodyMultiTypeVideos] = useState([]);
  const [bodyMultiTypeFileVideo, setBodyMultiTypeFileVideo] = useState([]);
  const [currentPlayingIndex, setCurrentPlayingIndex] = useState(-1);

  const [buttonType, setButtonType] = useState("");

  const handleNameChange = (event) => {
    setName(event.target.value);
    setPageDirty(true);
  };

  const handleWelcomeVideo = (event) => {
    setWelcomeFileVideo(URL.createObjectURL(event.target.files[0]));
    setWelcomeVideo(event.target.files[0]);
    URL.revokeObjectURL(event.target.files[0]);
  };

  const deleteWelcomeVideo = () => {
    setWelcomeVideo(null);
    setWelcomeFileVideo(null);
    URL.revokeObjectURL(null);
  };

  //Upload AboutBodyType Fun
  const handleBodyAboutTypeVideo = (event) => {
    setBodyTypeFileVideo(URL.createObjectURL(event.target.files[0]));
    setBodyTypeVideo(event.target.files[0]);
    setIsBodyAboutTypeVideo(true);
    URL.revokeObjectURL(event.target.files[0]);
  };

  const deleteBodyTypeVideo = () => {
    setBodyTypeVideo(null);
    setBodyTypeFileVideo(null);
    URL.revokeObjectURL(null);
  };

  const navigationPrompt = async (location) => {
    if (isPageDirty) {
      const userConfirmed = window.confirm(confirmMessage.message);
      if (userConfirmed) {
        console.log('User clicked "Yes" to leave with unsaved changes.');
        setDisableButton(true);
        setLoader(true);
        await CreateProgram({
          name: name,
        })
          .then(async (data) => {
            if (data.status) {
              console.log("Success.");
            } else {
              setDisableButton(false);
            }
          })
          .catch((error) => {
            setDisableButton(false);
            if (error.response.status == 401) {
              EmptyLocalStorage();
              history.push("/");
            } else {
              setDisableButton(false);
              setLoader(false);
              return toast.error(error.response.data.message);
            }
          });
      }
    }
    return true;
  };

  useEffect(() => {
    const getSubCategories = async () => {
      await GetCategories("", "", "", 0, "", "", 0, "asc", "name").then(
        (result) => {
          if (result.status && result.data) {
            setSubCategories(result.data);
          }
        }
      );
    };

    const getBodyTypes = async () => {
      await GetBodyTypes("", "", "", 0, "asc", "name").then((result) => {
        if (result.status && result.data) {
          setBodyTypes(result.data);
        }
      });
    };

    const getInjuryPrograms = async () => {
      await GetInjuries("", "", "", 0, 10, "asc", "name").then((result) => {
        if (result.status && result.data) {
          setInjuryPrograms(result.data);
        }
      });
    };

    const getTests = async () => {
      await GetTests("", "", "", "", 0, "asc", "title").then((result) => {
        if (result.status && result.data) {
          setTests(result.data);
        }
      });
    };

    getBodyTypes();
    getSubCategories();
    getInjuryPrograms();
    getTests();
  }, []);

  const onSubmit = async (data) => {
    setDisableButton(true);
    setLoader(true);
    setPageDirty(false);

    if (welcomeVideo) {
      let s3DataVideo = await upload(
        welcomeVideo,
        "program/videos",
        welcomeVideo.extension || "",
        welcomeVideo.type || ""
      );
      data.welcome_video = s3DataVideo.Location;
    } else {
      data.welcome_video = "";
    }

    if (bodyTypeVideo) {
      let s3DataVideo = await upload(
        bodyTypeVideo,
        "program/videos",
        bodyTypeVideo.extension || "",
        bodyTypeVideo.type || ""
      );
      data.bodyTypeVideo = s3DataVideo.Location;
    } else {
      data.bodyTypeVideo = "";
    }
    //BodyType Video Submit
    if (bodyMultiTypeFileVideo && bodyMultiTypeFileVideo.length > 0) {
      try {
        const uploadPromises = bodyMultiTypeFileVideo.map(async (videoBody) => {
          const s3DataVideo = await upload(
            videoBody,
            "program/videos",
            videoBody.extension || "",
            videoBody.type || ""
          );
          return { video: s3DataVideo.Location };
        });

        const locations = await Promise.all(uploadPromises);
        data.bodyMultiTypeFileVideo = locations;
      } catch (error) {
        console.error("Error uploading videos:", error);
      }
    } else {
      data.bodyMultiTypeFileVideo = [];
    }

    let params = {
      name: name,
      body_type_id: data.body_type_id ? data.body_type_id : null,
      category_id: data.category_id ? data.category_id : null,
      days_per_week: data.days_per_week ? data.days_per_week : null,
      gender: data.gender ? data.gender : null,
      injury_id: data.injury_id ? data.injury_id : null,
      welcome_video: data.welcome_video ? data.welcome_video : "",
      // body_type_video: data.bodyTypeVideo ? data.bodyTypeVideo : "",
      body_type_video: data.bodyTypeVideo ? data.bodyTypeVideo : "",
      body_type_videos: data.bodyMultiTypeFileVideo
        ? data.bodyMultiTypeFileVideo
        : "",
      program_tests: selectedTests ? selectedTests : null,
    };

    await CreateProgram(params)
      .then(async (data) => {
        if (data.status) {
          // history.push({
          //   pathname: "manage-program",
          //   state: {
          //     program: data.data,
          //   },
          // });

          if (buttonType == "save") {
            history.push("/program-library");
          } else {
            history.push({
              pathname: "manage-program",
              state: {
                program: data.data,
              },
            });
          }
          setLoader(false);
        } else {
          setLoader(false);
          setDisableButton(false);
        }
      })
      .catch((error) => {
        setDisableButton(false);
        if (error.response.status == 401) {
          EmptyLocalStorage();
          history.push("/");
        } else {
          setDisableButton(false);
          setLoader(false);
          return toast.error(error.response.data.message);
        }
      });
  };

  const selectedTestsOpt = (selectedValue) => {
    let data = [];
    selectedValue.map((item) => {
      data.push(item.value);
    });
    setSelectedTests(data);
  };

  const setBodyTypeFunc = (selectedValue) => {
    setValue("body_type_id", selectedValue ? selectedValue.value : null);
    setBodyType(selectedValue ? selectedValue.value : null);
  };

  const setInjuryProgramFunc = (selectedValue) => {
    setValue("injury_id", selectedValue ? selectedValue.value : "");
    setInjuryProgram(selectedValue ? selectedValue.value : null);
  };

  const setSubCategoryFunc = (selectedValue) => {
    setValue("category_id", selectedValue ? selectedValue.value : "");
    setSubCategory(selectedValue ? selectedValue.value : null);
  };

  return (
    <div className="AddNewProgramForm program-index">
      <div className="page-title">
        <h1>Create New Program</h1>
        <Button className="common-btn" onClick={() => history.goBack()}>
          Back
        </Button>
      </div>
      <Prompt when={isPageDirty} message={navigationPrompt} />

      <div className="common-form">
        <Form
          className="form-program"
          id="formAddProgram"
          autoComplete="off"
          role="presentation"
          onSubmit={handleSubmit(onSubmit)}
        >
          <Row>
            <Col md={4}>
              <Form.Group className="mb-3">
                <Form.Label htmlFor={"name"}>Program Name*</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter text"
                  value={name}
                  onChange={handleNameChange}
                />

                {errors.name && (
                  <Form.Text className="text-muted validationText hasError">
                    {errors.name.message}
                  </Form.Text>
                )}
              </Form.Group>
            </Col>
            <Col md={4}>
              <Form.Group className="mb-3">
                <Form.Label>Body Type</Form.Label>
                <Select
                  id={"body_type_id"}
                  options={
                    bodyTypes &&
                    bodyTypes.map((e) => ({ label: e.name, value: e.id }))
                  }
                  className="basic-multi-select"
                  classNamePrefix="select"
                  onChange={setBodyTypeFunc}
                />
                {errors.body_type_id && (
                  <Form.Text className="text-muted validationText hasError">
                    {errors.body_type_id.message}
                  </Form.Text>
                )}
              </Form.Group>
            </Col>
            <Col md={4}>
              <Form.Group className="mb-3">
                <Form.Label>Sub Category</Form.Label>
                <Select
                  id={"category_id"}
                  options={
                    subCategories &&
                    subCategories.map((e) => ({ label: e.name, value: e.id }))
                  }
                  className="basic-multi-select"
                  classNamePrefix="select"
                  onChange={setSubCategoryFunc}
                />
                {errors.category_id && (
                  <Form.Text className="text-muted validationText hasError">
                    {errors.category_id.message}
                  </Form.Text>
                )}
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col md={4}>
              <Form.Group className="mb-3">
                <Form.Label>Gender*</Form.Label>
                <Form.Select
                  placeholder="Select Gender"
                  id={"gender"}
                  {...register("gender", {})}
                >
                  <option value={""} selected={true} disabled={true}>
                    Select Gender
                  </option>
                  <option value={genders.FEMALE}>Female</option>
                  <option value={genders.MALE}>Male</option>
                </Form.Select>
                {errors.gender && (
                  <Form.Text className="text-muted validationText hasError">
                    {errors.gender.message}
                  </Form.Text>
                )}
              </Form.Group>
            </Col>
            <Col md={4}>
              <Form.Group className="mb-3">
                <Form.Label>Injury Program</Form.Label>
                <Select
                  id={"injury_id"}
                  options={
                    injuryPrograms &&
                    injuryPrograms.map((e) => ({
                      label: e.name,
                      value: e.id,
                    }))
                  }
                  className="basic-multi-select"
                  classNamePrefix="select"
                  onChange={setInjuryProgramFunc}
                />
                {errors.injury_id && (
                  <Form.Text className="text-muted validationText hasError">
                    {errors.injury_id.message}
                  </Form.Text>
                )}
              </Form.Group>
            </Col>
            <Col md={4}>
              <Form.Group className="mb-3">
                <Form.Label>Days Per Week</Form.Label>
                <Form.Select
                  id={"days_per_week"}
                  placeholder="Select Days Per Week"
                  {...register("days_per_week", {})}
                >
                  <option value={""} selected={true} disabled={true}>
                    Select Days Per Week
                  </option>
                  <option value="1">1</option>
                  <option value="2">2</option>
                  <option value="3">3</option>
                  <option value="4">4</option>
                  <option value="5">5</option>
                  <option value="6">6</option>
                  <option value="7">7</option>
                </Form.Select>
                {errors.days_per_week && (
                  <Form.Text className="text-muted validationText hasError">
                    {errors.days_per_week.message}
                  </Form.Text>
                )}
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col md={4}>
              <Form.Group className="mb-3" controlId="bodyType">
                <Form.Label className="d-block">Tests</Form.Label>
                <Select
                  isMulti
                  options={
                    tests &&
                    tests.map((e) => ({
                      label: `${e.title} (${
                        e.gender == 20 ? "Female" : "Male"
                      })`,
                      value: e.id,
                    }))
                  }
                  className="basic-multi-select"
                  classNamePrefix="select"
                  onChange={selectedTestsOpt}
                />
              </Form.Group>
            </Col>
            <Col md={4}>
              <Form.Group
                className="custom-file-upload"
                controlId="videoFile"
                onChange={(e) => handleWelcomeVideo(e)}
              >
                <Form.Label className={"common-btn"}>Welcome Video</Form.Label>
                <Form.Control hidden type="file" accept="video/*" />
              </Form.Group>
              {welcomeFileVideo ? (
                <>
                  <div className="image-item">
                    <video
                      controls
                      className={"img-table img-thumbnail"}
                      src={welcomeFileVideo}
                      title="Video"
                    ></video>
                    <div className="image-item__btn-wrapper">
                      <button
                        type="button"
                        className="common-btn"
                        onClick={() => deleteWelcomeVideo()}
                      >
                        <TrashIcon />
                      </button>
                    </div>
                  </div>
                </>
              ) : (
                ""
              )}
            </Col>
            <Col md={4}>
              <Form.Group
                controlId="abountVideoFile"
                onChange={(e) => handleBodyAboutTypeVideo(e)}
                className="custom-file-upload mb-2"
              >
                <Form.Label className={"common-btn"}>About Video</Form.Label>
                <Form.Control type="file" accept="video/*" />
              </Form.Group>

              {bodyTypeFileVideo ? (
                <>
                  <div className="image-item">
                    <video
                      controls
                      className={"img-table img-thumbnail"}
                      src={bodyTypeFileVideo}
                      title="Video"
                    ></video>
                    <div className="image-item__btn-wrapper">
                      <button
                        type="button"
                        className="common-btn"
                        onClick={() => deleteBodyTypeVideo()}
                      >
                        <TrashIcon />
                      </button>
                    </div>
                  </div>
                </>
              ) : (
                ""
              )}
            </Col>
          </Row>
          <VideoCommon
            bodyMultiTypeFileVideo={bodyMultiTypeFileVideo}
            setBodyMultiTypeFileVideo={setBodyMultiTypeFileVideo}
            setBodyMultiTypeVideos={setBodyMultiTypeVideos}
            bodyMultiTypeVideos={bodyMultiTypeVideos}
            currentPlayingIndex={currentPlayingIndex}
            setCurrentPlayingIndex={setCurrentPlayingIndex}
          />

          <Row>
            <Col md={2} className="ms-auto d-flex">
              {loader ? (
                <FullPageLoader loading={loader} />
              ) : (
                <>
                  <Button
                    className={"btn common-btn w-100 mt-3 ms-3"}
                    type={"submit"}
                    name={"next"}
                    onClick={() => setButtonType("next")}
                  >
                    Next
                  </Button>
                  <Button
                    className={"btn common-btn w-100 mt-3 ms-3"}
                    type={"submit"}
                    name={"next"}
                    onClick={() => setButtonType("save")}
                  >
                    Save
                  </Button>
                </>
              )}
            </Col>
          </Row>
        </Form>
      </div>
    </div>
  );
}

export default AddNewProgramForm;
