import React, { useEffect, useState } from "react";
import swal from "sweetalert";
import { useHistory } from "react-router-dom";
import {
  Button,
  Col,
  Form,
  FormControl,
  InputGroup,
  Row,
} from "react-bootstrap";
import {
  AddIconOutline,
  DeleteUpdated,
  SearchIcon,
  TrashIcon,
} from "../../components/SvgIcons";
import { cross_origin } from "../../utils/constants";
import { toast } from "react-toastify";
import ThemeModal from "../../components/ThemeModal";
import { useForm } from "react-hook-form";
import S3Browser from "../../components/S3Browser";
import { EmptyLocalStorage } from "../../services/auth/auth.service";
import { FadeLoader } from "react-spinners";
import {
  CreateInstructions,
  DeleteInstructions,
  GetInstructions,
  UpdateInstructions,
} from "../../services/instructions";
import { upload } from "../../utils/helpers";
import { DataGridPro, GridToolbar, useGridApiRef } from "@mui/x-data-grid-pro";
import { MdEdit, MdDelete } from "react-icons/md";
import { AiOutlineFolderView } from "react-icons/ai";
import { styled } from "@mui/material/styles";
import {
  ColumnHideShow,
  GetColumnHideShow,
} from "../../services/users.service";
import FullPageLoader from "../../components/FullPageLoader";
import { MenuItem, Pagination, Select, Stack } from "@mui/material";

const StyledDataGrid = styled(DataGridPro)(({ theme }) => ({
  position: "relative",
  "& .MuiDataGrid-toolbarContainer": {
    position: "absolute",
    top: "-43px",
    marginLeft: "50px",
  },
  "& .MuiDataGrid-columnHeaders": {
    fontWeight: 600,
    fontSize: "1.05em",
    fontFamily: "Arial, sans-serif",
  },
  "& .MuiDataGrid-columnHeaderTitle": {
    fontWeight: 600,
    fontSize: "1.05em",
  },
  "& .mui-aqpgxn-MuiFormLabel-root-MuiInputLabel-root": {
    visibility: "hidden !important",
  },
  "& .MuiDataGrid-cell": {
    fontSize: "0.9em",
    fontFamily: "Arial, sans-serif",
  },
  "& .MuiDataGrid-row": {
    minHeight: "30px",
    maxHeight: "30px",
  },
}));

function Instructions() {
  let history = useHistory();
  const [loading, setLoading] = useState(false);

  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(100);
  const [rows, setRows] = useState([]);
  const [keyword, setKeyword] = useState("");

  const [fileName, setFileName] = useState(null);
  const [formData, setFormData] = useState({});
  const [countPage, setPageCount] = useState(0);
  const [showAddModal, setShowAddModal] = useState(false);
  const [showViewModal, setShowViewModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [modalShow, setModalShow] = useState(false);
  const [loader, setLoader] = useState(false);
  const [selectedData, setSelectedData] = useState(null);
  const apiRef = useGridApiRef();
  const [video, setVideo] = useState("");
  const [isUploadVideo, setIsUploadVideo] = useState(false);
  const [fileVideo, setFileVideo] = useState(null);
  const [columnVisibilityModel, setColumnVisibilityModel] = useState({});
  const [searchLoading, setSearchLoading] = useState(false);
  const [columns, setColumns] = useState([]);

  const handlePaginationChange = (event, value) => {
    setPage(value);
  };

  const handlePageSizeChange = (event) => {
    setPerPage(event.target.value);
  };

  const {
    register,
    watch,
    handleSubmit,
    setError,
    clearErrors,
    formState: { errors },
  } = useForm({
    mode: "onBlur",
  });

  useEffect(() => {
    const fetchData = async () => {
      await getInstructions(true);
    };

    fetchData();
  }, [page, perPage]);

  useEffect(() => {
    if (keyword) {
      getInstructions(false);
    } else {
      getInstructions(true);
    }
  }, [keyword, page, perPage]);

  useEffect(() => {
    const fetchData = async () => {
      await getColumnsHideShow("how_to_use", "column_hide_show");
      await getColumnsOrderChange();
    };

    fetchData();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [columns] = await Promise.all([getColumns()]);
        getColumnsWidth("how_to_use", columns);
      } catch (error) {}
    };

    fetchData();
  }, []);

  const getColumnsWidth = async (type, columns) => {
    await GetColumnHideShow(type, "column_width_change").then((result) => {
      if (result?.status && result?.data) {
        const data = result?.data;
        const updatedObj = columns.map((item) => {
          const foundItem = result?.data.find(
            (data) => data.field_name === item.field
          );
          if (foundItem) {
            return {
              ...item,
              width: foundItem.field_width,
            };
          }
          return item;
        });
        setColumns(updatedObj);
      } else {
        setColumns(columns);
      }
    });
  };

  const handleColumnWidthChange = async (newColumns) => {
    setLoading(true);
    try {
      const requestObj = {
        module_name: "how_to_use",
        type: "column_width_change",
        field_name: newColumns?.colDef?.field,
        field_width: newColumns?.colDef?.width,
      };
      const result = await ColumnHideShow(requestObj);
      if (result.status && result.data) {
        if (columns.length > 0) {
          const reorderedColumns = columns.map((column) => {
            if (column.field == result?.data?.field_name) {
              return { ...column, width: result?.data?.field_width };
            }
            return column;
          });
          setColumns(reorderedColumns);
        } else {
          console.log("no columns are found.");
        }
      } else {
        toast.error(result.message);
      }
    } catch (error) {
      toast.error(error.response.data.message.replace(/_/g, " "));
    } finally {
      setLoading(false);
    }
  };

  const getColumnsHideShow = async (moduleName, type) => {
    await GetColumnHideShow(moduleName, type).then((result) => {
      if (result?.status && result?.data && result.data.table_data !== null) {
        const tableData = JSON.parse(result?.data?.table_data);
        setColumnVisibilityModel(tableData);
      }
    });
  };

  const getColumnsOrderChange = async () => {
    await GetColumnHideShow("how_to_use", "column_order_change")
      .then((result) => {
        if (result.status && result.data) {
          const parsedArray = JSON.parse(result.data.table_data);
          const dataArray = Object.values(parsedArray);
          const completeColumns = dataArray.map((item) => {
            if (item.field === "actions") {
              return {
                field: "actions",
                headerName: "Actions",
                sortable: false,
                filterable: false,
                width: item.width,
                renderCell: (params) => {
                  const onDelete = () => {
                    const id = `${params.id}`;
                    swal({
                      title: "Are you sure?",
                      text: "Are you sure you want to delete this video entry?",
                      icon: "warning",
                      buttons: true,
                      dangerMode: true,
                    }).then(async (willShip) => {
                      if (willShip) {
                        await DeleteInstructions(id)
                          .then((result) => {
                            if (result.status) {
                              swal(result.message, {
                                icon: "success",
                              });
                              getInstructions();
                            } else {
                              toast.error(result.message);
                            }
                          })
                          .catch((error) => {
                            toast.error(
                              error.response.data.message.replace(/_/g, " ")
                            );
                          });
                      }
                    });
                  };

                  return (
                    <div>
                      <button
                        className={"btn btn-primary btn-sm"}
                        onClick={() => {
                          setShowEditModal(true);
                          setSelectedData(params.data);
                        }}
                      >
                        <MdEdit />
                      </button>
                      <button
                        className={"btn btn-danger btn-sm mx-2"}
                        onClick={(event) => {
                          event.stopPropagation();
                          onDelete(params.id);
                        }}
                      >
                        <MdDelete />
                      </button>
                      <button
                        className={"btn btn-dark btn-sm"}
                        onClick={(event) => {
                          event.stopPropagation();

                          setShowViewModal(true);
                          setSelectedData(params?.row);
                        }}
                      >
                        <AiOutlineFolderView />
                      </button>
                    </div>
                  );
                },
              };
            } else if (item.field === "video") {
              return {
                field: "video",
                headerName: "Video",
                width: item.width,
                renderCell: (params) => (
                  <video
                    crossOrigin={cross_origin ? "anonymous" : null}
                    style={{ width: "150px", height: "100px" }}
                    controls
                    src={params.value}
                    className={"img-table img-thumbnail mt-2"}
                  />
                ),
              };
            } else {
              return item;
            }
          });
          setColumns(completeColumns);
        }
      })
      .catch((error) => {
        console.error("Error fetching column order change:", error);
      });
  };

  const saveColumnOrderToDatabase = async (newOrder) => {
    setLoading(true);
    try {
      const reorderedColumns = newOrder.map((fieldName) => {
        return columns.find((column) => column.field === fieldName);
      });

      const newArray = reorderedColumns.filter(
        (element) => element !== undefined
      );
      const requestObj = {
        table_data: JSON.stringify(newArray),
        module_name: "how_to_use",
        type: "column_order_change",
      };

      await ColumnHideShow(requestObj)
        .then((result) => {
          if (result.status) {
            if (result.data) {
              getColumnsOrderChange();
            }
          } else {
            setLoading(false);
            toast.error(result.message);
          }
        })
        .catch((error) => {
          setLoading(false);
          toast.error(error.response.data.message.replace(/_/g, " "));
        });
    } catch (error) {
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };

  const handleColumnOrderChange = () => {
    const newOrder = apiRef.current.getAllColumns().map((col) => col.field);
    saveColumnOrderToDatabase(newOrder);
  };

  const toggleCustomFunc = async (newModel) => {
    setLoading(true);
    const requestObj = {
      table_data: JSON.stringify(newModel),
      module_name: "how_to_use",
      type: "column_hide_show",
    };

    try {
      setColumnVisibilityModel(newModel);
      const result = await ColumnHideShow(requestObj);

      if (result.status) {
        if (result.data) {
          await getColumnsHideShow("how_to_use", "column_hide_show");
        }
      } else {
        toast.error(result.message);
      }
    } catch (error) {
      toast.error(
        error.response?.data?.message.replace(/_/g, " ") || "An error occurred"
      );
    } finally {
      setLoading(false);
    }
  };

  const handleChange = async (event) => {
    console.log("event.target.files[0]", event.target.files[0]);
    setFileVideo(URL.createObjectURL(event.target.files[0]));
    setVideo(event.target.files[0]);
    setIsUploadVideo(true);
  };

  const resetVideo = (type) => {
    setFileVideo(null);
    setVideo(null);
  };

  const selectedMedia = (fileName, file) => {
    clearErrors(fileName);
    setFormData({ ...formData, ...{ [fileName]: file } });
    setModalShow(false);
  };

  const getColumns = async () => {
    const obj = [
      { field: "id", headerName: "ID", width: 210 },
      {
        field: "video",
        headerName: "Video",
        width: 400,
        renderCell: (params) => (
          <video
            crossOrigin={cross_origin ? "anonymous" : null}
            style={{ width: "150px", height: "100px" }}
            controls
            src={params.value}
            className={"img-table img-thumbnail mt-2"}
          />
        ),
      },
      {
        field: "actions",
        headerName: "Actions",
        width: 150,
        sortable: false,
        filterable: false,
        disableClickEventBubbling: true,
        renderCell: (params) => {
          const onDelete = () => {
            const id = `${params.id}`;
            swal({
              title: "Are you sure?",
              text: "Are you sure you want to delete this video entry?",
              icon: "warning",
              buttons: true,
              dangerMode: true,
            }).then(async (willShip) => {
              if (willShip) {
                await DeleteInstructions(id)
                  .then((result) => {
                    if (result.status) {
                      swal(result.message, {
                        icon: "success",
                      });
                      getInstructions();
                    } else {
                      toast.error(result.message);
                    }
                  })
                  .catch((error) => {
                    toast.error(error.response.data.message.replace(/_/g, " "));
                  });
              }
            });
          };

          return (
            <div>
              <button
                className={"btn btn-primary btn-sm"}
                onClick={() => {
                  setShowEditModal(true);
                  setSelectedData(params?.row);
                }}
              >
                <MdEdit />
              </button>
              <button
                className={"btn btn-danger btn-sm mx-2"}
                onClick={(event) => {
                  event.stopPropagation();
                  onDelete(params.id);
                }}
              >
                <MdDelete />
              </button>
              <button
                className={"btn btn-dark btn-sm"}
                onClick={(event) => {
                  event.stopPropagation();

                  setShowViewModal(true);
                  setSelectedData(params?.row);
                }}
              >
                <AiOutlineFolderView />
              </button>
            </div>
          );
        },
      },
    ];
    return obj;
  };

  const ViewModal = (props) => {
    return (
      <div className={"ViewModalContact"}>
        <video
          crossOrigin={cross_origin ? "anonymous" : null}
          style={{ width: "100%", height: "210px" }}
          className={"img-table img-thumbnail mt-2"}
          controls
        >
          <source src={selectedData?.video} type="video/mp4" />
        </video>
        {/* <Form.Group controlId="message">
                    <Form.Label>Description</Form.Label>
                    <Form.Control value={selectedData?.description} placeholder={"Description"} as="textarea" rows={3} disabled />
                </Form.Group> */}
      </div>
    );
  };

  const onSubmit = async (data) => {
    setLoader(true);

    if (isUploadVideo) {
      let s3DataVideo = await upload(
        video,
        "app-instructions/videos",
        video.extension || "",
        video.type || ""
      );
      if (!s3DataVideo) {
        toast.error("Uploading failed");
        setLoader(false);
        return false;
      }
      data.video = s3DataVideo?.Location;
    } else {
      data.video = video;
    }

    await CreateInstructions(data)
      .then(async (data) => {
        setShowAddModal(false);
        setLoader(false);
        getInstructions();
        if (data.status) {
          toast.success(data.message);
          history.push("/app-instructions");
        } else {
          toast.error(data.message.replace(/_/g, " "));
        }
      })
      .catch((error) => {
        setLoader(false);
        if (error.response.status == 401) {
          EmptyLocalStorage();
          history.push("/");
        } else {
          return toast.error(error.response.data.message.replace(/_/g, " "));
        }
      });
  };

  const EditModal = (props) => {
    const {
      register,
      watch,
      handleSubmit,
      setValue,
      setError,
      clearErrors,
      formState: { errors },
    } = useForm({
      mode: "onBlur",
    });
    //const [video, setVideo] = useState(null)
    const onSubmit = async (data) => {
      if (!video) {
        setError("video", { message: "Video is required" });
        return false;
      } else {
        clearErrors("video");
      }

      setLoader(true);

      if (isUploadVideo) {
        let s3DataVideo = await upload(
          video,
          "app-instructions/videos",
          video.extension || "",
          video.type || ""
        );
        console.log("s3DataVideo...", s3DataVideo?.Location);
        if (!s3DataVideo) {
          toast.error("Uploading failed");
          setLoader(false);
          return false;
        }
        data.video = s3DataVideo?.Location;
      } else {
        data.video = video;
      }
      setLoader(true);
      await UpdateInstructions(selectedData?.id, data)
        .then(async (data) => {
          setShowEditModal(false);
          setLoader(false);
          //setVideo(null)
          if (data.status) {
            setTimeout(() => {
              getInstructions();
            }, 1000);
            toast.success(data.message);
            history.push("/app-instructions");
          } else {
            toast.error(data.message.replace(/_/g, " "));
          }
        })
        .catch((error) => {
          setLoader(false);
          if (error.response.status == 401) {
            EmptyLocalStorage();
            history.push("/");
          } else {
            return toast.error(error.response.data.message.replace(/_/g, " "));
          }
        });
    };

    useEffect(() => {
      setValue("description", selectedData?.description);
      setVideo(selectedData?.video);
      setFileVideo(selectedData?.video);
    }, []);

    return (
      <div className={"ViewModalContact"}>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Form.Group
            controlId="formFile"
            onChange={(e) => handleChange(e)}
            className="custom-file-upload mb-0"
          >
            <Form.Label className={"btn common-btn"}>
              Upload Instruction Video
            </Form.Label>
            <Form.Control type="file" accept="video/*" />
            {errors.video && (
              <Form.Text className="text-muted validationText hasError">
                {errors.video.message}
              </Form.Text>
            )}
          </Form.Group>
          {fileVideo ? (
            <>
              <div className="image-item">
                <video
                  className={"img-table img-thumbnail"}
                  src={fileVideo}
                  title="Video"
                ></video>
                <div className="image-item__btn-wrapper">
                  <button
                    type="button"
                    className="btn common-btn"
                    onClick={() => resetVideo("video")}
                  >
                    <TrashIcon />
                  </button>
                </div>
              </div>
            </>
          ) : (
            ""
          )}
          {loader ? (
            <div className="spin-loader">
              <FadeLoader color={"#EC1246"} height={10} />
            </div>
          ) : (
            <Button
              type={"submit"}
              className={"btn common-btn w-100 mt-3"}
              value={"Update Instruction"}
            >
              Update Instruction
            </Button>
          )}
        </Form>
      </div>
    );
  };

  const AddModal = () => {
    return (
      <div className={"ViewModalContact"}>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Form.Group
            controlId="formFile"
            onChange={(e) => handleChange(e)}
            className="custom-file-upload mb-0"
          >
            <Form.Label className={"btn common-btn"}>Upload Video</Form.Label>
            <Form.Control type="file" accept="video/*" />
            {errors.video && (
              <Form.Text className="text-muted validationText hasError">
                {errors.video.message}
              </Form.Text>
            )}
          </Form.Group>
          {fileVideo ? (
            <>
              <div className="image-item">
                <video
                  className={"img-table img-thumbnail"}
                  src={fileVideo}
                  title="Video"
                ></video>
                <div className="image-item__btn-wrapper">
                  <button
                    type="button"
                    className="btn common-btn"
                    onClick={() => resetVideo("video")}
                  >
                    <TrashIcon />
                  </button>
                </div>
              </div>
            </>
          ) : (
            ""
          )}
          {loader ? (
            <div className="spin-loader">
              <FadeLoader color={"#EC1246"} height={10} />
            </div>
          ) : (
            <input
              type={"submit"}
              className={"btn common-btn w-100 mt-3"}
              value={"Add New Instruction"}
            />
          )}
        </Form>
      </div>
    );
  };

  // const getInstructions = async (isInitialLoad=true) => {
  //   if (isInitialLoad) {
  //     setLoading(true);
  //   } else {
  //     setSearchLoading(true);
  //   }
  //   try {
  //     let params = {
  //       page,
  //       perPage,
  //       keyword,
  //       order: "asc",
  //       ["order-column"]: "id",
  //       is_paginate: 0,
  //     };

  //     const res = await GetInstructions(params);
  //     const data = res?.data?.map((val) => ({
  //       id: val?.id,
  //       description: val?.description,
  //       video: val?.video,
  //     }));
  //     setRows(data);
  //     setLoading(false);
  //   } catch (e) {
  //     setLoading(false);
  //     toast.error(e.response.data.message);
  //   }
  // };

  const getInstructions = async (isInitialLoad = true) => {
    if (isInitialLoad) {
      setLoading(true);
    } else {
      setSearchLoading(true);
    }

    try {
      let params = {
        page,
        perPage,
        keyword,
        order: "asc",
        ["order-column"]: "id",
        is_paginate: 1,
      };

      const res = await GetInstructions(params);

      if (res && res.data) {
        setPage(res?.data?.meta?.current_page);
        setPageCount(res?.data?.meta?.last_page);
        const data = res?.data?.data?.map((val) => ({
          id: val.id,
          video: val.video,
          description: val?.description,
        }));
        setRows(data);
        if (isInitialLoad) {
          setLoading(false);
        } else {
          setSearchLoading(false);
        }
      } else {
        toast.error("Failed to fetch videos.");
      }
    } catch (error) {
      if (isInitialLoad) {
        EmptyLocalStorage();
      }
      toast.error(
        "An error occurred while fetching videos: " +
          (error.response.data.message || error.message).replace(/_/g, " ")
      );
    } finally {
      if (isInitialLoad) {
        setLoading(false);
      } else {
        setSearchLoading(false);
      }
    }
  };

  return (
    <>
      {loading && <FullPageLoader loading={loading} />}

      <div>
        <h1>How To Use</h1>
        <div className={"customOrderFilters"}>
          <Row>
            <Col md={5} className="ms-auto">
              <div className="all-user">
                <div className="search-bar">
                  <div className="common-search-bar">
                    <InputGroup>
                      <InputGroup.Text id="basic-addon1">
                        <SearchIcon />
                      </InputGroup.Text>
                      <FormControl
                        placeholder="Search"
                        aria-label="Search"
                        aria-describedby="Search"
                        onChange={(e) => {
                          e.target.value.length > 0
                            ? setKeyword(e.target.value)
                            : setKeyword(null);
                        }}
                      />
                    </InputGroup>
                  </div>
                </div>
              </div>
            </Col>
            <Col md={2}>
              <Button
                // className={` mb-3 btn common-btn   w-100 ${
                //   rows?.length > 0 ? "d-none" : "d-block"
                // }`}
                className={`btn common-btn   w-100 d-block`}
                onClick={() => setShowAddModal(true)}
              >
                <AddIconOutline /> Add New
              </Button>
            </Col>
          </Row>
        </div>
        <section className="common-table mt-2">
          <div class="delete-icon">
            <Button className={"common-btn"} onClick={""}>
              <DeleteUpdated />
            </Button>
          </div>
          <div style={{ height: "99vh", width: "100%" }}>
            <StyledDataGrid
              rows={rows}
              loading={searchLoading}
              columnVisibilityModel={columnVisibilityModel}
              onColumnVisibilityModelChange={toggleCustomFunc}
              isColumnResizable
              onColumnWidthChange={handleColumnWidthChange}
              columns={columns}
              checkboxSelection
              disableSelectionOnClick
              unstable_headerFilters
              apiRef={apiRef}
              density="compact"
              pageSize={perPage}
              onPageChange={(newPage) => setPage(newPage)}
              onPageSizeChange={(newPageSize) => {
                setPerPage(newPageSize);
                setPage(1);
              }}
              paginationMode="server"
              slots={{
                toolbar: GridToolbar,
              }}
              initialState={{ pinnedColumns: { right: ["actions"] } }}
              onColumnOrderChange={handleColumnOrderChange}
            />

            <div className="user-pagination">
              <Row>
                <Col md={4}></Col>
                <Col md={4}>
                  <Stack spacing={2}>
                    <Pagination
                      count={countPage}
                      page={page}
                      onChange={handlePaginationChange}
                      size="large"
                    />
                  </Stack>
                </Col>
                <Col md={4}>
                  <Stack direction="row" spacing={2} alignItems="center">
                    <label>Rows per page:</label>
                    <Select value={perPage} onChange={handlePageSizeChange}>
                      <MenuItem value={50}>50</MenuItem>
                      <MenuItem value={100}>100</MenuItem>
                      <MenuItem value={200}>200</MenuItem>
                    </Select>
                  </Stack>
                </Col>
              </Row>
            </div>

            <ThemeModal
              title={"Add Instructions"}
              show={showAddModal}
              onHide={() => setShowAddModal(false)}
              size={"md"}
              content={<AddModal />}
            />
            <ThemeModal
              title={"Instructions"}
              show={showViewModal}
              onHide={() => setShowViewModal(false)}
              size={"md"}
              content={<ViewModal />}
            />
            <ThemeModal
              title={"Edit Instructions"}
              show={showEditModal}
              onHide={() => setShowEditModal(false)}
              size={"md"}
              content={<EditModal />}
            />
            <ThemeModal
              title={"S3 Browser"}
              content={
                <S3Browser fileName={fileName} selectedMedia={selectedMedia} />
              }
              size={"xl"}
              show={modalShow}
              onHide={() => {
                setModalShow(false);
              }}
            />
          </div>
        </section>
      </div>
    </>
  );
}

export default Instructions;
