import React, { useEffect, useRef, useState } from "react";
import {
  Button,
  Col,
  Form,
  FormControl,
  InputGroup,
  Row,
} from "react-bootstrap";
import { useForm } from "react-hook-form";
import { GetUserById, UpdateUser } from "../../services/users.service";
import { toast } from "react-toastify";
import { Link, useHistory } from "react-router-dom";
import { EmptyLocalStorage } from "../../services/auth/auth.service";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import Select from "react-select";
import { GetCountries } from "../../services/countries.service";
import { GetSports } from "../../services/sports.service";
import { GetOccupations } from "../../services/occupations.service";
import { GetPrograms } from "../../services/programs.service";
import {
  constants,
  cross_origin,
  genders,
  weight_unit,
  subscription_type,
} from "../../utils/constants";
import { TrashIcon } from "../../components/SvgIcons";
import { uploadToS3 } from "../../utils/helpers";
import { FadeLoader } from "react-spinners";
import PhoneInput, { isValidPhoneNumber } from "react-phone-number-input";
import FullPageLoader from "../../components/FullPageLoader";
import { GetInjuries } from "../../services/injurys.service";
import { GetBodyTypes } from "../../services/body_types.service";

function EditUser(props) {
  let history = useHistory();

  const [data, setData] = useState([]);
  const [pass, TogglePass] = useState(true);
  const [confPass, ToggleConfPass] = useState(true);
  const [confirmPass, setConfirmPass] = useState(true);
  const [countries, setCountyList] = useState([]);
  const [sports, setSport] = useState([]);
  const [occupations, setOccupation] = useState([]);
  const [programs, setPrograms] = useState([]);
  const [age, setAge] = useState({ format: "mm/dd/yyyy" });
  const [formData, setFormData] = useState({});
  const [editSelectedOccupation, setEditSelectedOccupation] = useState([]);
  const [editSelectedProgram, setEditSelectedProgram] = useState([]);
  const [selectCountry, setSelectCountry] = useState();
  const [file, setFileData] = useState(null);
  const [defaultImage, setDefaultImage] = useState(null);

  const [selectedOccupation, setSelectedOccupation] = useState();
  const [selectedProgram, setSelectedProgram] = useState([]);
  const [img, setImg] = useState("");
  const [loader, setLoader] = useState(false);
  const [phoneValue, setPhoneValue] = useState("");
  const [injuryPrograms, setInjuryPrograms] = useState([]);
  const [bodyTypes, setBodyTypes] = useState([]);

  const [selectedSport, setSelectedSport] = useState([]);
  const [editSelectedSport, setEditSelectedSport] = useState([]);

  const [selectedInjury, setSelectedInjury] = useState([]);
  const [editSelectedInjury, setEditSelectedInjury] = useState([]);

  const [selectedBodyType, setSelectedBodyType] = useState([]);
  const [editSelectedBodyType, setEditSelectedBodyType] = useState([]);

  const BackManageUser = () => {
    history.push("/manage-users");
  };

  const {
    register,
    watch,
    setError,
    clearErrors,
    setValue,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: "onBlur",
  });

  useEffect(async () => {
    const body = document.querySelector("#root");
    body.scrollIntoView(
      {
        behavior: "smooth",
      },
      500
    );
    setTimeout(() => {
      const elements = document.querySelectorAll(".PhoneInputCountryIconImg");
      elements.forEach((element) => {
        element.setAttribute("crossOrigin", "anonymous");
      });
    }, 1500);
    await getOccupations();
    await getCountries();
    await getSports();
    await getPrograms();
    await getInjuryPrograms();
    await getBodyTypes();
  }, []);

  useEffect(() => {
    let id = props.match.params.id;
    const getUserById = async (id) => {
      setLoader(true);
      try {
        const result = await GetUserById(id);
        if (result.status) {
          if (result.data) {
            setData(result.data);
            setFormData(result.data);
            setValue("full_name", result.data?.name);
            setValue("email", result.data?.email);
            setValue("phone", result.data?.phone);
            setValue("gender", result.data?.userDetail?.gender);
            setValue("age", result.data?.userDetail?.dob);
            setValue("country", result.data?.userDetail?.country);
            setValue("weight_unit", result.data?.userDetail?.weight_unit);
            setValue("subscription_type", result.data?.subscription_type);
            setPhoneValue(result.data?.phone);

            let tempInjuryResult;
            await GetInjuries("", "", "", 0, 10, "asc", "name").then(
              (InjuryResult) => {
                if (InjuryResult.status && InjuryResult.data) {
                  setInjuryPrograms(InjuryResult.data);
                  tempInjuryResult = InjuryResult.data;
                }
              }
            );

            let tempBodyTypeResult;
            await GetBodyTypes("", "", "", 0, "asc", "name").then(
              (bodyTypeResult) => {
                if (bodyTypeResult.status && bodyTypeResult.data) {
                  setBodyTypes(bodyTypeResult.data);
                  tempBodyTypeResult = bodyTypeResult.data;
                }
              }
            );

            let selectedInjuryData = [];
            if (result.data.userInjuries) {
              selectedInjuryData = result.data.userInjuries.map((item) => ({
                value: item.id,
                label: item.name,
              }));
              setEditSelectedInjury(selectedInjuryData);
            }

            let selectedBodyTypeData = [];
            if (result.data.userBodyTypes) {
              selectedBodyTypeData = result.data.userBodyTypes.map((item) => ({
                value: item.id,
                label: item.name,
              }));
              setEditSelectedBodyType(selectedBodyTypeData);
            }

            let selectedSportsData = [];
            if (result.data.userSports) {
              selectedSportsData = result.data.userSports.map((item) => ({
                value: item.id,
                label: item.name,
              }));
              setEditSelectedSport(selectedSportsData);
            }

            let selectedOccupationData = [];
            if (result.data.occupation) {
              selectedOccupationData.push({
                value: result.data.occupation?.id,
                label: result.data.occupation?.name,
              });
              setEditSelectedOccupation(selectedOccupationData);
            }

            let selectedProgramsData = [];
            if (result.data.userActivePrograms) {
              selectedProgramsData = result.data.userActivePrograms.map(
                (item) => ({
                  label: item.program?.name,
                  value: item?.program_id,
                })
              );
              setEditSelectedProgram(selectedProgramsData);
            }

            setFileData(result.data?.userDetail?.image);

            if (result.data.userSocialLinks) {
              result.data.userSocialLinks.forEach((item) => {
                setValue(item.platform, item.url);
              });
            }
          }
        } else {
          toast.error(result.message);
        }
      } catch (error) {
        if (error.response?.status === 401) {
          EmptyLocalStorage();
          history.push("/");
        } else {
          toast.error(error.response?.data?.message?.replace(/_/g, " "));
        }
      } finally {
        setLoader(false);
      }
    };

    getUserById(id);
  }, [props.match.params.id]);

  const setBodyTypeFunc = (selectedBodyTypes) => {
    let data = [];
    selectedBodyTypes.map((item) => {
      data.push(item.value);
    });
    setSelectedBodyType(data);
    setEditSelectedBodyType(selectedBodyTypes);
  };

  const setInjuryProgramFunc = (selectedInjury) => {
    let data = [];
    selectedInjury.map((item) => {
      data.push(item.value);
    });
    setSelectedInjury(data);
    setEditSelectedInjury(selectedInjury);
  };

  const optionsSports = (selectedSport) => {
    let data = [];
    selectedSport.map((item) => {
      data.push(item.value);
    });
    setSelectedSport(data);
    setEditSelectedSport(selectedSport);
  };

  const handleChange = (event) => {
    setFileData(URL.createObjectURL(event.target.files[0]));
    setImg(event.target.files[0]);
  };

  const getBodyTypes = async () => {
    await GetBodyTypes("", "", "", 0, "asc", "name").then((result) => {
      if (result.status && result.data) {
        setBodyTypes(result.data);
      }
    });
  };

  const getInjuryPrograms = async () => {
    await GetInjuries("", "", "", 0, 10, "asc", "name").then((result) => {
      if (result.status && result.data) {
        setInjuryPrograms(result.data);
      }
    });
  };

  const getCountries = async () => {
    await GetCountries(null, null, null, null, 0).then((result) => {
      if (result.status && result.data) {
        const reversedCountries = result.data.reverse();
        setCountyList(reversedCountries);
      }
    });
  };

  const getOccupations = async () => {
    await GetOccupations(null, null, null, 0, 10).then((result) => {
      if (result.status && result.data) {
        setOccupation(result.data);
      }
    });
  };
  const getSports = async () => {
    await GetSports(null, null, null, 0, 10).then((result) => {
      if (result.status && result.data) {
        setSport(result.data);
      }
    });
  };

  const getPrograms = async () => {
    await GetPrograms(null, null, null, null, 0).then((result) => {
      if (result.status && result.data) {
        setPrograms(result.data);
      }
    });
  };

  // const ageDate = (date, format = age.format) => {
  //     let object = { date, format }
  //         setAge({
  //             object
  //         })
  // }

  const onAgeChange = (e) => {
    const object = e.target.value;
    const currentDate = new Date();
    const date = new Date(object);
    if (date > currentDate) {
      setError("age", { message: "Age cannot be greater than current date" });
    } else {
      clearErrors("age");
      setAge(date.toLocaleDateString());
    }
  };

  const onCountryChange = (e) => {
    setSelectCountry(e.target.value);
  };

  const optionsOccupations = (selectedOccupation) => {
    // For Single Select
    setSelectedOccupation(selectedOccupation.value);
    setEditSelectedOccupation(selectedOccupation);
  };

  const optionsPrograms = (selectedProgram) => {
    //data.push({ program_id: selectedProgram.value });
    // setEditSelectedProgram({
    //   label: selectedProgram.label,
    //   value: selectedProgram.value,
    // });
    let data = [];
    selectedProgram.map((item) => {
      data.push({ program_id: item.value });
    });
    setSelectedProgram(data);
    setEditSelectedProgram(selectedProgram);
  };

  const password = useRef({});
  password.current = watch("password", "");

  const onSubmit = async (data) => {
    setLoader(true);
    let id = props.match.params.id;
    let imageUrl = null;
    let date = new Date(data.age.toLocaleString());
    const currentDate = new Date();
    if (date > currentDate) {
      setError("age", { message: "Age cannot be greater than current date" });
      setLoader(false);
      return false;
    } else {
      clearErrors("age");
    }

    let filledSocialLinks = [];
    if (data.facebook) {
      filledSocialLinks.push({
        platform: "facebook",
        url: data.facebook,
      });
    }
    if (data.instagram) {
      filledSocialLinks.push({
        platform: "instagram",
        url: data.instagram,
      });
    }
    if (data.linkedin) {
      filledSocialLinks.push({
        platform: "linkedin",
        url: data.linkedin,
      });
    }

    let dt = {
      name: data.full_name,
      email: data.email,
      phone: data.phone,
      gender: data.gender,
      weight_unit: data.weight_unit,
      country: selectCountry,
      dob: data.age,
      user_sports: selectedSport,
      occupation_id: selectedOccupation,
      user_programs: selectedProgram,
      social_links: filledSocialLinks,
      injury_id: selectedInjury,
      body_type_id: selectedBodyType,
      subscription_type: data.subscription_type,
    };

    if (data.password && data.password_confirmation) {
      dt.password = data.password;
      dt.password_confirmation = data.password_confirmation;
    }

    if (img) {
      let s3Data = await uploadToS3(img.type, img.name, img, "user");
      if (!s3Data) {
        toast.error("Uploading failed");
        setLoader(false);
        return false;
      }

      // imageUrl = await UploadImageToS3(data.image[0])
      imageUrl = s3Data;
      dt.image = imageUrl;
    } else {
      dt.image = file;
    }

    try {
      if (phoneValue.length > 0 && isValidPhoneNumber(phoneValue)) {
        clearErrors("phone");
        dt.phone = phoneValue;
      } else {
        setError("phone", {
          message: phoneValue
            ? isValidPhoneNumber(phoneValue)
              ? undefined
              : "Invalid phone number"
            : "Phone number required",
        });
        // toast.error(phoneValue ? (isValidPhoneNumber(phoneValue) ? undefined : 'Invalid phone number') : 'Phone number required');
        setLoader(false);
        return false;
      }
    } catch {
      toast.error(
        phoneValue
          ? isValidPhoneNumber(phoneValue)
            ? undefined
            : "Invalid phone number"
          : "Phone number required"
      );
      setLoader(false);
      return false;
    }
    //
    // if(data.image.length>0){
    //     imageUrl = await UploadImageToS3(data.image[0])
    //     dt.image = imageUrl.data
    // }

    // console.log("Update:", data);

    // setDisableButton(true)
    await UpdateUser(id, dt)
      .then(async (data) => {
        setLoader(false);
        if (data.status) {
          // await updateUser(data.data).then(() => {
          toast.success(data.message);
          history.push("/manage-users");
          // })
        } else {
          setLoader(false);
          // setDisableButton(false)
          toast.error(data.message.replace(/_/g, " "));
        }
      })
      .catch((error) => {
        setLoader(false);
        // setDisableButton(false)
        if (error.response.status == 401) {
          EmptyLocalStorage();
          history.push("/");
        } else {
          return toast.error(error.response.data.message.replace(/_/g, " "));
        }
      });
  };

  const handlePhoneChange = (number) => {
    if (number?.length > 0 && isValidPhoneNumber(number)) {
      clearErrors("phone");
      setPhoneValue(number);
    } else {
      setError("phone", {
        message: number
          ? isValidPhoneNumber(number)
            ? undefined
            : "Invalid phone number"
          : "Phone number required",
      });
    }
  };

  const resetImage = () => {
    setFileData(null);
    setImg(null);
  };

  return (
    <>
      {loader && <FullPageLoader loading={loader} />}

      <div className="users-index">
        <div className="add-new-user">
          <div className="page-title">
            <h1>Edit User - {data?.name}</h1>
            <Button onClick={BackManageUser} className="btn common-btn">
              Back
            </Button>
          </div>
          <div className="common-form">
            <Form
              encType="multipart/form-data"
              className="formAddUser"
              onSubmit={handleSubmit(onSubmit)}
            >
              <Row>
                <Col sm={3} className="pe-0">
                  <Form.Group
                    controlId="formFile"
                    onChange={(e) => handleChange(e)}
                    className="custom-file-upload mb-0"
                  >
                    <Form.Label className="common-btn w-100 text-center">
                      Upload Image
                    </Form.Label>
                    <Form.Control
                      type="file"
                      accept="image/png, image/jpeg, image/jpg, image/gif"
                      {...register("image")}
                    />
                  </Form.Group>
                </Col>
                <Col sm={12} className="ps-0">
                  <ul className={"list-inline list-variations mb-0"}>
                    <li
                      className={
                        "list-inline-item d-flex justify-content-between mb-0"
                      }
                    >
                      {file && (
                        <div className="image-item">
                          <img
                            crossOrigin={cross_origin ? "anonymous" : null}
                            onError={({ currentTarget }) => {
                              currentTarget.onerror = null; // prevents looping
                              currentTarget.src = constants.IMAGENOTFOUND;
                            }}
                            src={file ? file : defaultImage}
                            alt={"User Profile"}
                            width="50%"
                            height="50%"
                          />
                          <div className="delete-icon">
                            <Button
                              type="button"
                              className="btn common-btn"
                              onClick={() => resetImage()}
                            >
                              <TrashIcon />
                            </Button>
                          </div>
                        </div>
                      )}
                    </li>
                  </ul>
                </Col>
              </Row>

              <Row>
                <Col sm={6}>
                  <Form.Group className="mb-3">
                    <Form.Label>Full Name*</Form.Label>
                    <Form.Control
                      placeholder="Enter Full Name"
                      id={"full_name"}
                      autoFill={"off"}
                      maxLength={25}
                      minLength={3}
                      {...register("full_name", {
                        required: {
                          value: "required",

                          message: "Full Name is required",
                        },
                        pattern: {
                          value: /^[a-zA-Z0-9_ ]*$/,
                          message: "This field must contain only letters",
                        },
                        minLength: {
                          value: 3,
                          message: "min length is 3",
                        },
                        maxLength: {
                          value: 25,
                          message: "Max length is 25",
                        },
                      })}
                      type="text"
                    />
                    {errors.full_name && (
                      <Form.Text className="text-muted validationText hasError">
                        {errors.full_name.message}
                      </Form.Text>
                    )}
                  </Form.Group>
                </Col>

                <Col sm={6} className="ps-0">
                  <Form.Group className="mb-3">
                    <Form.Label>Email Address*</Form.Label>
                    <Form.Control
                      id={"email"}
                      placeholder="Enter Email Address"
                      defaultValue={data.email}
                      maxLength={255}
                      {...register("email", {
                        required: {
                          value: "required",
                          message: "email is required",
                        },
                        pattern: {
                          value: /\S+@\S+\.\S+/,
                          message: "Entered value does not match email format",
                        },
                        maxLength: {
                          value: 255,
                          message: "max length is 255",
                        },
                      })}
                      type="email"
                    />
                    {errors.email && (
                      <Form.Text className="text-muted validationText hasError">
                        {errors.email.message}
                      </Form.Text>
                    )}
                  </Form.Group>
                </Col>

                <Col sm={6}>
                  <Form.Group className="mb-3">
                    <Form.Label>DOB*</Form.Label>
                    <Form.Control
                      id={"age"}
                      placeholder="Enter Age"
                      value={age.date}
                      maxLength={3}
                      {...register("age", {
                        required: {
                          value: "required",
                          message: "Age is required",
                        },
                        onChange: (e) => onAgeChange(e),
                      })}
                      type="date"
                    />
                    {errors.age && (
                      <Form.Text className="text-muted validationText hasError">
                        {errors.age.message}
                      </Form.Text>
                    )}
                  </Form.Group>
                </Col>

                <Col sm={6} className="ps-0">
                  <Form.Group className="mb-3">
                    <Form.Label>Gender*</Form.Label>
                    <Form.Select
                      id={"gender"}
                      type="text"
                      placeholder="Select Gender"
                      {...register("gender", {
                        required: {
                          value: "required",
                          message: "gender is required",
                        },
                      })}
                    >
                      <option selected disabled value="">
                        Select Gender
                      </option>
                      <option value={genders.FEMALE}>Female</option>
                      <option value={genders.MALE}>Male</option>
                    </Form.Select>
                    {errors.gender && (
                      <Form.Text className="text-muted validationText hasError">
                        {errors.gender.message}
                      </Form.Text>
                    )}
                  </Form.Group>
                </Col>

                <Col sm={6}>
                  <Form.Group className="mb-3">
                    <Form.Label>Weight Unit*</Form.Label>
                    <Form.Select
                      placeholder="Select Weight Unit"
                      id={"weight_unit"}
                      {...register("weight_unit", {
                        required: {
                          value: "required",
                          message: " Weight unit is required",
                        },
                      })}
                    >
                      <option value="" selected={true} disabled={true}>
                        Select Weight Unit
                      </option>
                      <option value={weight_unit.KG}>Kg</option>
                      <option value={weight_unit.LBS}>Lbs</option>
                    </Form.Select>
                    {errors.weight_unit && (
                      <Form.Text className="text-muted validationText hasError">
                        {errors.weight_unit.message}
                      </Form.Text>
                    )}
                  </Form.Group>
                </Col>

                <Col sm={6} className="ps-0">
                  <Form.Group className="mb-3">
                    <Form.Label>Phone Number*</Form.Label>
                    <PhoneInput
                      id={"phone"}
                      name="phone"
                      limitMaxLength={20}
                      className="phone-input"
                      placeholder="Enter phone number"
                      international
                      value={phoneValue}
                      defaultCountry="US"
                      onChange={handlePhoneChange}
                    />
                    {errors.phone && (
                      <Form.Text className="text-muted validationText hasError">
                        {errors.phone.message}
                      </Form.Text>
                    )}
                  </Form.Group>
                </Col>

                <Col sm={6}>
                  <div className={"pass-field mb-3"}>
                    <Form.Label>Password*</Form.Label>
                    <InputGroup>
                      <FormControl
                        id={"password"}
                        placeholder="Enter Password"
                        autoComplete={"off"}
                        maxLength={255}
                        minLength={8}
                        {...register("password", {
                          minLength: {
                            value: 8,
                            message: "Min length is 8",
                          },
                          maxLength: {
                            value: 255,
                            message: "Max length is 255",
                          },
                          required: false,
                        })}
                        onClick={() => setConfirmPass(!confirmPass)}
                        type={pass ? "password" : "text"}
                      />
                      <Button
                        variant="outline-secondary"
                        id="button-addon2"
                        onClick={() => TogglePass(!pass)}
                      >
                        {pass ? <FaEye /> : <FaEyeSlash />}
                      </Button>
                    </InputGroup>
                    {errors.password && (
                      <Form.Text className="text-muted validationText hasError">
                        {errors.password.message}
                      </Form.Text>
                    )}
                  </div>
                </Col>

                <Col sm={6} className="ps-0">
                  <div className={"pass-field mb-3"}>
                    <Form.Label>Confirm Password*</Form.Label>
                    <InputGroup>
                      <Form.Control
                        id={"password_confirmation"}
                        placeholder="Enter Password Again"
                        maxLength={255}
                        minLength={8}
                        {...register("password_confirmation", {
                          minLength: {
                            value: 8,
                            message: "Min length is 8",
                          },
                          validate: password.current
                            ? (value) =>
                                value === password.current ||
                                "The passwords do not match"
                            : "",
                          maxLength: {
                            value: 255,
                            message: "Max length is 255",
                          },
                          required: false,
                        })}
                        type={confPass ? "password" : "text"}
                      />
                      <Button
                        variant="outline-secondary"
                        id="button-addon2"
                        onClick={() => ToggleConfPass(!confPass)}
                      >
                        {confPass ? <FaEyeSlash /> : <FaEye />}
                      </Button>
                    </InputGroup>
                    {errors.password_confirmation && (
                      <Form.Text className="text-muted validationText hasError">
                        {errors.password_confirmation.message}
                      </Form.Text>
                    )}
                  </div>
                </Col>

                <Col sm={6}>
                  <Form.Group className="mb-3">
                    <Form.Label>Country</Form.Label>
                    <Form.Select
                      id={"country"}
                      {...register("country", {
                        onChange: (e) => onCountryChange(e),
                      })}
                    >
                      <option value="" selected={true} disabled={true}>
                        Select Country
                      </option>
                      {countries &&
                        countries.map((country, index) => (
                          <option
                            value={country.id}
                            selected={
                              country.id == data?.userDetail?.country
                                ? true
                                : false
                            }
                          >
                            {country.nicename}
                          </option>
                        ))}
                    </Form.Select>
                    {errors.country && (
                      <Form.Text className="text-muted validationText hasError">
                        {errors.country.message}
                      </Form.Text>
                    )}
                  </Form.Group>
                </Col>

                <Col sm={6} className="ps-0">
                  <Form.Group className="mb-3">
                    <Form.Label>Occupation</Form.Label>
                    <Select
                      id={"occupations"}
                      value={editSelectedOccupation}
                      // isMulti
                      options={
                        occupations &&
                        occupations.map((e) => ({ label: e.name, value: e.id }))
                      }
                      className="basic-multi-select"
                      onChange={optionsOccupations}
                      classNamePrefix="select"
                    />
                    {errors.occupations && (
                      <Form.Text className="text-muted validationText hasError">
                        {errors.occupations.message}
                      </Form.Text>
                    )}
                  </Form.Group>
                </Col>

                <Col sm={6}>
                  <Form.Group className="mb-3">
                    <Form.Label>Sports</Form.Label>
                    <Select
                      id={"sports"}
                      value={editSelectedSport}
                      isMulti
                      options={
                        sports &&
                        sports.map((e) => ({ label: e.name, value: e.id }))
                      }
                      className="basic-multi-select"
                      onChange={optionsSports}
                      classNamePrefix="select"
                    />
                    {errors.sports && (
                      <Form.Text className="text-muted validationText hasError">
                        {errors.sports.message}
                      </Form.Text>
                    )}
                  </Form.Group>
                </Col>

                <Col sm={6} className="ps-0">
                  <Form.Group className="mb-3">
                    <Form.Label>Programs</Form.Label>
                    <Select
                      id={"programs"}
                      isMulti
                      value={editSelectedProgram}
                      options={
                        programs &&
                        programs.map((e) => ({ label: e.name, value: e.id }))
                      }
                      className="basic-multi-select"
                      onChange={optionsPrograms}
                      classNamePrefix="select"
                    />
                    {errors.programs && (
                      <Form.Text className="text-muted validationText hasError">
                        {errors.programs.message}
                      </Form.Text>
                    )}
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col md={6}>
                  <Form.Group className="mb-3">
                    <Form.Label>Injury Program</Form.Label>
                    <Select
                      id={"injury_id"}
                      isMulti
                      isClearable
                      value={editSelectedInjury}
                      options={
                        injuryPrograms &&
                        injuryPrograms.map((e) => ({
                          label: e.name,
                          value: e.id,
                        }))
                      }
                      className="basic-multi-select"
                      classNamePrefix="select"
                      onChange={setInjuryProgramFunc}
                    />
                    {errors.injury_id && (
                      <Form.Text className="text-muted validationText hasError">
                        {errors.injury_id.message}
                      </Form.Text>
                    )}
                  </Form.Group>
                </Col>
                <Col md={6}>
                  <Form.Group className="mb-3">
                    <Form.Label>Body Type</Form.Label>
                    <Select
                      id={"body_type_id"}
                      isMulti
                      isClearable
                      value={editSelectedBodyType}
                      options={
                        bodyTypes &&
                        bodyTypes.map((e) => ({ label: e.name, value: e.id }))
                      }
                      className="basic-multi-select"
                      classNamePrefix="select"
                      onChange={setBodyTypeFunc}
                    />
                    {errors.body_type_id && (
                      <Form.Text className="text-muted validationText hasError">
                        {errors.body_type_id.message}
                      </Form.Text>
                    )}
                  </Form.Group>
                </Col>
              </Row>

              <Row>
                <Col sm={6}>
                  <Form.Group className="mb-3">
                    <Form.Label>Subscription Type*</Form.Label>
                    <Form.Select
                      id={"subscription_type"}
                      placeholder="Select Subscription Type"
                      {...register("subscription_type", {
                        required: {
                          value: "required",
                          message: "Subscription Type is required",
                        },
                      })}
                    >
                      <option selected disabled value="">
                        Select Subscription Type
                      </option>
                      <option value={subscription_type.ADD_SUB}>
                        Subscription
                      </option>
                      <option value={subscription_type.ONE_TIME_SUB}>
                        One Time Program
                      </option>
                    </Form.Select>
                    {errors.subscription_type && (
                      <Form.Text className="text-muted validationText hasError">
                        {errors.subscription_type.message}
                      </Form.Text>
                    )}
                  </Form.Group>
                </Col>
              </Row>

              {/* Social Links */}
              <Row>
                <Col sm={4}>
                  <div className="mb-3">
                    <Form.Label htmlFor="basic-url">
                      Facebook URL(optional)
                    </Form.Label>
                    <InputGroup>
                      <InputGroup.Text id="basic-addon3">
                        https://facebook.com/
                      </InputGroup.Text>
                      <Form.Control
                        id="fb-basic-url"
                        aria-describedby="basic-addon3"
                        type={"text"}
                        maxLength={255}
                        {...register("facebook", {
                          required: false,
                        })}
                      />
                    </InputGroup>
                    {errors.fb && (
                      <Form.Text className="text-muted validationText hasError">
                        {errors.fb.message}
                      </Form.Text>
                    )}
                  </div>
                </Col>
                <Col sm={4} className="px-0">
                  <div className="mb-3">
                    <Form.Label htmlFor="basic-url">
                      Instagram URL(optional)
                    </Form.Label>
                    <InputGroup>
                      <InputGroup.Text id="basic-addon3">
                        https://instagram.com/
                      </InputGroup.Text>
                      <Form.Control
                        id="insta-basic-url"
                        aria-describedby="basic-addon3"
                        type={"text"}
                        maxLength={255}
                        {...register("instagram", {
                          required: false,
                        })}
                      />
                    </InputGroup>
                    {errors.insta && (
                      <Form.Text className="text-muted validationText hasError">
                        {errors.insta.message}
                      </Form.Text>
                    )}
                  </div>
                </Col>
                <Col sm={4}>
                  <div className="mb-3">
                    <Form.Label htmlFor="basic-url">
                      Linkedin URL(optional)
                    </Form.Label>
                    <InputGroup>
                      <InputGroup.Text id="basic-addon3">
                        https://linkedin.com/
                      </InputGroup.Text>
                      <Form.Control
                        id="linkedin-basic-url"
                        aria-describedby="basic-addon3"
                        type={"text"}
                        maxLength={255}
                        {...register("linkedin", {
                          required: false,
                        })}
                      />
                    </InputGroup>
                    {errors.linkedin && (
                      <Form.Text className="text-muted validationText hasError">
                        {errors.linkedin.message}
                      </Form.Text>
                    )}
                  </div>
                </Col>
              </Row>

              <Row>
                <Col sm={2} className="ms-auto">
                  {loader ? (
                    <div className="spin-loader">
                      <FadeLoader color={"#EC1246"} height={10} />
                    </div>
                  ) : (
                    <Button
                      type={"submit"}
                      className={"btn common-btn w-100 mt-3"}
                      value={"Update User"}
                    >
                      Update User
                    </Button>
                  )}
                  {/*<input type={"submit"} className={"btn common-btn w-100 mt-3"}*/}
                  {/*       disabled={disableButton}*/}
                  {/*       value={"Update User"}/>*/}
                </Col>
              </Row>
            </Form>
          </div>
        </div>
      </div>
    </>
  );
}

export default EditUser;
