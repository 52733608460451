import { apiUrl } from '../../utils/constants';
import { GET } from '../api.service.wrapper';

export const GetDashboard = async (params) => {
    const response = await GET(apiUrl.dashboard, params);
    return response;
}

export const GetDashboardSummary = async () => {
    const response = await GET(apiUrl.dashboard_summary);
    return response;
}

export const GetDashboardDetails = async (params) => {
    const response = await GET(apiUrl.dashboard_data);
    return response;
}