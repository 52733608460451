import React, { useEffect, useRef, useState } from "react";
import {
  Button,
  Col,
  Form,
  FormControl,
  InputGroup,
  Row,
} from "react-bootstrap";
import { useForm } from "react-hook-form";
import { useHistory, Prompt } from "react-router-dom";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import Select from "react-select";
import {
  constants,
  cross_origin,
  genders,
  weight_unit,
  confirmMessage,
  subscription_type,
} from "../../utils/constants";
import { uploadToS3 } from "../../utils/helpers";

import { CreateUser } from "../../services/users.service";
import { toast } from "react-toastify";
import { TrashIcon } from "../../components/SvgIcons";
import { EmptyLocalStorage } from "../../services/auth/auth.service";
import { GetSports } from "../../services/sports.service";
import { GetCountries } from "../../services/countries.service";
import { GetPrograms } from "../../services/programs.service";
import { GetInjuries } from "../../services/injurys.service";
import { GetBodyTypes } from "../../services/body_types.service";
import { FadeLoader } from "react-spinners";
import PhoneInput, { isValidPhoneNumber } from "react-phone-number-input";
import "react-phone-number-input/style.css";
import { GetOccupations } from "../../services/occupations.service";
import FullPageLoader from "../../components/FullPageLoader";

function AddNewUser(props) {
  let history = useHistory();
  const [pass, TogglePass] = useState(true);
  const [confPass, ToggleConfPass] = useState(true);
  const [confirmPass, setConfirmPass] = useState(true);
  const [disableButton, setDisableButton] = useState(false);
  const [countries, setCountyList] = useState([]);
  const [sports, setSport] = useState([]);
  const [programs, setPrograms] = useState([]);
  const [age, setAge] = useState({ format: "mm/dd/yyyy" });
  const [selectCountry, setSelectCountry] = useState();
  const [file, setFileData] = useState(null);
  const [defaultImage, setDefaultImage] = useState(false);
  const [selectedSport, setSelectedSport] = useState([]);
  const [selectedProgram, setSelectedProgram] = useState([]);
  const [img, setImg] = useState("");
  const [loader, setLoader] = useState(false);
  const [selectedOccupation, setSelectedOccupation] = useState();
  const [occupations, setOccupation] = useState([]);
  const [value, setValue] = useState("");
  const [injuryPrograms, setInjuryPrograms] = useState([]);
  const [injuryProgram, setInjuryProgram] = useState("");
  const [bodyTypes, setBodyTypes] = useState([]);
  const [bodyType, setBodyType] = useState("");

  const {
    register,
    watch,
    setError,
    clearErrors,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: "onBlur",
  });

  const BackManageUser = () => {
    history.push("/manage-users");
  };

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");

  const handleNameChange = (event) => {
    if (event.target.value == "") {
      setError("full_name", { message: "Full name is required" });
      return false;
    }
    var reg = /^[a-zA-Z0-9_ ]*$/;
    if (!reg.test(event.target.value)) {
      setError("full_name", {
        message: "This field must contain only letters",
      });
      return false;
    }
    setName(event.target.value);
  };

  const handleEmailChange = (event) => {
    if (event.target.value == "") {
      setError("email", { message: "Email is required" });
      return false;
    }
    setEmail(event.target.value);
  };

  const navigationPrompt = async (location) => {
    if (name != "" && email != "" && value != "") {
      const userConfirmed = window.confirm(confirmMessage.message);
      if (userConfirmed) {
        setDisableButton(true);
        setLoader(true);
        await CreateUser({
          name: name,
          email: email,
          phone: value,
          device_type: "web",
          device_token: "user",
          password: "123456",
          password_confirmation: "123456",
          role_id: constants.ROLES.ROLE_USER.toString(),
        })
          .then(async (data) => {
            setLoader(false);
            if (data.status) {
              return userConfirmed;
              // await createUser(data.data.user).then(() => {
              // toast.success(data.message);
              // history.push('/manage-users');
              // })
            } else {
              setLoader(false);
              setDisableButton(false);
              toast.error(data.message.replace(/_/g, " "));
            }
          })
          .catch((error) => {
            setLoader(false);
            setDisableButton(false);
            if (error.response.status == 401) {
              EmptyLocalStorage();
              history.push("/");
            } else {
              return toast.error(
                error.response.data.message.replace(/_/g, " ")
              );
            }
          });
      }
    }
    return true;
  };

  useEffect(() => {
    const body = document.querySelector("#root");
    body.scrollIntoView(
      {
        behavior: "smooth",
      },
      500
    );
    getCountries();
    getSports();
    getPrograms();
    getOccupations();
    getInjuryPrograms();
    getBodyTypes();
    setTimeout(() => {
      const elements = document.querySelectorAll(".PhoneInputCountryIconImg");

      elements.forEach((element) => {
        element.setAttribute("crossOrigin", "anonymous");
      });
    }, 1500);
  }, []);

  const handleChange = (event) => {
    setFileData(URL.createObjectURL(event.target.files[0]));
    setImg(event.target.files[0]);
  };

  const getBodyTypes = async () => {
    await GetBodyTypes("", "", "", 0, "asc", "name").then((result) => {
      if (result.status && result.data) {
        setBodyTypes(result.data);
      }
    });
  };

  const getCountries = async () => {
    await GetCountries(null, null, null, null, 0).then((result) => {
      if (result.status && result.data) {
        // setCountyList(result.data);
        const reversedCountries = result.data.reverse();
        setCountyList(reversedCountries);
      }
    });
  };

  const getInjuryPrograms = async () => {
    await GetInjuries("", "", "", 0, 10, "asc", "name").then((result) => {
      if (result.status && result.data) {
        setInjuryPrograms(result.data);
      }
    });
  };

  const getOccupations = async () => {
    await GetOccupations(null, null, null, 0, 10).then((result) => {
      if (result.status && result.data) {
        setOccupation(result.data);
      }
    });
  };
  const getSports = async () => {
    await GetSports(null, null, null, 0, 10).then((result) => {
      if (result.status && result.data) {
        setSport(result.data);
      }
    });
  };

  const getPrograms = async () => {
    await GetPrograms(null, null, null, null, 0).then((result) => {
      if (result.status && result.data) {
        setPrograms(result.data);
      }
    });
  };

  // const ageDate = (date, format = age.format) => {
  //     let object = { date, format }
  //
  //     setAge({
  //         object
  //     })
  //
  // }
  const currentDate = new Date().toISOString().slice(0, 10);

  const onAgeChange = (e) => {
    const object = e.target.value;
    const currentDate = new Date();
    const date = new Date(object);

    if (date > currentDate) {
      setError("age", { message: "Age cannot be greater than current date" });
    } else {
      clearErrors("age");
      setAge(date.toLocaleDateString());
    }
  };
  const onCountryChange = (e) => {
    setSelectCountry(e.target.value);
  };

  const optionsSports = (selectedSport) => {
    let data = [];
    selectedSport.map((item) => {
      data.push(item.value);
    });
    setSelectedSport(data);
  };

  const setBodyTypeFunc = (selectedBodyTypes) => {
    let data = [];
    selectedBodyTypes.map((item) => {
      data.push(item.value);
    });
    setValue("body_type_id", data);
    setBodyType(data);
  };

  const setInjuryProgramFunc = (selectedInjury) => {
    let data = [];
    selectedInjury.map((item) => {
      data.push(item.value);
    });
    setValue("injury_id", data);
    setInjuryProgram(data);
  };

  const optionsPrograms = (selectedProgram) => {
    let data = [];
    selectedProgram.map((item) => {
      data.push(item.value);
      //data.push({ program_id: selectedProgram.value });
    });
    setSelectedProgram(data);
  };

  const handlePhoneChange = (number) => {
    if (number?.length > 0 && isValidPhoneNumber(number)) {
      clearErrors("phone");
      setValue(number);
      //setIsPhone(true)
    } else {
      setError("phone", {
        message: number
          ? isValidPhoneNumber(number)
            ? undefined
            : "Invalid phone number"
          : "Phone number required",
      });
    }
  };
  const password = useRef({});
  password.current = watch("password", "");

  const onSubmit = async (data) => {
    if (data.full_name == "") {
      setError("full_name", { message: "Fullname is required" });
      return false;
    }
    var reg = /^[a-zA-Z0-9_ ]*$/;
    if (!reg.test(data.full_name)) {
      setError("full_name", {
        message: "This field must contain only letters",
      });
      return false;
    }

    if (data.email == "") {
      setError("email", { message: "Email is required" });
      return false;
    }

    if (data.phone == "") {
      setError("phone", { message: "Phone no is required" });
      return false;
    }

    setLoader(true);
    let imageUrl = null;
    let date = new Date(data.age.toLocaleString());
    const currentDate = new Date();

    if (date > currentDate) {
      setError("age", { message: "Age cannot be greater than current date" });
      setLoader(false);
      return false;
    } else {
      clearErrors("age");
    }

    if (img) {
      let s3Data = await uploadToS3(img.type, img.name, img, "user");
      if (!s3Data) {
        toast.error("Uploading failed");
        setLoader(false);
        return false;
      }

      // imageUrl = await UploadImageToS3(data.image[0])
      imageUrl = s3Data;
      data.image = s3Data;
    } else {
      data.image = imageUrl;
    }
    try {
      if (value.length > 0 && isValidPhoneNumber(value)) {
        clearErrors("phone");
        data.phone = value;
      } else {
        setError("phone", {
          message: value
            ? isValidPhoneNumber(value)
              ? undefined
              : "Invalid phone number"
            : "Phone number required",
        });
        setLoader(false);
        return false;
      }
    } catch {
      toast.error(
        value
          ? isValidPhoneNumber(value)
            ? undefined
            : "Invalid phone number"
          : "Phone number required"
      );
      setLoader(false);
      return false;
    }

    let filledSocialLinks = [];
    if (data.facebook) {
      filledSocialLinks.push({
        platform: "facebook",
        url: data.facebook,
      });
    }
    if (data.instagram) {
      filledSocialLinks.push({
        platform: "instagram",
        url: data.instagram,
      });
    }
    if (data.linkedin) {
      filledSocialLinks.push({
        platform: "linkedin",
        url: data.linkedin,
      });
    }

    setDisableButton(true);
    await CreateUser({
      name: data.full_name,
      email: data.email,
      phone: data.phone,
      gender: data.gender,
      weight_unit: data.weight_unit,
      country: selectCountry,
      image: data.image,
      password: data.password,
      occupation_id: selectedOccupation,
      injury_id: injuryProgram,
      body_type_id: bodyType,
      subscription_type: data.subscription_type,
      password_confirmation: data.password_confirmation,
      dob: data.age,
      role_id: constants.ROLES.ROLE_USER.toString(),
      device_type: "web",
      device_token: "user",
      user_sports: selectedSport,
      user_programs: selectedProgram,
      social_links: filledSocialLinks,
    })
      .then(async (data) => {
        setLoader(false);
        if (data.status) {
          // await createUser(data.data.user).then(() => {
          toast.success(data.message);
          history.push("/manage-users");
          // })
        } else {
          setLoader(false);
          setDisableButton(false);
          toast.error(data.message.replace(/_/g, " "));
        }
      })
      .catch((error) => {
        setLoader(false);
        setDisableButton(false);
        if (error.response.status == 401) {
          EmptyLocalStorage();
          history.push("/");
        } else {
          return toast.error(error.response.data.message.replace(/_/g, " "));
        }
      });
  };

  const resetImage = () => {
    setFileData(null);
    setImg(null);
  };

  const optionsOccupations = (selectedOccupation) => {
    // For Single Select
    setSelectedOccupation(selectedOccupation.value);
  };

  return (
    <div className="users-index">
      <div className="add-new-user">
        <div className="page-title">
          <h1>Create User</h1>
          <Button onClick={BackManageUser} className="btn common-btn">
            Back
          </Button>
        </div>
        <Prompt
          when={name != "" && email != "" && value != ""}
          message={navigationPrompt}
        />
        <div className="common-form">
          <Form
            encType="multipart/form-data"
            className="formAddUser"
            autoComplete="off"
            role="presentation"
            onSubmit={handleSubmit(onSubmit)}
          >
            <Row>
              <Col sm={3} className="pe-0">
                <Form.Group
                  controlId="formFile"
                  onChange={(e) => handleChange(e)}
                  className="custom-file-upload mb-0"
                >
                  <Form.Label className={"common-btn w-100 text-center"}>
                    Upload Image
                  </Form.Label>
                  <Form.Control
                    type="file"
                    accept="image/png, image/jpeg, image/jpg, image/gif"
                    {...register("image")}
                  />
                </Form.Group>
                <ul className={"list-inline list-variations mb-0"}>
                  <li
                    className={
                      "list-inline-item d-flex justify-content-between mb-0"
                    }
                  >
                    {file && (
                      <div className="image-item">
                        <img
                          crossOrigin={cross_origin ? "anonymous" : null}
                          onError={({ currentTarget }) => {
                            currentTarget.onerror = null; // prevents looping
                            currentTarget.src = constants.IMAGENOTFOUND;
                          }}
                          src={file ? file : defaultImage}
                          alt={"User Profile"}
                          width="50%"
                          height="50%"
                        />
                        <div className="delete-icon">
                          <Button
                            type="button"
                            className="btn common-btn"
                            onClick={() => resetImage()}
                          >
                            <TrashIcon />
                          </Button>
                        </div>
                      </div>
                    )}
                  </li>
                </ul>
              </Col>
            </Row>
            <Row>
              <Col sm={6} className="pe-0">
                <Form.Group className="mb-3" controlId="full_name">
                  <Form.Label>Full Name*</Form.Label>
                  <Form.Control
                    placeholder="Enter Full Name"
                    // value={name}
                    // onChange={handleNameChange}
                    {...register("full_name", {})}
                    type="text"
                  />
                  {errors.full_name && (
                    <Form.Text className="text-muted validationText hasError">
                      {errors.full_name.message}
                    </Form.Text>
                  )}
                </Form.Group>
              </Col>
              <Col sm={6}>
                <Form.Group className="mb-3" controlId="email">
                  <Form.Label>Email Address*</Form.Label>
                  <Form.Control
                    placeholder="Enter Email Address"
                    autocomplete="off"
                    autofill="off"
                    maxLength={255}
                    {...register("email", {})}
                    // value={email}
                    // onChange={handleEmailChange}
                    type="email"
                  />
                  {errors.email && (
                    <Form.Text className="text-muted validationText hasError">
                      {errors.email.message}
                    </Form.Text>
                  )}
                </Form.Group>
              </Col>
              <Col sm={6} className="pe-0">
                <Form.Group className="mb-3" controlId="dob">
                  <Form.Label>DOB*</Form.Label>
                  <Form.Control
                    placeholder="Enter Age"
                    id={"formSignUpAge"}
                    value={age.date}
                    max={currentDate}
                    maxLength={3}
                    {...register("age", {
                      required: {
                        value: "required",
                        message: "Age is required",
                      },
                      onChange: (e) => onAgeChange(e),
                      // pattern: {
                      //     value: /^[1-9]?[0-9]{1}$|^100$/,
                      //     message: "Age should be between 1 and 100",
                      // },
                      // maxLength: {
                      //     value: 3,
                      //     message: "Max length is 25"
                      // },
                    })}
                    type="date"
                  />
                  {errors.age && (
                    <Form.Text className="text-muted validationText hasError">
                      {errors.age.message}
                    </Form.Text>
                  )}
                </Form.Group>
              </Col>
              <Col sm={6}>
                <Form.Group className="mb-3" controlId="gender">
                  <Form.Label>Gender*</Form.Label>
                  <Form.Select
                    placeholder="Select Gender"
                    {...register("gender", {
                      required: {
                        value: "required",
                        message: "gender is required",
                      },
                    })}
                  >
                    <option selected disabled value={""}>
                      Select Gender
                    </option>
                    <option value={genders.FEMALE}>Female</option>
                    <option value={genders.MALE}>Male</option>
                  </Form.Select>
                  {errors.gender && (
                    <Form.Text className="text-muted validationText hasError">
                      {errors.gender.message}
                    </Form.Text>
                  )}
                </Form.Group>
              </Col>
              <Col sm={6} className="pe-0">
                <Form.Group className="mb-3">
                  <Form.Label>Weight Unit*</Form.Label>
                  <Form.Select
                    id={"weight_unit"}
                    placeholder="Select Weight Unit"
                    {...register("weight_unit", {
                      required: {
                        value: "required",
                        message: " Weight unit is required",
                      },
                    })}
                  >
                    <option selected disabled value="">
                      Select Weight Unit
                    </option>
                    <option value={weight_unit.KG}>Kg</option>
                    <option value={weight_unit.LBS}>Lbs</option>
                  </Form.Select>
                  {errors.weight_unit && (
                    <Form.Text className="text-muted validationText hasError">
                      {errors.weight_unit.message}
                    </Form.Text>
                  )}
                </Form.Group>
              </Col>
              <Col sm={6}>
                <Form.Group className="mb-3" controlId="phone">
                  <Form.Label>Phone Number*</Form.Label>
                  <PhoneInput
                    name="phone"
                    limitMaxLength={20}
                    className="phone-input"
                    placeholder="Enter phone number"
                    international
                    value={value}
                    defaultCountry="US"
                    onChange={handlePhoneChange}
                  />
                  {errors.phone && (
                    <Form.Text className="text-muted validationText hasError">
                      {errors.phone.message}
                    </Form.Text>
                  )}
                </Form.Group>
                {/*<p>{value}</p>*/}
              </Col>
              {/*<Col xs={12} sm={12} md={8} lg={6} xl={6}>*/}
              {/*    <Form.Group className="mb-3" controlId="phone">*/}
              {/*        <Form.Label>Phone Number*</Form.Label>*/}
              {/*        <Form.Control*/}
              {/*            type="tel"*/}
              {/*            placeholder="Enter Phone Number"*/}
              {/*            maxLength={16}*/}
              {/*            inputMode={"tel"}*/}
              {/*            {...register("phone", {*/}
              {/*                    required: {*/}
              {/*                        value: "required",*/}
              {/*                        message: "Phone is required"*/}
              {/*                    },*/}
              {/*                    pattern: {*/}
              {/*                        value: /^\+[0-9]*$/,*/}
              {/*                        message: "incorrect pattern +xxxxxxxxxxxx"*/}
              {/*                    },*/}
              {/*                    maxLength: {*/}
              {/*                        value: 16,*/}
              {/*                        message: "max length is 16"*/}
              {/*                    }*/}
              {/*                }*/}
              {/*            )}*/}
              {/*        />*/}
              {/*        {errors.phone && <Form.Text*/}
              {/*            className="text-muted validationText hasError">{errors.phone.message}</Form.Text>}*/}
              {/*    </Form.Group>*/}
              {/*</Col>*/}

              <Col sm={6} className="pe-0">
                <div className={"pass-field mb-3"}>
                  <Form.Label>Password*</Form.Label>
                  <InputGroup controlId="passowrd">
                    <FormControl
                      placeholder="Enter Password"
                      autoComplete={"off"}
                      maxLength={255}
                      minLength={8}
                      {...register("password", {
                        required: {
                          value: "required",
                          message: "Password is required",
                        },
                        minLength: {
                          value: 8,
                          message: "Min length is 8",
                        },
                        maxLength: {
                          value: 255,
                          message: "Max length is 255",
                        },
                      })}
                      onClick={() => setConfirmPass(!confirmPass)}
                      type={pass ? "password" : "text"}
                    />
                    <Button
                      variant="outline-secondary"
                      id="button-addon2"
                      onClick={() => TogglePass(!pass)}
                    >
                      {pass ? <FaEyeSlash /> : <FaEye />}
                    </Button>
                  </InputGroup>
                  {errors.password && (
                    <Form.Text className="text-muted validationText hasError">
                      {errors.password.message}
                    </Form.Text>
                  )}
                </div>
              </Col>
              <Col sm={6}>
                <div className={"pass-field mb-3"}>
                  <Form.Label>Confirm Password*</Form.Label>
                  <InputGroup controlId="password_confirmation">
                    <FormControl
                      placeholder="Enter Password Again"
                      maxLength={25}
                      minLength={3}
                      {...register("password_confirmation", {
                        required: {
                          value: "required",
                          message: "Confirm Password is required",
                        },
                        minLength: {
                          value: 8,
                          message: "Min length is 8",
                        },
                        validate: password.current
                          ? (value) =>
                              value === password.current ||
                              "The passwords do not match"
                          : "",
                        maxLength: {
                          value: 255,
                          message: "Max length is 255",
                        },
                      })}
                      type={confPass ? "password" : "text"}
                    />
                    <Button
                      variant="outline-secondary"
                      id="button-addon2"
                      onClick={() => ToggleConfPass(!confPass)}
                    >
                      {confPass ? <FaEyeSlash /> : <FaEye />}
                    </Button>
                  </InputGroup>
                  {errors.password_confirmation && (
                    <Form.Text className="text-muted validationText hasError">
                      {errors.password_confirmation.message}
                    </Form.Text>
                  )}
                </div>
              </Col>
              <Col sm={6} className="pe-0">
                <Form.Group className="mb-3" controlId="country">
                  <Form.Label>Country</Form.Label>
                  <Form.Select
                    {...register("country", {
                      onChange: (e) => onCountryChange(e),
                    })}
                  >
                    <option value="" selected={true} disabled={true}>
                      Select Country
                    </option>
                    {countries &&
                      countries.map((country, index) => (
                        <option value={country.id}>{country.nicename}</option>
                      ))}
                  </Form.Select>

                  {errors.country && (
                    <Form.Text className="text-muted validationText hasError">
                      {errors.country.message}
                    </Form.Text>
                  )}
                </Form.Group>
              </Col>
              <Col sm={6}>
                <Form.Group className="mb-3">
                  <Form.Label>Occupation</Form.Label>
                  <Select
                    id={"occupations"}
                    // isMulti
                    options={
                      occupations &&
                      occupations.map((e) => ({ label: e.name, value: e.id }))
                    }
                    className="basic-multi-select"
                    onChange={optionsOccupations}
                    classNamePrefix="select"
                  />
                  {errors.occupations && (
                    <Form.Text className="text-muted validationText hasError">
                      {errors.occupations.message}
                    </Form.Text>
                  )}
                </Form.Group>
              </Col>
              <Col sm={6} className="pe-0">
                <Form.Group className="mb-3" controlId="sports">
                  <Form.Label>Sports</Form.Label>
                  <Select
                    isMulti
                    options={
                      sports &&
                      sports.map((e) => ({ label: e.name, value: e.id }))
                    }
                    className="basic-multi-select"
                    onChange={optionsSports}
                  />
                  {errors.sports && (
                    <Form.Text className="text-muted validationText hasError">
                      {errors.sports.message}
                    </Form.Text>
                  )}
                </Form.Group>
              </Col>
              <Col sm={6}>
                <Form.Group className="mb-3" controlId="programs">
                  <Form.Label>Programs</Form.Label>
                  <Select
                    isMulti
                    options={
                      programs &&
                      programs.map((e) => ({ label: e.name, value: e.id }))
                    }
                    className="basic-multi-select"
                    onChange={optionsPrograms}
                    classNamePrefix="select"
                  />
                  {errors.programs && (
                    <Form.Text className="text-muted validationText hasError">
                      {errors.programs.message}
                    </Form.Text>
                  )}
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <Form.Group className="mb-3">
                  <Form.Label>Injury Program</Form.Label>
                  <Select
                    isMulti
                    id={"injury_id"}
                    options={
                      injuryPrograms &&
                      injuryPrograms.map((e) => ({
                        label: e.name,
                        value: e.id,
                      }))
                    }
                    className="basic-multi-select"
                    classNamePrefix="select"
                    onChange={setInjuryProgramFunc}
                  />
                  {errors.injury_id && (
                    <Form.Text className="text-muted validationText hasError">
                      {errors.injury_id.message}
                    </Form.Text>
                  )}
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group className="mb-3">
                  <Form.Label>Body Type</Form.Label>
                  <Select
                    isMulti
                    id={"body_type_id"}
                    options={
                      bodyTypes &&
                      bodyTypes.map((e) => ({ label: e.name, value: e.id }))
                    }
                    className="basic-multi-select"
                    classNamePrefix="select"
                    onChange={setBodyTypeFunc}
                  />
                  {errors.body_type_id && (
                    <Form.Text className="text-muted validationText hasError">
                      {errors.body_type_id.message}
                    </Form.Text>
                  )}
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col sm={6}>
                <Form.Group className="mb-3">
                  <Form.Label>Subscription Type*</Form.Label>
                  <Form.Select
                    id={"subscription_type"}
                    placeholder="Select Subscription Type"
                    {...register("subscription_type", {
                      required: {
                        value: "required",
                        message: "Subscription Type is required",
                      },
                    })}
                  >
                    <option selected disabled value="">
                      Select Subscription Type
                    </option>
                    <option value={subscription_type.ADD_SUB}>
                      Subscription
                    </option>
                    <option value={subscription_type.ONE_TIME_SUB}>
                      One Time Program
                    </option>
                  </Form.Select>
                  {errors.subscription_type && (
                    <Form.Text className="text-muted validationText hasError">
                      {errors.subscription_type.message}
                    </Form.Text>
                  )}
                </Form.Group>
              </Col>
            </Row>
            {/* Social Links */}
            <Row>
              <Col sm={4}>
                <div className="mb-3">
                  <Form.Label htmlFor="basic-url">
                    Facebook URL(optional)
                  </Form.Label>
                  <InputGroup>
                    <InputGroup.Text id="basic-addon3">
                      https://facebook.com/
                    </InputGroup.Text>
                    <Form.Control
                      id="fb-basic-url"
                      aria-describedby="basic-addon3"
                      type={"text"}
                      maxLength={255}
                      {...register("facebook", {
                        required: false,
                      })}
                    />
                  </InputGroup>
                  {errors.fb && (
                    <Form.Text className="text-muted validationText hasError">
                      {errors.fb.message}
                    </Form.Text>
                  )}
                </div>
              </Col>
              <Col sm={4} className="px-0">
                <div className="mb-3">
                  <Form.Label htmlFor="basic-url">
                    Instagram URL(optional)
                  </Form.Label>
                  <InputGroup>
                    <InputGroup.Text id="basic-addon3">
                      https://instagram.com/
                    </InputGroup.Text>
                    <Form.Control
                      id="insta-basic-url"
                      aria-describedby="basic-addon3"
                      type={"text"}
                      maxLength={255}
                      {...register("instagram", {
                        required: false,
                      })}
                    />
                  </InputGroup>
                  {errors.insta && (
                    <Form.Text className="text-muted validationText hasError">
                      {errors.insta.message}
                    </Form.Text>
                  )}
                </div>
              </Col>
              <Col sm={4}>
                <div className="mb-3">
                  <Form.Label htmlFor="basic-url">
                    Linkedin URL(optional)
                  </Form.Label>
                  <InputGroup>
                    <InputGroup.Text id="basic-addon3">
                      https://linkedin.com/
                    </InputGroup.Text>
                    <Form.Control
                      id="linkedin-basic-url"
                      aria-describedby="basic-addon3"
                      type={"text"}
                      maxLength={255}
                      {...register("linkedin", {
                        required: false,
                      })}
                    />
                  </InputGroup>
                  {errors.linkedin && (
                    <Form.Text className="text-muted validationText hasError">
                      {errors.linkedin.message}
                    </Form.Text>
                  )}
                </div>
              </Col>
            </Row>

            <Row>
              <Col sm={2} className="ms-auto">
                {loader ? (
                  <FullPageLoader loading={loader} />
                ) : (
                  <Button
                    type={"submit"}
                    className={"btn common-btn w-100 mt-3"}
                    value={"Create User"}
                  >
                    Create User
                  </Button>
                )}
              </Col>
            </Row>
          </Form>
        </div>
      </div>
    </div>
  );
}

export default AddNewUser;
