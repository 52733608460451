import { React, useState } from "react";
import { NavLink, Nav } from "react-router-dom";
import { Navbar } from "react-bootstrap";
import {
  ManageUsersIcon,
  ManagePaymentsIcon,
  HomeIcon,
  Program,
  WeightBar,
  Review,
  Files,
  Faq,
  Video,
} from "../SvgIcons";
import {
  GiWeightLiftingUp,
  GiWeight,
  GiMuscularTorso,
  GiBodyHeight,
  GiMuscleUp,
  GiAnatomy,
} from "react-icons/gi";
import { GrYoga } from "react-icons/gr";
import { AiFillContainer } from "react-icons/ai";
import { IoMdHelpCircle, IoMdVideocam, IoMdSettings } from "react-icons/io";
import { FaSuitcase } from "react-icons/fa";
import { FaSlideshare } from "react-icons/fa";
import { GiInternalInjury } from "react-icons/gi";
import { BsPencilSquare } from "react-icons/bs";
import { BsQuestionSquareFill } from "react-icons/bs";
import { Submenu } from "./Submenu";
import Logo from "../../assets/images/body-sol.png";
// import Logo from "./../assets/images/body-sol.png";
import { BiRadioCircleMarked, BiCategory } from "react-icons/bi";
import { MdCategory } from "react-icons/md";

const Admin = () => {
  const BiSolidCategory = (
    <svg
      stroke="currentColor"
      fill="currentColor"
      stroke-width="0"
      viewBox="0 0 24 24"
      height="1em"
      width="1em"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M10 3H4a1 1 0 0 0-1 1v6a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4a1 1 0 0 0-1-1zm10 10h-6a1 1 0 0 0-1 1v6a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1v-6a1 1 0 0 0-1-1zM17 3c-2.206 0-4 1.794-4 4s1.794 4 4 4 4-1.794 4-4-1.794-4-4-4zM7 13c-2.206 0-4 1.794-4 4s1.794 4 4 4 4-1.794 4-4-1.794-4-4-4z"></path>
    </svg>
  );
  const userManagementItems = [
    {
      path: "/manage-users",
      label: "Manage Users",
      // icon: <ManageUsersIcon />,
      subMenuItems: [],
    },
    {
      path: "/sports",
      label: "Sports",
      // icon: <GiMuscleUp />,
      subMenuItems: [],
    },
    {
      path: "/occupation",
      label: "Occupation",
      // icon: <FaSuitcase />,
      subMenuItems: [],
    },
    // Add more user-related submenu items as needed
  ];

  const exerciseManagementItems = [
    {
      path: "/exercises",
      label: "Manage Exercises",
      // icon: <GiWeightLiftingUp />,
      subMenuItems: [
        { path: "/exercises", label: "Manage Exercises", icon: "" },
        { path: "/add-exercise", label: "Create Exercise", icon: "" },
        { path: "/add-compilation", label: "Exercise Compilation", icon: "" },
      ],
    },
    {
      path: "/feel-it-here",
      label: "Feel It Here",
      // icon: <GiMuscularTorso />,
      subMenuItems: [],
    },
    {
      path: "/exercise-types",
      label: "Exercise Type",
      // icon: <GrYoga />,
      subMenuItems: [],
    },
    {
      path: "/body-types",
      label: "Body Types",
      // icon: <GiBodyHeight />,
      subMenuItems: [],
    },
    {
      path: "/muscles",
      label: "Muscles",
      // icon: <GiMuscleUp />,
      subMenuItems: [],
    },
    {
      path: "/category",
      label: "Sub Category",
      // icon: BiSolidCategory,
      subMenuItems: [],
    },
    // Add more user-related submenu items as needed
  ];

  const equipmentManagementItems = [
    {
      path: "/equipments",
      label: "Manage Equipment",
      // icon: <GiWeight />,
      subMenuItems: [],
    },
    {
      path: "/equipment-categories",
      label: "Equipment Categories",
      // icon: BiSolidCategory,
      subMenuItems: [],
    },
    // Add more user-related submenu items as needed
  ];
  const triggerlibraryItems = [
    {
      path: "/pain-point-library",
      label: "Trigger Point",
      subMenuItems: [],
    },
    {
      path: "/pain-point-category",
      label: "Trigger Point Categories",
      subMenuItems: [],
    },
  ];

  const muscleslibraryItems = [
    {
      path: "/muscles-anatomy-library",
      label: "Muscles",
      // icon: <GiAnatomy />,
      subMenuItems: [],
    },
    {
      path: "/muscles-categories",
      label: "Muscles Categories",
      // icon: BiSolidCategory,
      subMenuItems: [],
    },
    // Add more user-related submenu items as needed
  ];

  const programManagementItems = [
    {
      path: "/program-library",
      label: "Manage Programs",
      // icon: <ManagePaymentsIcon />,
      subMenuItems: [],
    },
    {
      path: "/injury-program",
      label: " Injury Program",
      // icon: <GiInternalInjury />,
      subMenuItems: [],
    },
    {
      path: "/tests",
      label: "Tests",
      // icon: <BsPencilSquare />,
      subMenuItems: [],
    },
    {
      path: "/skip-question-reason",
      label: "Skip Question Reasons",
      // icon: <BsQuestionSquareFill />,
      subMenuItems: [],
    },
    // Add more user-related submenu items as needed
  ];
  return (
    <>
      <div className={`d-flex flex-column flex-shrink-0 h-100}`}>
        <ul className={`nav nav-pills sidebarNav flex-column mb-auto`}>
          {/* <ul className={`nav nav-pills sidebarNav flex-column mb-auto}`}> */}
          <Navbar.Brand href="/dashboard" className="py-4 text-center">
            <img
              src={Logo}
              alt="BodySolution"
              title={"BodySolution"}
              className={"img-fluid"}
            />
          </Navbar.Brand>
          <li className="nav-item">
            <NavLink
              to="/dashboard"
              className="nav-link"
              // activeClassName="active"
            >
              <HomeIcon />
              Dashboard
            </NavLink>
          </li>
          <Submenu
            items={userManagementItems}
            name={"User Management"}
            icon={<Program />}
          />
          <Submenu
            items={programManagementItems}
            name={"Program Management"}
            icon={<Program />}
          />
          <Submenu
            items={exerciseManagementItems}
            name={"Exercise Management"}
            icon={<WeightBar />}
          />
          <Submenu
            items={equipmentManagementItems}
            name={"Equipment Management"}
            icon={<Review />}
          />
          <li className="nav-item">
            <NavLink
              to="/page-list"
              className="nav-link"
              activeClassName="active"
            >
              <Files />
              Content Management
            </NavLink>
          </li>
          <li className="nav-item">
            <NavLink
              to="/testimonials"
              className="nav-link"
              activeClassName="active"
            >
              <FaSlideshare />
              Testimonials
            </NavLink>
          </li>
          <Submenu
            items={triggerlibraryItems}
            name={"Trigger Point Library"}
            icon={<BiRadioCircleMarked />}
          />
          <Submenu
            items={muscleslibraryItems}
            name={"Muscles Library"}
            icon={<GiAnatomy />}
          />
          <li className="nav-item">
            <NavLink
              to="/apt-videos"
              className="nav-link"
              activeClassName="active"
            >
              <IoMdVideocam />
              General ED Videos
            </NavLink>
          </li>
          <li className="nav-item">
            <NavLink to="/faqs" className="nav-link" activeClassName="active">
              <Faq />
              FAQs
            </NavLink>
          </li>
          <li className="nav-item">
            <NavLink
              to="/app-instructions"
              className="nav-link"
              activeClassName="active"
            >
              <Video />
              How To Use
            </NavLink>
          </li>
          {/* <Submenu
            items={equipmentManagementItems}
            name={"Content Management"}
            icon={<Review />}
          /> */}
          {/* <li className="nav-item">
                    <NavLink to="/equipments" className="nav-link" activeClassName="active">
                        <GiWeight />
                        Equipment Management
                    </NavLink>
                </li> */}
          {/* <li className="nav-item">
             <NavLink to="/sports" className="nav-link" activeClassName="active">
                 <GiMuscleUp />
                  Sports
              </NavLink>
          </li> */}
          {/* <li className="nav-item">
              <NavLink to="/occupation" className="nav-link" activeClassName="active">
                  <FaSuitcase />
                  Occupation
              </NavLink>
          </li> */}
          {/* <li className="nav-item">
                    <NavLink to="/payment" className="nav-link" activeClassName="active">
                        <BsCashCoin/>
                       Payment
                    </NavLink>
                </li> */}
          
         
          {/* <li className="nav-item">
                    <NavLink to="/home-settings" className="nav-link" activeClassName="active">
                        <IoMdSettings />
                        Homepage Settings
                    </NavLink>
                </li> */}
          {/* <li className="nav-item">
                <NavLink to="/contact-us" className="nav-link" activeClassName="active">
                    <AiFillMail />
                    Contact Us
                </NavLink>
            </li> */}
          {/* <li className="nav-item">
                    <NavLink to="/reviews-2" className="nav-link" activeClassName="active">
                        <ReportsIcon/>
                        Reviews
                    </NavLink>
                </li>
                <li className="nav-item">
                    <NavLink to={'/notification-2'} className="nav-link" activeClassName="active">
                        <SettingsIcon/>
                        Notifications
                    </NavLink>
                </li>
                <li className="nav-item">
                    <NavLink to={'/pages-2'} className="nav-link" activeClassName="active">
                        <SettingsIcon/>
                        Pages
                    </NavLink>
                </li> */}
        </ul>
      </div>
    </>
  );
};

export default Admin;
