import {
  Form,
  Row,
  Col,
  Button,
  InputGroup,
  FormControl,
  FormLabel,
} from "react-bootstrap";
import Select from "react-select";
import { useState, useRef, useEffect } from "react";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { useHistory, useLocation, Link } from "react-router-dom";
import { genders } from "../../utils/constants";
import { TbFilterOff } from "react-icons/tb";
import { EmptyLocalStorage } from "../../services/auth/auth.service";
import VideoThumbnail from "../program/VideoThumbnail";
import { SearchIcon } from "../../components/SvgIcons";
import { HashLoader } from "react-spinners";
import { GetBodyTypes } from "../../services/body_types.service";
import { GetExerciseTypes } from "../../services/exercise_types.service";
import { GetMuscles } from "../../services/muscles.service";
import { GetEquipments } from "../../services/equipments.service";
import { FadeLoader } from "react-spinners";
import {
  GetIProgramExercises,
  CreateExerciseCompilation,
} from "../../services/exercises.service";
import LevelWiseAddExercise from "../exercise/LevelWiseAddExercise";

function CreateCompilation(props) {
  let history = useHistory();
  let location = useLocation();
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    mode: "onBlur",
  });

  const [programPreData, setProgramPreData] = useState([
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
  ]);
  const [programNewData, setProgramNewData] = useState([
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
  ]);
  const [program, setProgram] = useState();
  const [exercise, setExercise] = useState();
  const [keyword, setKeyword] = useState("");
  const [gender, setGender] = useState("");
  const [bodyType, setBodyType] = useState([]);
  const [bodyTypePre, setBodyTypePre] = useState([]);
  const [loading, setLoading] = useState(false);
  const [equipment, setEquipment] = useState([]);
  const [exerciseType, setExerciseType] = useState([]);
  const [muscle, setMuscle] = useState([]);
  const [level, setLevel] = useState([]);
  const [buttonType, setButtonType] = useState("");
  const [loader, setLoader] = useState(false);
  const [disableButton, setDisableButton] = useState(false);
  const [bodyTypes, setBodyTypes] = useState([]);
  const [equipments, setEquipments] = useState([]);
  const [exerciseTypes, setExerciseTypes] = useState([]);
  const [muscles, setMuscles] = useState([]);
  const [exercises, setExercises] = useState([]);
  const [dragged, setDragged] = useState(null);
  const [swapDraggedArray, setSwapDraggedArray] = useState(null);
  const [selectRef, setSelectRef] = useState([]);
  const videoContainerRef = useRef();
  const videoComponentRef = useRef();
  const dayWiseContainerRef = useRef();
  const [removeExercises, setRemoveExercises] = useState([]);
  const [isRestComponent, setIsRestComponent] = useState(false);
  const [statusFilter, setStatusWiseFilter] = useState([]);
  const [exerciseFilter, setExcerciseFilter] = useState([]);
  const [dayId, setDayId] = useState("");
  const typingTimeoutRef = useRef(null);
  const [isComplete, setIsComplete] = useState(0);
  const [completeStatus, setCompleteStatus] = useState();
  const setExerciseFunc = (selectedValue) => {
    let data = [];
    selectedValue.map((item) => {
      data.push(item.value);
    });
    setExcerciseFilter(data);
  };
  const statusOptions = [
    { value: "", label: "Select Status", isDisabled: true },
    { value: 1, label: "Complete" },
    { value: 0, label: "Incomplete" },
  ];
  const handleKeyUp = (e) => {
    clearTimeout(typingTimeoutRef.current);
    const value = e.target.value;
    const sanitizedInput = value.replace(/[^A-Za-z\s]+/g, "");
    if (sanitizedInput.length > 2) {
      typingTimeoutRef.current = setTimeout(() => {
        setKeyword(sanitizedInput);
      }, 500); // Set keyword after 500 milliseconds of inactivity
    } else {
      setKeyword(null);
    }
  };

  // if (location.state !== undefined && program === undefined) {
  //   setProgram(location.state.exercise)
  //   if (location.state.redirectToSummary) {
  //     location.state.redirectToSummary = false;
  //     props.handleStep(12)
  //   }
  // }

  if (location.state !== undefined && exercise === undefined) {
    setExercise(location.state.exercise);
    if (location.state.redirectToSummary) {
      location.state.redirectToSummary = false;
      // props.handleStep(12)
    }
  }

  // if (location.state === undefined && program === undefined) {
  //   history.push('/add-new-program')
  // }

  const resetCurrentComponent = async () => {
    setIsRestComponent(true);
    setDayId("");
    setDragged(null);
    setExercises([]);
    //   await props.changed(false)
    //   await props.setNextStepFunc(null)
    setProgramPreData([
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
    ]);
    setProgramNewData([
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
      null,
    ]);

    document.getElementById("formFilterProgram")?.reset();
    document.getElementById("formProgramExercise")?.reset();
  };

  useEffect(() => {
    resetCurrentComponent();
  }, [program, props.activeStep, props.dayId, props.day, props.activeStep]);

  useEffect(() => {
    setProgramNewData(programPreData);
  }, [programPreData]);

  useEffect(() => {
    const getBodyTypes = async () => {
      await GetBodyTypes("", "", "", 0, "asc", "name").then((result) => {
        if (result.status && result.data) {
          setBodyTypes(result.data);
          if (bodyType !== "" && bodyType !== null) {
            let editBodyTypePre = result.data.filter(
              (item, index) => item.id == bodyType
            );
            setBodyTypePre({
              value: editBodyTypePre[0] ? editBodyTypePre[0].id : "",
              label: editBodyTypePre[0] ? editBodyTypePre[0].name : "",
            });
          } else {
            setBodyTypePre(null);
          }
        }
      });
    };

    const getExerciseTypes = async () => {
      await GetExerciseTypes("", "", "", 0, "asc", "name").then((result) => {
        if (result.status && result.data) {
          setExerciseTypes(result.data);
        }
      });
    };

    const getMuscles = async () => {
      await GetMuscles("", "", "", 0, "asc", "name").then((result) => {
        if (result.status && result.data) {
          setMuscles(result.data);
        }
      });
    };

    const getEquipmentList = async () => {
      await GetEquipments("", "", "", 0, "asc", "title").then((result) => {
        if (result.status && result.data) {
          setEquipments(result.data);
        }
      });
    };

    getBodyTypes();
    getExerciseTypes();
    getMuscles();
    getEquipmentList();
  }, []);

  const removeExerciseVideo = (targetId) => {
    const newPreData = [...programPreData];
    let removeExercisesArray = removeExercises;
    if (
      newPreData[targetId] !== undefined &&
      newPreData[targetId] !== null &&
      newPreData[targetId].id !== undefined &&
      newPreData[targetId].id !== null
    ) {
      removeExercisesArray.push(newPreData[targetId].id);
      setRemoveExercises(removeExercisesArray);
    }
    newPreData[targetId] = null;
    setProgramPreData(newPreData);

    const newNewData = [...programNewData];
    newNewData[targetId] = null;
    setProgramNewData(newNewData);
  };

  const handleSave = () => {
    setButtonType("save");
  };

  const handleSummary = () => {
    setButtonType("summary");
  };
  const setCompleteStatusTypeFunc = (selectedValue) => {
    setCompleteStatus(selectedValue);
    //setValue("is_draft", selectedValue);
    //setPageDirty(true);
  };
  const onSubmit = async (data) => {
    if (programNewData.length !== 0 && programNewData !== undefined) {
      setDisableButton(true);
      setLoader(true);
      await CreateExerciseCompilation({
        internal_notes: data.internal_notes,
        week: 1,
        circuit: 1,
        name: props.day,
        excerciseCompilation: programNewData,
        removeExercises: removeExercises,
        excercise_id: exercise.id,
        isComplete: isComplete,
      })
        .then(async (data) => {
          setIsRestComponent(true);
          await resetCurrentComponent();
          setDayId("");
          if (data.status) {
            setDisableButton(false);
            setLoader(false);
            toast.success(data.message);

            if (buttonType == "save") {
              history.push("/exercises");
            } else {
              history.push("/summary-excercise-compliation/" + exercise.id);
            }
          } else {
            toast.error(data.message.replace(/_/g, " "));
            setDisableButton(false);
            setLoader(false);
          }
        })
        .catch((error) => {
          setDisableButton(false);
          setLoader(false);
          toast.error("Something went wrong. Please try again.");
          if (error.response.status == 401) {
            EmptyLocalStorage();
            history.push("/");
          }
        });
    } else {
      toast.error("Please select exercise videos");
    }
  };

  const getExercises = async () => {
    setLoading(true);
    setExercises([]);
    await GetIProgramExercises(
      keyword,
      bodyType,
      exerciseType,
      equipment,
      muscle,
      gender,
      "",
      exerciseFilter,
      level,
      statusFilter,
      "",
      "asc",
      "name"
    )
      .then((result) => {
        if (result.status) {
          const exerciseData = result.data;
          console.log("exerciseData", exerciseData);
          setExercises(exerciseData);
          setLoading(false);
        }
      })
      .catch((error) => {
        console.log(error);
      });
    setLoading(false);
  };

  useEffect(() => {
    getExercises();
  }, [
    keyword,
    bodyType,
    exerciseType,
    equipment,
    muscle,
    gender,
    program,
    level,
    exerciseFilter,
    statusFilter,
    props.activeStep,
    props.dayId,
    props.day,
  ]);

  const clearFilter = () => {
    setKeyword("");
    setBodyType([]);
    setExerciseType([]);
    setEquipment([]);
    setMuscle([]);
    setGender("");
    setBodyTypePre([]);
    resetSelectInstances();
    setExcerciseFilter([]);
    setStatusWiseFilter("");
    setLevel([]);
    document.getElementById("formFilterProgram").reset();
  };

  const setDraggedFunc = (data) => {
    setDragged(data);
    setSwapDraggedArray(null);
  };

  const onDragOverFunc = (e) => {
    e.stopPropagation();
    e.preventDefault();
  };

  const onDragEnterFunc = (e) => {
    e.stopPropagation();
  };

  const onDropFunc = (e, equipment, levelNo) => {
    e.preventDefault();
    //   props.changed(true)
    const targetId = e.target.id.split("-")[1];
    if (targetId && swapDraggedArray && !dragged) {
      let oldIndex = parseInt(swapDraggedArray.exercise_no) - 1;
      let newIndex = parseInt(targetId) - 1;
      let tempData = swapDraggedArray;
      tempData.exercise_no = targetId;
      tempData.equipment = equipment;
      tempData.level = levelNo;

      const newPreData = [...programPreData];
      if (
        oldIndex != null &&
        oldIndex != -1 &&
        newIndex != null &&
        newIndex != -1
      ) {
        newPreData[oldIndex] = null;
        newPreData[newIndex] = tempData;
      }
      setProgramPreData(newPreData);

      const newNewData = [...programNewData];
      if (
        oldIndex != null &&
        oldIndex != -1 &&
        newIndex != null &&
        newIndex != -1
      ) {
        newNewData[oldIndex] = null;
        newNewData[newIndex] = tempData;
      }
      setProgramNewData(newNewData);
      setSwapDraggedArray(null);
      setDragged(null);
    } else if (e.target.className === "dropzone" && dragged) {
      const videoId = dragged.dataset.video_id;
      const levelId = dragged.dataset.level_id;
      setProgramDayNewDataFunc(videoId, targetId, levelId, equipment, levelNo);
      setDragged(null);
      setSwapDraggedArray(null);
    }
  };

  const setSwapDraggedFunc = (data) => {
    //   props.changed(true)
    setDragged(null);
    setSwapDraggedArray(data);
  };

  const setProgramDayNewDataFunc = (
    exerciseId,
    targetId,
    levelId,
    equipment,
    levelNo
  ) => {
    if (exerciseId !== null && targetId !== null) {
      const exerciseData = exercises.filter((elem) => elem.id == exerciseId);
      console.log("exerciseData", exerciseData);
      let preData = {
        // 'exercise_compilation_videos': 566,
        level: levelNo,
        equipment_no: equipment,
        exercise_id: exerciseId,
        exercise_no: targetId,
        position: 0,
        exercise_level_id: levelId,
        exerciseLevel: exerciseData[0]["exerciseLevels"][0]
          ? exerciseData[0]["exerciseLevels"][0]
          : null,
        feel_it_position: "TL",
        feel_it_show: true,
        text_position: "TR",
        text_show: true,
        exerciseLevels: exerciseData[0]["exerciseLevels"]
          ? exerciseData[0]["exerciseLevels"]
          : null,
      };

      let newData = {
        // 'exercise_compilation_videos': 566,
        level: levelNo,
        equipment_no: equipment,
        exercise_id: exerciseId,
        exercise_no: targetId,
        position: 0,
        exercise_level_id: levelId,
        exerciseLevel: exerciseData[0]["exerciseLevels"][0]
          ? exerciseData[0]["exerciseLevels"][0]
          : null,
        feel_it_position: "TL",
        feel_it_show: true,
        text_position: "TR",
        text_show: true,
        exerciseLevels: exerciseData[0]["exerciseLevels"]
          ? exerciseData[0]["exerciseLevels"]
          : null,
      };

      handleNewDataArray(preData, newData, targetId);
      return true;
    }
    return false;
  };

  const setWeightFunc = (e) => {
    //   props.changed(true)
    const targetId = e.target.id.split("-")[1];
    const position = e.target.value;
    if (position !== null && targetId !== null) {
      const newNewData = [...programNewData];
      if (targetId - 1 !== -1) {
        newNewData[targetId - 1].position = position;
      } else {
        newNewData[targetId].position = position;
      }
      setProgramNewData(newNewData);
    }
  };

  const setFeelItOverlayFunc = (data) => {
    //   props.changed(true)
    const targetId = data.index;
    const position = data.position;

    if (position !== null && targetId !== null) {
      let tempArray = programNewData;
      if (targetId - 1 !== -1) {
        tempArray[targetId - 1].feel_it_position = position;
        tempArray[targetId - 1].feel_it_show = data.show;
      } else {
        tempArray[targetId].feel_it_position = position;
        tempArray[targetId].feel_it_show = data.show;
      }
      setProgramNewData((programNewData) => tempArray);
    }
  };

  const setFeelImageShowFunc = (data) => {
    //   props.changed(true)
    const targetId = data.index;
    if (targetId !== null) {
      let tempArray = programNewData;
      if (targetId - 1 !== -1) {
        tempArray[targetId - 1].feel_it_show = data.show;
      } else {
        tempArray[targetId].feel_it_show = data.show;
      }
      setProgramNewData(tempArray);

      let tempPreArray = programPreData;
      if (targetId - 1 !== -1) {
        tempPreArray[targetId - 1].feel_it_show = data.show;
      } else {
        tempPreArray[targetId].feel_it_show = data.show;
      }
      setProgramPreData(tempPreArray);
    }
  };

  const setTextOverlayFunc = (data) => {
    //   props.changed(true)
    const targetId = data.index;
    const position = data.position;
    if (position !== null && targetId !== null) {
      let tempArray = programNewData;
      if (targetId - 1 !== -1) {
        tempArray[targetId - 1].text_position = position;
        tempArray[targetId - 1].text_show = data.show;
      } else {
        tempArray[targetId].text_position = position;
        tempArray[targetId].text_show = data.show;
      }
      setProgramNewData((programNewData) => tempArray);
    }
  };

  const setTextOverlayShowFunc = (data) => {
    //   props.changed(true)
    const targetId = data.index;
    if (targetId !== null) {
      let tempArray = programNewData;
      if (targetId - 1 !== -1) {
        tempArray[targetId - 1].text_show = data.show;
      } else {
        tempArray[targetId].text_show = data.show;
      }
      setProgramNewData(tempArray);

      let tempPreArray = programPreData;
      if (targetId - 1 !== -1) {
        tempPreArray[targetId - 1].text_show = data.show;
      } else {
        tempPreArray[targetId].text_show = data.show;
      }
      setProgramPreData(tempPreArray);
    }
  };

  const handlePreDataArray = (array) => {
    // const newPreData = [...programPreData];
    const newPreData = [];
    array.map((item) => {
      newPreData[item.exercise_no - 1] = item;
    });
    setProgramPreData(newPreData);
  };

  const handleNewDataArray = (preData, newData, targetId) => {
    const newPreData = [...programPreData];
    if (targetId - 1 !== -1) {
      newPreData[targetId - 1] = preData;
    } else {
      newPreData[targetId] = preData;
    }
    setProgramPreData(newPreData);

    const newNewData = [...programNewData];
    if (targetId - 1 !== -1) {
      newNewData[targetId - 1] = newData;
    } else {
      newNewData[targetId] = newData;
    }
    setProgramNewData(newNewData);
  };

  const swapArrayFunc = (data) => {
    const newPreData = [...programPreData];
    if (
      data.indexSource != null &&
      data.indexSource != -1 &&
      data.indexDestination != null &&
      data.indexDestination != -1
    ) {
      newPreData[data.indexDestination] = data.tempSource;
      newPreData[data.indexSource] = data.tempDestination;
    }
    setProgramPreData(newPreData);

    const newNewData = [...programNewData];
    if (
      data.indexSource != null &&
      data.indexSource != -1 &&
      data.indexDestination != null &&
      data.indexDestination != -1
    ) {
      newNewData[data.indexDestination] = data.tempSource;
      newNewData[data.indexSource] = data.tempDestination;
    }
    setProgramNewData(newNewData);
  };

  const setBodyTypeFunc = (selectedValue) => {
    let data = [];
    selectedValue.map((item) => {
      data.push(item.value);
    });

    setBodyType(data);
  };

  const setEquipmentFunc = (selectedValue) => {
    let data = [];
    selectedValue.map((item) => {
      data.push(item.value);
    });

    setEquipment(data);
  };

  const setExerciseTypeFunc = (selectedValue) => {
    let data = [];
    selectedValue.map((item) => {
      data.push(item.value);
    });
    setExerciseType(data);
  };

  const setMuscleFunc = (selectedValue) => {
    let data = [];
    selectedValue.map((item) => {
      data.push(item.value);
    });
    setMuscle(data);
  };

  const setLevelFilter = (selectedValue) => {
    let data = [];
    selectedValue.map((item) => {
      data.push(item.value);
    });
    setLevel(data);
  };

  const resetSelectInstances = () => {
    selectRef.map((item) => {
      if (item !== null) {
        item.clearValue();
      }
    });
    setSelectRef([null]);
  };

  const setIsRestComponentFunc = (data) => {
    setIsRestComponent(false);
  };

  const setStatusFilter = (selectedValue) => {
    setStatusWiseFilter(selectedValue ? selectedValue : "");
  };

  return (
    <>
      <section className="add-exercise">
        <div className="page-title">
          <h1>Create Exercise Compilation</h1>
          <Button href="/add-compilation" className="common-btn">
            Back
          </Button>
        </div>
        <Form id="formFilterProgram" autoComplete="off">
          <div className="exercise-detail">
            <div className="exercise-select">
              <Select
                placeholder="Select Exercise"
                id={"exercise"}
                className="basic-multi-select"
                classNamePrefix="select"
                aria-label="exercise"
                onChange={(selectedOptions) => setExerciseFunc(selectedOptions)}
                ref={(ref) => {
                  let selectRefs = selectRef;
                  selectRefs.push(ref);
                  setSelectRef(selectRefs);
                }}
                isClearable={true}
                isMulti={true}
                options={[
                  { value: "0", label: "Individual Exercise" },
                  { value: "1", label: "Linked/Complied Exercise" },
                ]}
              />
            </div>
            <div className="exercise-select">
              <Form.Select
                placeholder="Select Gender"
                id={"gender"}
                className="formselect program-dropdown"
                aria-label="gender"
                onChange={(e) => setGender(e.target.value)}
              >
                <option value={""} selected={true}>
                  Select Gender
                </option>
                <option value={genders.FEMALE}>Female</option>
                <option value={genders.MALE}>Male</option>
              </Form.Select>
            </div>
            <div className="exercise-select">
              <Select
                id={"body_type_id"}
                placeholder="Select Body Type"
                options={
                  bodyTypes &&
                  bodyTypes.map((e) => ({ label: e.name, value: e.id }))
                }
                className="basic-multi-select"
                classNamePrefix="select"
                onChange={(selectedOptions) => setBodyTypeFunc(selectedOptions)}
                isClearable={true}
                isMulti={true}
                ref={(ref) => {
                  let selectRefs = selectRef;
                  selectRefs.push(ref);
                  setSelectRef(selectRefs);
                }}
              />
            </div>
            <div className="exercise-select">
              <Select
                id={"exercise_type"}
                placeholder="Select Exercise Type"
                options={
                  exerciseTypes &&
                  exerciseTypes.map((e) => ({ label: e.name, value: e.id }))
                }
                isClearable={true}
                isMulti={true}
                className="basic-multi-select"
                classNamePrefix="select"
                onChange={(selectedOptions) =>
                  setExerciseTypeFunc(selectedOptions)
                }
                ref={(ref) => {
                  let selectRefs = selectRef;
                  selectRefs.push(ref);
                  setSelectRef(selectRefs);
                }}
              />
            </div>
            <div className="exercise-select">
              <Select
                id={"muscle"}
                options={
                  muscles &&
                  muscles.map((e) => ({ label: e.name, value: e.id }))
                }
                placeholder="Select Mucles"
                className="basic-multi-select"
                classNamePrefix="select"
                onChange={(selectedOptions) => setMuscleFunc(selectedOptions)}
                isClearable={true}
                isMulti={true}
                ref={(ref) => {
                  let selectRefs = selectRef;
                  selectRefs.push(ref);
                  setSelectRef(selectRefs);
                }}
              />
            </div>
            <div className="exercise-select">
              <Select
                id="equipment"
                options={
                  equipments &&
                  equipments.map((e) => ({ label: e.title, value: e.id }))
                }
                placeholder="Select Equipment"
                className="basic-multi-select"
                classNamePrefix="select"
                onChange={(selectedOptions) =>
                  setEquipmentFunc(selectedOptions)
                }
                isClearable={true}
                isMulti={true}
                ref={(ref) => {
                  let selectRefs = selectRef;
                  selectRefs.push(ref);
                  setSelectRef(selectRefs);
                }}
              />
            </div>
            <div className="search-bar">
              <div className="common-search-bar">
                <InputGroup className="search-group">
                  <InputGroup.Text id="basic-addon1">
                    <SearchIcon />
                  </InputGroup.Text>
                  <FormControl
                    placeholder="Search"
                    aria-label="Search"
                    aria-describedby="Search"
                    onChange={(e) => {
                      e.target.value.length > 1
                        ? setKeyword(e.target.value)
                        : setKeyword(null);
                    }}
                  />
                </InputGroup>
              </div>
            </div>
            <div className="exercise-select">
              <Button
                onClick={() => clearFilter()}
                className="common-btn"
                title={"Clear All Filters"}
              >
                <TbFilterOff /> Clear All Filters
              </Button>
              {/* <ul className="list-inline">
                <li className="list-inline-item" >
                  <span
                    style={{ cursor: "pointer", fontSize: "14px" }}
                    title={"Clear All Filters"}
                  >
                    <TbFilterOff /> Clear All Filters
                  </span>
                </li>
              </ul> */}
            </div>
            {/* <div className="exercise-select">
              <Select
                placeholder="Select Level"
                id={"exercise"}
                className="basic-multi-select"
                classNamePrefix="select"
                aria-label="exercise"
                onChange={(selectedOptions) => setLevelFilter(selectedOptions)}
                ref={(ref) => {
                  let selectRefs = selectRef;
                  selectRefs.push(ref);
                  setSelectRef(selectRefs);
                }}
                isClearable={true}
                isMulti={true}
                options={[
                  { value: "4", label: "Level 0" },
                  { value: "1", label: "Level 1" },
                  { value: "2", label: "Level 2" },
                  { value: "3", label: "Level 3" },
                ]}
              />
            </div> */}
            {/* <div className="exercise-select">
              <Select
                placeholder="Select Status"
                id={"excercise"}
                className="basic-multi-select"
                classNamePrefix="select"
                aria-label="excercise"
                onChange={(e) => setStatusFilter(e.target.value)}
                options={[
                  { value: "0", label: "In Complete" },
                  { value: "1", label: "Complete" },
                ]}
              />
            </div> */}
          </div>
        </Form>
        <Row>
          <Col md={6}>
            <div className="common-video">
              <div className="common-title">
                <h6>All Exercises</h6>
              </div>
              {loading ? (
                <div className="spin-loader mt-auto mb-auto">
                  <HashLoader color={"#EC1246"} height={10} />
                </div>
              ) : (
                <div
                  ref={videoContainerRef}
                  className="thumbnail-gallery exercise-video exercise-video-flex-custom 11"
                  id={"thumbnail-gallery-row"}
                >
                  {exercises.length > 0 &&
                    exercises?.map((item, index) => {
                      return (
                        <VideoThumbnail
                          exercise={item}
                          ref={videoComponentRef}
                          index={index}
                          videoUrl={
                            item && item.exerciseLevels[0]
                              ? item.exerciseLevels[0].video
                                ? item.exerciseLevels[0].video
                                : ""
                              : ""
                          }
                          videoPoster={
                            item && item.exerciseLevels[0]
                              ? item.exerciseLevels[0].video_cover
                                ? item.exerciseLevels[0].video_cover
                                : ""
                              : ""
                          }
                          videoTitle={item && item.name ? item.name : ""}
                          addedBy={""}
                          link={
                            item && item.exerciseLevels[0]
                              ? item.exerciseLevels[0].video
                                ? item.exerciseLevels[0].video
                                : ""
                              : ""
                          }
                          key={item.id}
                          id={item.id}
                          level={
                            item && item.exerciseLevels[0]
                              ? item.exerciseLevels[0].id
                                ? item.exerciseLevels[0].id
                                : ""
                              : ""
                          }
                          dragged={setDraggedFunc}
                          flipVideo={
                            item && item.exerciseLevels[0]
                              ? item.exerciseLevels[0].video_flip
                                ? item.exerciseLevels[0].video_flip
                                : null
                              : null
                          }
                        />
                      );
                    })}
                </div>
              )}
            </div>
          </Col>
          <Col md={6}>
            <div className="common-video right-video">
              <div className="common-title">
                <h6>No. of Exercises</h6>
              </div>
              <div className="thumbnail-gallery exercise-video common-thumbnail-gallery">
                <LevelWiseAddExercise
                  dragged={setDraggedFunc}
                  onDragEnterFunc={onDragEnterFunc}
                  onDragOverFunc={onDragOverFunc}
                  onDropFunc={onDropFunc}
                  setWeightFunc={setWeightFunc}
                  removeExerciseVideo={removeExerciseVideo}
                  dayExerciseData={programPreData}
                  setFeelItOverlayFunc={setFeelItOverlayFunc}
                  setFeelImageShowFunc={setFeelImageShowFunc}
                  setTextOverlayPosFunc={setTextOverlayFunc}
                  setTextOverlayShowFunc={setTextOverlayShowFunc}
                  swapArray={swapArrayFunc}
                  swapDragged={setSwapDraggedFunc}
                  isRestComponent={isRestComponent}
                  setIsRestComponent={setIsRestComponentFunc}
                />
              </div>
            </div>
          </Col>
        </Row>
        <div className="note">
          <Form
            id="formProgramExercise"
            className="formProgramExercise mt-1"
            autoComplete="off"
            role="presentation"
            onSubmit={handleSubmit(onSubmit)}
          >
            {loader ? (
              <Row className="mt-4">
                <Col md={{ span: 2, offset: 5 }} className={"text-start"}>
                  <div className="spin-loader-screen">
                    <FadeLoader color={"#EC1246"} height={10} />
                  </div>
                </Col>
              </Row>
            ) : (
              <></>
            )}
            <Row className="align-items-center pb-3">
              <Col xl={3} xxl={2}>
                {/* <Form.Group>
                  <Form.Label>Status</Form.Label>
                  <Form.Control type="email" placeholder="Incomplete" />
                  <Form.Select
                    id={"isComplete"}
                    className="form-select exercise-dropdown"
                    aria-label="Timer"
                    {...register("isComplete", {
                      onChange: (e) => {
                        setIsComplete(e.target.value);
                      },
                    })}
                  >
                    <option value="" selected={true} disabled={true}>
                      Select Status
                    </option>
                    <option value="0">Incomplete</option>
                    <option value="1">Complete</option>
                  </Form.Select>
                </Form.Group> */}
                <Form.Group>
                  <Form.Label>Status</Form.Label>
                  {/* <Form.Control type="email" placeholder="Incomplete" /> */}
                  <Select
                    isClearable
                    id="is_draft"
                    className="basic-multi-select"
                    classNamePrefix="select"
                    placeholder="Select Status"
                    {...register("isComplete", {
                      onChange: (e) => {
                        setIsComplete(e.target.value);
                      },
                    })}
                    value={statusOptions.find(
                      (option) => option.value === completeStatus?.value
                    )}
                    options={statusOptions}
                    onChange={(selectedOption) =>
                      setCompleteStatusTypeFunc(selectedOption)
                    }
                  />
                </Form.Group>
              </Col>
              <Col xl={7} xxl={8}>
                <Form.Group>
                  <Form.Label>Internal Notes</Form.Label>
                  <Form.Control
                    className="exercise-input"
                    placeholder="Internal Notes"
                    as="textarea"
                    id={"internal_notes"}
                    {...register("internal_notes", {
                      minLength: {
                        value: 3,
                        message: "Min length is 3",
                      },
                      maxLength: {
                        value: 500,
                        message: "Max length is 500",
                      },
                      //   onChange: (e) => props.changed(true)
                    })}
                    type="text"
                  />
                  {errors.internal_notes && (
                    <Form.Text className="text-muted validationText hasError">
                      {errors.internal_notes.message}
                    </Form.Text>
                  )}
                </Form.Group>
              </Col>
              <Col md={1}>
                <Button
                  className="common-btn"
                  type={"button"}
                  onClick={() => props.handleBack()}
                >
                  save
                </Button>
              </Col>
              <Col xl={1}>
                {/* <Button
                  id={"formProgramExerciseSubmitBtn"}
                  className="common-btn"
                  type={"submit"}
                  disabled={disableButton}
                  name={"save"}
                  onClick={handleSave}
                >
                  Save
                </Button> */}
                {/* <Button
                  id={"formProgramExerciseSubmitBtn"}
                  className="common-btn"
                  type={"submit"}
                  disabled={disableButton}
                  name={"summary"}
                  onClick={handleSummary}
                >
                  Summary
                </Button> */}
              </Col>
            </Row>
          </Form>
        </div>
      </section>

      {/* form starts */}
      {/* <Form.Select
                  placeholder="Select Excercise"
                  id={"excercise"}
                  className="formselect program-dropdown"
                  aria-label="excercise"
                  onChange={(e) => setLevelFilter(e.target.value)}
                >
                  <option value={""} selected={true}>
                    Select Level
                  </option>
                  <option value="4">Level 0</option>
                  <option value="1">Level 1</option>
                  <option value="2">Level 2</option>
                  <option value="3">Level 3</option>
                </Form.Select> */}
      {/* <div className="text-end ">
                  <ul className="list-inline">
                    <li
                      className="list-inline-item"
                      onClick={() => clearFilter()}
                    >
                      <span
                        style={{ cursor: "pointer", fontSize: "14px" }}
                        title={"Clear All Filters"}
                      >
                        <TbFilterOff /> Clear All Filters
                      </span>
                    </li>
                  </ul>
                </div> */}
      {/* form end */}
    </>
  );
}

export default CreateCompilation;
