import React, { useState, useEffect } from "react";
import { useHistory, Prompt } from "react-router-dom";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { EmptyLocalStorage } from "../../services/auth/auth.service";
import { upload } from "../../utils/helpers";
import { Col, Form, Row, Button } from "react-bootstrap";
import { CreateMusclesAnatomy } from "../../services/muscles_anatomy_library.service";
import { cross_origin, constants } from "../../utils/constants";
import ThemeModal from "../../components/ThemeModal";
import S3Browser from "../../components/S3Browser";
import { TrashIcon } from "../../components/SvgIcons";
import { GetMusclesCategories } from "../../services/muscles-categories.service";
import MyEditor from "../pages/MyEditor";
import { confirmMessage } from "../../utils/constants";
import Select from "react-select";
import FullPageLoader from "../../components/FullPageLoader";

function AddNewMusclesAnatomy(props) {
  let history = useHistory();
  const [formData, setFormData] = useState({});
  const [loader, setLoader] = useState(false);
  const [categories, setCategories] = useState([]);
  const [selectedCategories, setSelectedCategories] = useState();
  const [defaultImage, setDefaultImage] = useState(false);
  const [fileName, setFileName] = useState(null);
  const [modalShow, setModalShow] = useState(false);
  const [disableButton, setDisableButton] = useState(false);
  const [description, setDescription] = useState(null);
  const [title, setTitle] = useState("");
  const [title2, setTitle2] = useState("");
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(constants.PERPAGE);
  const [keyword, setKeyword] = useState("");
  const [isPageDirty, setPageDirty] = useState(false);
  // image  State
  const [img, setImg] = useState("");
  const [file, setFileData] = useState(null);
  const [secondImg, setSecondImg] = useState("");
  const [secondFile, setSecondFileData] = useState(null);
  const [thirdImg, setThirdImg] = useState("");
  const [thirdFile, setThirdFileData] = useState(null);
  const [isComplete, setIsComplete] = useState(0);
  const [completeStatus, setCompleteStatus] = useState();

  //video State
  const [video, setVideo] = useState("");
  const [video2, setVideo2] = useState("");
  const [video3, setVideo3] = useState("");
  const [video4, setVideo4] = useState("");
  const [fileVideo, setFileVideo] = useState(null);
  const [fileVideo2, setFileVideo2] = useState(null);
  const [fileVideo3, setFileVideo3] = useState(null);
  const [fileVideo4, setFileVideo4] = useState(null);

  // Video Titles
  const [videoTitle1, setVideoTitle1] = useState("");
  const [videoTitle2, setVideoTitle2] = useState("");
  const [videoTitle3, setVideoTitle3] = useState("");
  const [videoTitle4, setVideoTitle4] = useState("");

  useEffect(() => {
    getCategories();
  }, []);

  const statusOptions = [
    { value: "", label: "Select Status", isDisabled: true },
    { value: 1, label: "Complete" },
    { value: 0, label: "Incomplete" },
  ];

  const setCompleteStatusTypeFunc = (selectedValue) => {
    setCompleteStatus(selectedValue);
  };

  const {
    register,
    handleSubmit,
    watch,
    setError,
    clearErrors,
    formState: { errors },
    trigger,
  } = useForm({ mode: "onBlur" });

  const selectedMedia = (fileName, file) => {
    clearErrors(fileName);
    setFormData({ ...formData, ...{ [fileName]: file } });
    setModalShow(false);
  };

  const deleteVideo = (videoType) => {
    if (videoType === "video1") {
      setVideo(null);
      setFileVideo(null);
    } else if (videoType === "video2") {
      setVideo2(null);
      setFileVideo2(null);
    } else if (videoType === "video3") {
      setVideo3(null);
      setFileVideo3(null);
    } else if (videoType === "video4") {
      setVideo4(null);
      setFileVideo4(null);
    }
  };

  const resetAllImage = (imageType) => {
    if (imageType === "image1") {
      setFileData(null);
      setImg("");
    } else if (imageType === "image2") {
      setSecondFileData(null);
      setSecondImg("");
    } else if (imageType === "image3") {
      setThirdFileData(null);
      setThirdImg("");
    }
  };

  const getCategories = async () => {
    await GetMusclesCategories(keyword, page, perPage, 0, "", "", "")
      .then((result) => {
        if (result.status) {
          if (result.data) {
            setCategories(result.data);
          }
        } else {
          toast.error(result.message);
        }
      })
      .catch((error) => {
        toast.error(error.response.data.message.replace(/_/g, " "));
      });
  };

  const selectedCategoryOpt = (selectedValue) => {
    setSelectedCategories(selectedValue?.value);
  };

  // Image Upload Function
  const handleChange = async (event) => {
    const file = event.target.files[0];
    if (file) {
      setFileData(URL.createObjectURL(file));
      setImg(file);
      setPageDirty(true);
    }
  };

  const handleSecondChange = async (event) => {
    const file = event.target.files[0];
    if (file) {
      setSecondFileData(URL.createObjectURL(file));
      setSecondImg(file);
      setPageDirty(true);
    }
  };

  const handleThirdChange = async (event) => {
    const file = event.target.files[0];
    if (file) {
      setThirdFileData(URL.createObjectURL(file));
      setThirdImg(file);
      setPageDirty(true);
    }
  };

  //Video Upload Functions

  const handleChangeVideo = (event) => {
    setFileVideo(URL.createObjectURL(event.target.files[0]));
    setVideo(event.target.files[0]);
    setPageDirty(true);
  };
  const handleChangeVideo2 = (event) => {
    setFileVideo2(URL.createObjectURL(event.target.files[0]));
    setVideo2(event.target.files[0]);
    setPageDirty(true);
  };
  const handleChangeVideo3 = (event) => {
    setFileVideo3(URL.createObjectURL(event.target.files[0]));
    setVideo3(event.target.files[0]);
    setPageDirty(true);
  };

  const handleChangeVideo4 = (event) => {
    setFileVideo4(URL.createObjectURL(event.target.files[0]));
    setVideo4(event.target.files[0]);
    setPageDirty(true);
  };

  const handleTitle2Change = (event) => {
    setTitle2(event.target.value);
    setPageDirty(true);
  };

  const navigationPrompt = async (location) => {
    if (isPageDirty) {
      const userConfirmed = window.confirm(confirmMessage.message);
      if (userConfirmed) {
        let imageUrl = null;
        if (img) {
          let s3Data = await upload(
            img,
            "muscles-library/images",
            img.extension || "",
            img.type || ""
          );

          imageUrl = s3Data.Location;
        }
        setDisableButton(true);
        setLoader(true);
        await CreateMusclesAnatomy({
          title: title,
          image: imageUrl,
          video: video,
        })
          .then(async (data) => {
            setLoader(false);
            if (data.status) {
              toast.success(data.message);
            } else {
              setLoader(false);
              toast.error(data.message.replace(/_/g, " "));
            }
          })
          .catch((error) => {
            setLoader(false);
            if (error.response.status == 401) {
              EmptyLocalStorage();
              history.push("/");
            } else {
              return toast.error(
                error.response.data.message.replace(/_/g, " ")
              );
            }
          });
      }
      return userConfirmed;
    }
    return true;
  };

  // Video Title Function

  const handleVideoTitle1 = (event) => {
    setVideoTitle1(event.target.value);
  };

  const handleVideoTitle2 = (event) => {
    setVideoTitle2(event.target.value);
  };
  const handleVideoTitle3 = (event) => {
    setVideoTitle3(event.target.value);
  };
  const handleVideoTitle4 = (event) => {
    setVideoTitle4(event.target.value);
  };

  const onSubmit = async (data) => {
    setPageDirty(false);
    setLoader(true);

    if (!selectedCategories) {
      toast.error("Muscles Categories is required");
      setLoader(false);
      return false;
    }

    if (!data.title) {
      toast.error("Title is required");
      setLoader(false);
      return false;
    }

    if (!img) {
      toast.error("Image 1 is required");
      setLoader(false);
      return false;
    }

    let imageUrl = null;
    let videoUrl = null;

    if (img) {
      let s3Data = await upload(
        img,
        "muscles-library/images",
        img.extension || "",
        img.type || ""
      );

      data.image = s3Data.Location;
    } else {
      data.image = imageUrl;
    }

    let imageSecondUrl = null;

    if (secondImg) {
      let s3Data = await upload(
        secondImg,
        "muscles-library/images",
        secondImg.extension || "",
        secondImg.type || ""
      );

      data.image2 = s3Data.Location;
    } else {
      data.image2 = imageSecondUrl;
    }

    let imageThirdUrl = null;

    if (thirdImg) {
      let s3Data = await upload(
        thirdImg,
        "muscles-library/images",
        thirdImg.extension || "",
        thirdImg.type || ""
      );

      data.image3 = s3Data.Location;
    } else {
      data.image3 = imageThirdUrl;
    }

    if (video) {
      let s3DataVideo = await upload(
        video,
        "muscles-library/videos",
        video.extension || "",
        img.type || ""
      );
      data.video = s3DataVideo.Location;
    } else {
      data.video = videoUrl;
    }

    if (video2) {
      let s3DataVideo = await upload(
        video2,
        "muscles-library/videos",
        video2.extension || "",
        img.type || ""
      );
      data.video2 = s3DataVideo.Location;
    } else {
      data.video2 = videoUrl;
    }

    if (video3) {
      let s3DataVideo = await upload(
        video3,
        "muscles-library/videos",
        video3.extension || "",
        img.type || ""
      );
      data.video3 = s3DataVideo.Location;
    } else {
      data.video3 = videoUrl;
    }

    if (video4) {
      let s3DataVideo = await upload(
        video4,
        "muscles-library/videos",
        video4.extension || "",
        img.type || ""
      );
      data.video4 = s3DataVideo.Location;
    } else {
      data.video4 = videoUrl;
    }

    data.description = description;
    data.muscles_category_id = selectedCategories;
    data.title = data.title;
    data.title2 = title2;
    data.internal_notes = data.internal_notes;
    data.is_complete = isComplete;

    //Video Title
    data.video_title = videoTitle1;
    data.video_title2 = videoTitle2;
    data.video_title3 = videoTitle3;
    data.video_title4 = videoTitle4;

    await CreateMusclesAnatomy(data)
      .then(async (data) => {
        setLoader(false);
        if (data.status) {
          toast.success(data.message);
          history.push("/muscles-anatomy-library");
        } else {
          setLoader(false);
          toast.error(data.message.replace(/_/g, " "));
        }
      })
      .catch((error) => {
        setLoader(false);
        if (error.response.status == 401) {
          EmptyLocalStorage();
        } else {
          return toast.error(error.response.data.message.replace(/_/g, " "));
        }
      });
  };

  const BackMusclesr = () => {
    history.push("/muscles-anatomy-library");
  };

  return (
    <div className="muscles-index">
      <Prompt when={isPageDirty} message={navigationPrompt} />
      <section className="add-muscles">
        <div className="page-title">
          <h1>Add New Muscles Anatomy</h1>
          <Button onClick={BackMusclesr} className={"btn common-btn"}>
            Back
          </Button>
        </div>
        <div className="common-form">
          <Form
            className="formAddUser"
            autoComplete="off"
            role="presentation"
            onSubmit={handleSubmit(onSubmit)}
          >
            <Row className="mb-3">
              <Col md={3}>
                <Form.Group className="common-input" controlId="categories">
                  <Form.Label>Muscles Categories</Form.Label>
                  <Select
                    options={
                      categories &&
                      categories.map((e) => ({ label: e.name, value: e.id }))
                    }
                    className="basic-multi-select"
                    classNamePrefix="select"
                    onChange={selectedCategoryOpt}
                  />
                </Form.Group>
              </Col>
              <Col md={3}>
                <Form.Group className="common-input" controlId="title">
                  <Form.Label>Title 1*</Form.Label>
                  <Form.Control
                    placeholder="Enter Title"
                    type="text"
                    {...register("title")}
                  />
                  {errors.title && (
                    <Form.Text className="text-muted validationText hasError">
                      {errors.title.message}
                    </Form.Text>
                  )}
                </Form.Group>
              </Col>
              <Col md={3}>
                <Form.Group className="common-input">
                  <Form.Label>Title 2</Form.Label>
                  <Form.Control
                    placeholder="Enter Title"
                    onChange={handleTitle2Change}
                    value={title2}
                    type="text"
                  />
                </Form.Group>
              </Col>
              <Col md={3}>
                <Form.Group className="common-input" controlId="title">
                  <Form.Label>Keyword </Form.Label>
                  <Form.Control
                    placeholder="Enter Keyword"
                    {...register("search_keyword", {})}
                    aria-label="With textarea"
                  />
                </Form.Group>
              </Col>
            </Row>

            <Row className="mb-3">
              <Col md={6}>
                <Row>
                  <Col md={4}>
                    <Form.Group
                      controlId="formFile"
                      className="custom-file-upload"
                    >
                      <Form.Label className="btn common-btn">
                        Upload Image 1
                      </Form.Label>
                      <Form.Control
                        type="file"
                        accept="image/png, image/jpeg, image/jpg, image/gif"
                        {...register("image")}
                        onChange={handleChange}
                      />
                      {errors.image && (
                        <Form.Text className="text-muted validationText hasError">
                          {errors.image.message}
                        </Form.Text>
                      )}
                    </Form.Group>
                    {file ? (
                      <>
                        <div className="image-item">
                          <img
                            crossOrigin={cross_origin ? "anonymous" : null}
                            className={"img-table img-thumbnail"}
                            onError={({ currentTarget }) => {
                              currentTarget.onerror = null;
                              currentTarget.src = constants.IMAGENOTFOUND;
                            }}
                            src={file ? file : defaultImage}
                            alt={"Image1"}
                          />
                          <button
                            type="button"
                            className="btn common-btn"
                            onClick={() => resetAllImage("image1")}
                          >
                            <TrashIcon />
                          </button>
                        </div>
                      </>
                    ) : (
                      <div className="third-img"></div>
                    )}
                  </Col>
                  <Col md={4}>
                    <Form.Group
                      controlId="secondFile"
                      onChange={(e) => handleSecondChange(e)}
                      className="custom-file-upload"
                    >
                      <Form.Label className={"btn common-btn"}>
                        Upload Image 2
                      </Form.Label>
                      <Form.Control
                        type="file"
                        accept="image/png, image/jpeg, image/jpg, image/gif"
                        {...register("image2")}
                      />
                    </Form.Group>
                    {secondFile ? (
                      <>
                        <div className="image-item">
                          <img
                            crossOrigin={cross_origin ? "anonymous" : null}
                            className={"img-table img-thumbnail"}
                            onError={({ currentTarget }) => {
                              currentTarget.onerror = null; // prevents looping
                              currentTarget.src = constants.IMAGENOTFOUND;
                            }}
                            src={secondFile ? secondFile : defaultImage}
                            alt={"Image2"}
                          />
                          <button
                            type="button"
                            className="btn common-btn"
                            onClick={() => resetAllImage("image2")}
                          >
                            <TrashIcon />
                          </button>
                        </div>
                      </>
                    ) : (
                      <div className="third-img"></div>
                    )}
                  </Col>
                  <Col md={4}>
                    <Form.Group
                      controlId="thirdFile"
                      onChange={(e) => handleThirdChange(e)}
                      className="custom-file-upload"
                    >
                      <Form.Label className={"btn common-btn"}>
                        Upload Image 3
                      </Form.Label>
                      <Form.Control
                        type="file"
                        accept="image/png, image/jpeg, image/jpg, image/gif"
                        {...register("image")}
                      />
                    </Form.Group>
                    {thirdFile ? (
                      <>
                        <div className="image-item">
                          <img
                            crossOrigin={cross_origin ? "anonymous" : null}
                            className={"img-table img-thumbnail"}
                            onError={({ currentTarget }) => {
                              currentTarget.onerror = null; // prevents looping
                              currentTarget.src = constants.IMAGENOTFOUND;
                            }}
                            src={thirdFile ? thirdFile : defaultImage}
                            alt={"Image3"}
                          />
                          <button
                            type="button"
                            className="btn common-btn"
                            onClick={() => resetAllImage("image3")}
                          >
                            <TrashIcon />
                          </button>
                        </div>
                      </>
                    ) : (
                      <div className="third-img"></div>
                    )}
                  </Col>
                </Row>
              </Col>
              <Col md={6}>
                <Form.Group className="common-input" controlId="description">
                  <Form.Label>Description</Form.Label>
                  <div className={"ckeditor-container"}>
                    <>
                      <MyEditor
                        handleChange={(data) => {
                          setDescription(data);
                        }}
                        data={description}
                        {...props}
                      />
                    </>
                  </div>
                  {errors.description && (
                    <Form.Text className="text-muted validationText hasError">
                      {errors.description.message}
                    </Form.Text>
                  )}
                </Form.Group>
              </Col>
            </Row>

            <Row className="mb-4">
              <h6>Example Videos(up of 4)</h6>
              <hr></hr>
              <Col md={3}>
                <Form.Group
                  controlId="videoFile"
                  onChange={(e) => handleChangeVideo(e)}
                  className="custom-file-upload"
                >
                  <Form.Label className={"btn common-btn"}>
                    Upload Video 1
                  </Form.Label>
                  <Form.Control
                    type="file"
                    accept="video/*"
                    {...register("video")}
                  />
                </Form.Group>
                {fileVideo ? (
                  <>
                    <div className="image-item">
                      <video
                        controls
                        className={"img-table img-thumbnail"}
                        src={fileVideo}
                        title="Video"
                      ></video>
                      <div className="image-item__btn-wrapper">
                        <button
                          type="button"
                          className="btn common-btn"
                          onClick={() => deleteVideo("video1")}
                        >
                          <TrashIcon />
                        </button>
                      </div>
                    </div>
                  </>
                ) : (
                  <div className="video-box"></div>
                )}
                <Form.Group
                  className="common-input video-input mt-3"
                  controlId="title1"
                >
                  <Form.Control
                    placeholder="Video Title"
                    type="text"
                    onChange={handleVideoTitle1}
                  />
                </Form.Group>
              </Col>
              <Col md={3}>
                <Form.Group
                  controlId="videoFile2"
                  onChange={(e) => handleChangeVideo2(e)}
                  className="custom-file-upload"
                >
                  <Form.Label className={"btn common-btn"}>
                    Upload Video 2
                  </Form.Label>
                  <Form.Control
                    type="file"
                    accept="video/*"
                    {...register("video2")}
                  />
                </Form.Group>
                {fileVideo2 ? (
                  <>
                    <div className="image-item">
                      <video
                        controls
                        className={"img-table img-thumbnail"}
                        src={fileVideo2}
                        title="Video"
                      ></video>
                      <button
                        type="button"
                        className="btn common-btn"
                        onClick={() => deleteVideo("video2")}
                      >
                        <TrashIcon />
                      </button>
                    </div>
                  </>
                ) : (
                  <div className="video-box"></div>
                )}
                <Form.Group
                  className="common-input video-input mt-3"
                  controlId="title2"
                >
                  <Form.Control
                    placeholder="Video Title"
                    type="text"
                    onChange={handleVideoTitle2}
                  />
                </Form.Group>
              </Col>
              <Col md={3}>
                <Form.Group
                  controlId="videoFile3"
                  onChange={(e) => handleChangeVideo3(e)}
                  className="custom-file-upload"
                >
                  <Form.Label className={"btn common-btn"}>
                    Upload Video 3
                  </Form.Label>
                  <Form.Control
                    type="file"
                    accept="video/*"
                    {...register("video3")}
                  />
                </Form.Group>
                {fileVideo3 ? (
                  <>
                    <div className="image-item">
                      <video
                        controls
                        className={"img-table img-thumbnail"}
                        src={fileVideo3}
                        title="Video"
                      ></video>
                      <button
                        type="button"
                        className="btn common-btn"
                        onClick={() => deleteVideo("video3")}
                      >
                        <TrashIcon />
                      </button>
                    </div>
                  </>
                ) : (
                  <div className="video-box"></div>
                )}
                <Form.Group
                  className="common-input video-input mt-3"
                  controlId="title3"
                >
                  <Form.Control
                    placeholder="Video Title"
                    type="text"
                    onChange={handleVideoTitle3}
                  />
                </Form.Group>
              </Col>
              <Col md={3}>
                <Form.Group
                  controlId="videoFile4"
                  onChange={(e) => handleChangeVideo4(e)}
                  className="custom-file-upload"
                >
                  <Form.Label className={"btn common-btn"}>
                    Upload Video 4
                  </Form.Label>
                  <Form.Control
                    type="file"
                    accept="video/*"
                    {...register("video3")}
                  />
                </Form.Group>
                {fileVideo4 ? (
                  <>
                    <div className="image-item">
                      <video
                        controls
                        className={"img-table img-thumbnail"}
                        src={fileVideo4}
                        title="Video"
                      ></video>
                      <button
                        type="button"
                        className="btn common-btn"
                        onClick={() => deleteVideo("video4")}
                      >
                        <TrashIcon />
                      </button>
                    </div>
                  </>
                ) : (
                  <div className="video-box"></div>
                )}
                <Form.Group
                  className="common-input mt-3 d-flex aling-items-center"
                  controlId="title4"
                >
                  <Form.Control
                    placeholder="Video Title"
                    type="text"
                    onChange={handleVideoTitle4}
                  />
                </Form.Group>
              </Col>
            </Row>

            <Row>
              {" "}
              <Col md={3}>
                <Form.Group>
                  <Form.Label>Status</Form.Label>
                  <Select
                    isClearable
                    id="is_draft"
                    className="basic-multi-select"
                    classNamePrefix="select"
                    placeholder="Select Status"
                    {...register("is_complete", {
                      onChange: (e) => {
                        setIsComplete(e.target.value);
                      },
                    })}
                    value={statusOptions.find(
                      (option) => option.value === completeStatus?.value
                    )}
                    options={statusOptions}
                    onChange={(selectedOption) =>
                      setCompleteStatusTypeFunc(selectedOption)
                    }
                  />
                </Form.Group>
              </Col>
              <Col md={7}>
                <Form.Group>
                  <Form.Label>Internal Notes</Form.Label>
                  <Form.Control
                    className="exercise-input"
                    placeholder="Internal Notes"
                    as="textarea"
                    id={"internal_notes"}
                    {...register("internal_notes", {
                      minLength: {
                        value: 3,
                        message: "Min length is 3",
                      },
                      maxLength: {
                        value: 500,
                        message: "Max length is 500",
                      },
                    })}
                    type="text"
                  />
                  {errors.internal_notes && (
                    <Form.Text className="text-muted validationText hasError">
                      {errors.internal_notes.message}
                    </Form.Text>
                  )}
                </Form.Group>
              </Col>
              <Col md={2} className="mt-2">
                {loader ? (
                  <FullPageLoader loading={loader} />
                ) : (
                  <Button
                    type={"submit"}
                    className={"btn common-btn mt-4 w-100"}
                    value={"Create Muscles Anatomy"}
                  >
                    Save
                  </Button>
                )}
              </Col>
            </Row>
          </Form>
        </div>
      </section>

      <ThemeModal
        title={"S3 Browser"}
        content={
          <S3Browser fileName={fileName} selectedMedia={selectedMedia} />
        }
        size={"xl"}
        show={modalShow}
        onHide={() => {
          setModalShow(false);
        }}
      />
    </div>
  );
}

export default AddNewMusclesAnatomy;
