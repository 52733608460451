import React, { useEffect, useState } from "react";
import { Col, Form, Row, Button } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { EmptyLocalStorage } from "../../services/auth/auth.service";
import { upload } from "../../utils/helpers";
import {
  GetMusclesAnatomyById,
  UpdateMusclesAnatomy,
} from "../../services/muscles_anatomy_library.service";
import { useHistory } from "react-router-dom";
import { cross_origin, constants } from "../../utils/constants";

import S3Browser from "../../components/S3Browser";
import ThemeModal from "../../components/ThemeModal";
import { TrashIcon } from "../../components/SvgIcons";

import Select from "react-select";
import { GetMusclesCategories } from "../../services/muscles-categories.service";
import MyEditor from "../pages/MyEditor";
import FullPageLoader from "../../components/FullPageLoader";

function EditMusclesAnatomy(props) {
  const id = props.match.params.id;
  let history = useHistory();
  const [formData, setFormData] = useState({});
  const [loader, setLoader] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [row, setRow] = useState(null);
  const [defaultImage, setDefaultImage] = useState(false);
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(constants.PERPAGE);
  const [keyword, setKeyword] = useState("");
  const [fileName, setFileName] = useState(null);
  const [modalShow, setModalShow] = useState(false);
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [description, setDescription] = useState(null);
  const [categories, setCategories] = useState([]);
  const [isComplete, setIsComplete] = useState(0);

  //Video State
  const [isUploadVideo, setIsUploadVideo] = useState(false);
  const [isUploadVideo2, setIsUploadVideo2] = useState(false);
  const [isUploadVideo3, setIsUploadVideo3] = useState(false);
  const [isUploadVideo4, setIsUploadVideo4] = useState(false);
  const [video, setVideo] = useState("");
  const [video2, setVideo2] = useState("");
  const [video3, setVideo3] = useState("");
  const [video4, setVideo4] = useState("");
  const [fileVideo, setFileVideo] = useState(null);
  const [fileVideo2, setFileVideo2] = useState(null);
  const [fileVideo3, setFileVideo3] = useState(null);
  const [fileVideo4, setFileVideo4] = useState(null);

  //Image State
  const [img, setImg] = useState("");
  const [file, setFileData] = useState(null);
  const [isUpload, setIsUpload] = useState(false);
  const [secondImg, setSecondImg] = useState("");
  const [secondFile, setSecondFileData] = useState(null);
  const [isUpload2, setIsUpload2] = useState(false);
  const [thirdImg, setThirdImg] = useState("");
  const [thirdFile, setThirdFileData] = useState(null);
  const [isUpload3, setIsUpload3] = useState(false);

  const {
    register,
    setValue,
    handleSubmit,
    setError,
    clearErrors,
    formState: { errors },
  } = useForm({
    mode: "onBlur",
  });

  const selectedMedia = (fileName, file) => {
    clearErrors(fileName);
    setFormData({ ...formData, ...{ [fileName]: file } });
    setModalShow(false);
  };

  const resetAllImage = (imageType) => {
    if (imageType === "image1") {
      setFileData(null);
      setImg("");
    } else if (imageType === "image2") {
      setSecondFileData(null);
      setSecondImg("");
    } else if (imageType === "image3") {
      setThirdFileData(null);
      setThirdImg("");
    }
  };

  const getCategories = async () => {
    await GetMusclesCategories(keyword, page, perPage, 0, "", "", "")
      .then((result) => {
        if (result.status) {
          if (result.data) {
            setCategories(result.data);
          }
        } else {
          toast.error(result.message);
        }
      })
      .catch((error) => {
        toast.error(error.response.data.message.replace(/_/g, " "));
      });
  };

  useEffect(() => {
    if (
      formData.muscles_category_id !== "" &&
      formData.muscles_category_id !== null
    ) {
      let editInjuryProgram = categories.filter(
        (item, index) => item.id == formData.muscles_category_id
      );
      setSelectedCategories({
        value: editInjuryProgram[0] ? editInjuryProgram[0].id : "",
        label: editInjuryProgram[0] ? editInjuryProgram[0].name : "",
      });
    }
  }, [formData]);

  const selectedCategoryOpt = (selectedValue) => {
    setValue("muscles_category_id", selectedValue ? selectedValue.value : "");
    setSelectedCategories(selectedValue ? selectedValue : "");
  };

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true); // Start loading
      try {
        await getCategories();
        const result = await GetMusclesAnatomyById(id);
        if (result?.status) {
          if (result?.data) {
            setRow(result.data);
            setFormData(result?.data);
            setValue("title", result?.data?.title);
            setValue("title2", result?.data?.title2);
            setValue("muscles_category_id", result.data?.muscles_category_id);
            setValue("internal_notes", result?.data?.internal_notes);
            setIsComplete(result?.data?.is_complete);
            setImg(result?.data?.image);
            setFileData(result?.data?.image);
            setSecondImg(result?.data?.image2);
            setSecondFileData(result?.data?.image2);
            setThirdImg(result?.data?.image3);
            setThirdFileData(result?.data?.image3);
            setValue("video_title", result?.data?.video_title);
            setValue("video_title2", result?.data?.video_title2);
            setValue("video_title3", result?.data?.video_title3);
            setValue("video_title4", result?.data?.video_title4);
            setValue("search_keyword", result?.data?.search_keyword);
            setDescription(result?.data?.description);
            setVideo(result?.data?.video);
            setVideo2(result?.data?.video2);
            setVideo3(result?.data?.video3);
            setVideo4(result?.data?.video4);
            setFileVideo(result?.data?.video);
            setFileVideo2(result?.data?.video2);
            setFileVideo3(result?.data?.video3);
            setFileVideo4(result?.data?.video4);
          }
        } else {
          toast.error(result.message);
        }
      } catch (error) {
        toast.error(error.response.data.message.replace(/_/g, " "));
      } finally {
        setIsLoading(false); // End loading
      }
    };

    fetchData();
  }, []);

  const onSubmit = async (data) => {
    setLoader(true);

    if (!data.title) {
      toast.error("Title is required");
      setLoader(false);
      return false;
    }

    if (!img) {
      toast.error("Image 1 is required");
      setLoader(false);
      return false;
    }

    if (isUpload) {
      let s3Data = await upload(
        img,
        "pain-point-library/images",
        img.extension || "",
        img.type || ""
      );

      if (!s3Data) {
        toast.error("Uploading failed");
        setLoader(false);
        return false;
      }

      data.image = s3Data?.Location;
    } else {
      data.image = img;
    }
    if (isUpload2) {
      let s3Data = await upload(
        secondImg,
        "pain-point-library/images",
        secondImg.extension || "",
        secondImg.type || ""
      );

      if (!s3Data) {
        toast.error("Uploading failed");
        setLoader(false);
        return false;
      }

      data.image2 = s3Data?.Location;
    } else {
      data.image2 = secondImg;
    }

    if (isUpload3) {
      let s3Data = await upload(
        thirdImg,
        "pain-point-library/images",
        thirdImg.extension || "",
        thirdImg.type || ""
      );

      if (!s3Data) {
        toast.error("Uploading failed");
        setLoader(false);
        return false;
      }

      data.image3 = s3Data?.Location;
    } else {
      data.image3 = thirdImg;
    }

    if (isUploadVideo) {
      let s3DataVideo = await upload(
        video,
        "pain-point-library/videos",
        video.extension || "",
        video.type || ""
      );
      if (!s3DataVideo) {
        toast.error("Uploading failed");
        setLoader(false);
        return false;
      }
      data.video = s3DataVideo?.Location;
    } else {
      data.video = video;
    }
    if (isUploadVideo2) {
      let s3DataVideo = await upload(
        video2,
        "pain-point-library/videos",
        video2.extension || "",
        video2.type || ""
      );
      if (!s3DataVideo) {
        toast.error("Uploading failed");
        setLoader(false);
        return false;
      }
      data.video2 = s3DataVideo?.Location;
    } else {
      data.video2 = video2;
    }
    if (isUploadVideo3) {
      let s3DataVideo = await upload(
        video3,
        "pain-point-library/videos",
        video3.extension || "",
        video3.type || ""
      );
      if (!s3DataVideo) {
        toast.error("Uploading failed");
        setLoader(false);
        return false;
      }
      data.video3 = s3DataVideo?.Location;
    } else {
      data.video3 = video3;
    }
    if (isUploadVideo4) {
      let s3DataVideo = await upload(
        video4,
        "pain-point-library/videos",
        video4.extension || "",
        video4.type || ""
      );
      if (!s3DataVideo) {
        toast.error("Uploading failed");
        setLoader(false);
        return false;
      }
      data.video4 = s3DataVideo?.Location;
    } else {
      data.video4 = video4;
    }

    data.description = description;
    data.muscles_category_id = selectedCategories?.value;
    data.internal_notes = data.internal_notes;
    data.is_complete = isComplete;

    await UpdateMusclesAnatomy(id, data)
      .then(async (data) => {
        setLoader(false);
        if (data.status) {
          toast.success(data.message);
          history.push("/muscles-anatomy-library");
        } else {
          toast.error(data.message.replace(/_/g, " "));
        }
      })
      .catch((error) => {
        setLoader(false);
        if (error.response.status == 401) {
          EmptyLocalStorage();
          history.push("/");
        } else {
          return toast.error(error.response.data.message.replace(/_/g, " "));
        }
      });
  };

  // Image handle change
  const handleChange = (event) => {
    setFileData(URL.createObjectURL(event.target.files[0]));
    setImg(event.target.files[0]);
    setIsUpload(true);
  };

  const handleSecondChange = async (event) => {
    setSecondFileData(URL.createObjectURL(event.target.files[0]));
    setSecondImg(event.target.files[0]);
    setIsUpload2(true);
  };

  const handleThirdChange = async (event) => {
    setThirdFileData(URL.createObjectURL(event.target.files[0]));
    setThirdImg(event.target.files[0]);
    setIsUpload3(true);
  };

  // Video handle change
  const handleChangeVideo = (event) => {
    setFileVideo(URL.createObjectURL(event.target.files[0]));
    setVideo(event.target.files[0]);
    setIsUploadVideo(true);
  };
  const handleChangeVideo2 = (event) => {
    setFileVideo2(URL.createObjectURL(event.target.files[0]));
    setVideo2(event.target.files[0]);
    setIsUploadVideo2(true);
  };
  const handleChangeVideo3 = (event) => {
    setFileVideo3(URL.createObjectURL(event.target.files[0]));
    setVideo3(event.target.files[0]);
    setIsUploadVideo3(true);
  };

  const handleChangeVideo4 = (event) => {
    setFileVideo4(URL.createObjectURL(event.target.files[0]));
    setVideo4(event.target.files[0]);
    setIsUploadVideo4(true);
  };

  // Delete Video Function

  const deleteVideo = (videoType) => {
    if (videoType === "video1") {
      setVideo(null);
      setFileVideo(null);
    } else if (videoType === "video2") {
      setVideo2(null);
      setFileVideo2(null);
    } else if (videoType === "video3") {
      setVideo3(null);
      setFileVideo3(null);
    } else if (videoType === "video4") {
      setVideo4(null);
      setFileVideo4(null);
    }
  };
  const BackMusclesr = () => {
    history.push("/muscles-anatomy-library");
  };

  return (
    <>
      <FullPageLoader loading={isLoading} />
      <div className="muscles-index">
        <section className="add-muscles edit-muscles">
          <div className="page-title">
            <h1>Edit Muscles Anatomy</h1>
            <Button onClick={BackMusclesr} className={"btn common-btn"}>
              Back
            </Button>
          </div>
          <div className="common-form">
            <Form
              className="formAddUser"
              autoComplete="off"
              role="presentation"
              onSubmit={handleSubmit(onSubmit)}
            >
              <Row className="mb-3">
                <Col md={3}>
                  <Form.Group className="common-input" controlId="categories">
                    <Form.Label>Muscles Categories</Form.Label>
                    <Select
                      id="muscles_category_id"
                      value={selectedCategories}
                      options={
                        categories &&
                        categories.map((category) => ({
                          label: category.name,
                          value: category.id,
                        }))
                      }
                      className="basic-multi-select"
                      classNamePrefix="select"
                      onChange={selectedCategoryOpt}
                    />
                  </Form.Group>
                </Col>
                <Col md={3}>
                  <Form.Group className="common-input" controlId="title">
                    <Form.Label>Title 1*</Form.Label>

                    <Form.Control
                      placeholder="Enter Title"
                      type="text"
                      {...register("title")}
                    />
                    {errors.title && (
                      <Form.Text className="text-muted validationText hasError">
                        {errors.title.message}
                      </Form.Text>
                    )}
                  </Form.Group>
                </Col>
                <Col md={3}>
                  <Form.Group className="common-input" controlId="title">
                    <Form.Label>Title 2</Form.Label>

                    <Form.Control
                      placeholder="Enter Title"
                      {...register("title2")}
                      type="text"
                    />
                  </Form.Group>
                </Col>
                <Col md={3}>
                  <Form.Group className="common-input" controlId="title">
                    <Form.Label>Keyword</Form.Label>
                    <Form.Control
                      placeholder="Enter Keyword"
                      {...register("search_keyword", {})}
                      aria-label="With textarea"
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col md={6}>
                  <Row>
                    <Col md={4}>
                      <Form.Group
                        controlId="formFile"
                        onChange={(e) => handleChange(e)}
                        className="custom-file-upload"
                      >
                        <Form.Label className={"btn common-btn"}>
                          Upload Image 1
                        </Form.Label>
                        <Form.Control
                          type="file"
                          accept="image/png, image/jpeg, image/jpg, image/gif"
                          {...register("image")}
                        />
                        {errors.image && (
                          <Form.Text className="text-muted validationText hasError">
                            {errors.image.message}
                          </Form.Text>
                        )}
                      </Form.Group>
                      {file && (
                        <>
                          <div className="image-item">
                            <img
                              crossOrigin={cross_origin ? "anonymous" : null}
                              className={"img-table img-thumbnail"}
                              onError={({ currentTarget }) => {
                                currentTarget.onerror = null;
                                currentTarget.src = constants.IMAGENOTFOUND;
                              }}
                              src={file ? file : defaultImage}
                              alt={"Image1"}
                            />
                            <button
                              type="button"
                              className="btn common-btn"
                              onClick={() => resetAllImage("image1")}
                            >
                              <TrashIcon />
                            </button>
                          </div>
                        </>
                      )}
                    </Col>
                    <Col md={4}>
                      <Form.Group
                        controlId="secondFile"
                        onChange={(e) => handleSecondChange(e)}
                        className="custom-file-upload"
                      >
                        <Form.Label className={"btn common-btn"}>
                          Upload Image 2
                        </Form.Label>
                        <Form.Control
                          type="file"
                          accept="image/png, image/jpeg, image/jpg, image/gif"
                          {...register("image2")}
                        />
                        {errors.image && (
                          <Form.Text className="text-muted validationText hasError">
                            {errors.image.message}
                          </Form.Text>
                        )}
                      </Form.Group>
                      {secondFile ? (
                        <>
                          <div className="image-item">
                            <img
                              crossOrigin={cross_origin ? "anonymous" : null}
                              className={"img-table img-thumbnail"}
                              onError={({ currentTarget }) => {
                                currentTarget.onerror = null; 
                                currentTarget.src = constants.IMAGENOTFOUND;
                              }}
                              src={secondFile ? secondFile : defaultImage}
                              alt={"Image2"}
                            />
                            <Button
                              type="button"
                              className="btn common-btn"
                              onClick={() => resetAllImage("image2")}
                            >
                              <TrashIcon />
                            </Button>
                          </div>
                        </>
                      ) : (
                        <div className="third-img"></div>
                      )}
                    </Col>
                    <Col md={4}>
                      <Form.Group
                        controlId="thirdFile"
                        onChange={(e) => handleThirdChange(e)}
                        className="custom-file-upload"
                      >
                        <Form.Label className={"btn common-btn"}>
                          Upload Image 3
                        </Form.Label>
                        <Form.Control
                          type="file"
                          accept="image/png, image/jpeg, image/jpg, image/gif"
                          {...register("image3")}
                        />
                        {errors.image && (
                          <Form.Text className="text-muted validationText hasError">
                            {errors.image.message}
                          </Form.Text>
                        )}
                      </Form.Group>
                      {thirdFile ? (
                        <div className="image-item">
                          <img
                            crossOrigin={cross_origin ? "anonymous" : null}
                            className={"img-table img-thumbnail"}
                            onError={({ currentTarget }) => {
                              currentTarget.onerror = null; 
                              currentTarget.src = constants.IMAGENOTFOUND;
                            }}
                            src={thirdFile ? thirdFile : defaultImage}
                            alt={"Image3"}
                          />
                          <Button
                            type="button"
                            className="btn common-btn"
                            onClick={() => resetAllImage("image3")}
                          >
                            <TrashIcon />
                          </Button>
                        </div>
                      ) : (
                        <div className="third-img"></div>
                      )}
                    </Col>
                  </Row>
                </Col>
                <Col md={6}>
                  <Form.Group className="common-input" controlId="description">
                    <Form.Label>Description</Form.Label>
                    <div className={"ckeditor-container"}>
                      <>
                        <MyEditor
                          handleChange={(data) => {
                            setDescription(data);
                          }}
                          data={description}
                          {...props}
                        />
                      </>
                    </div>
                    {errors.description && (
                      <Form.Text className="text-muted validationText hasError">
                        {errors.description.message}
                      </Form.Text>
                    )}
                  </Form.Group>
                </Col>
              </Row>
              <Row className="mb-4">
                <h6>Example Videos(up of 4)</h6>
                <hr></hr>
                <Col md={3}>
                  <Form.Group
                    controlId="videoFile"
                    onChange={(e) => handleChangeVideo(e)}
                    className="custom-file-upload"
                  >
                    <Form.Label className={"btn common-btn"}>
                      Upload Video 1
                    </Form.Label>
                    <Form.Control
                      type="file"
                      accept="video/*"
                      {...register("video")}
                    />
                  </Form.Group>
                  {fileVideo ? (
                    <div className="image-item">
                      <video
                        className={"img-table img-thumbnail"}
                        controls
                        src={fileVideo ? fileVideo : ""}
                        crossOrigin={cross_origin ? "anonymous" : null}
                      />

                      <button
                        className={"btn common-btn"}
                        onClick={() => deleteVideo("video1")}
                      >
                        <TrashIcon />
                      </button>
                    </div>
                  ) : (
                    <div className="video-box"></div>
                  )}

                  <Form.Group className="common-input mt-3" controlId="title1">
                    <Form.Control
                      placeholder="Video Title"
                      type="text"
                      {...register("video_title")}
                    />
                  </Form.Group>
                </Col>
                <Col md={3}>
                  <Form.Group
                    controlId="videoFile2"
                    onChange={(e) => handleChangeVideo2(e)}
                    className="custom-file-upload"
                  >
                    <Form.Label className={"btn common-btn"}>
                      Upload Video 2
                    </Form.Label>
                    <Form.Control
                      type="file"
                      accept="video/*"
                      {...register("video2")}
                    />
                  </Form.Group>
                  {fileVideo2 ? (
                    <div className="image-item">
                      <video
                        className={"img-table img-thumbnail"}
                        controls
                        src={fileVideo2 ? fileVideo2 : ""}
                        crossOrigin={cross_origin ? "anonymous" : null}
                      />
                      <button
                        className={"btn common-btn"}
                        onClick={() => deleteVideo("video2")}
                      >
                        <TrashIcon />
                      </button>
                    </div>
                  ) : (
                    <div className="video-box"></div>
                  )}
                  <Form.Group
                    className="common-input mt-3"
                    controlId="videoTitle2"
                  >
                    <Form.Control
                      placeholder="Video Title"
                      {...register("video_title2")}
                      type="text"
                    />
                  </Form.Group>
                </Col>
                <Col md={3}>
                  <Form.Group
                    controlId="videoFile3"
                    onChange={(e) => handleChangeVideo3(e)}
                    className="custom-file-upload"
                  >
                    <Form.Label className={"btn common-btn"}>
                      Upload Video 3
                    </Form.Label>
                    <Form.Control
                      type="file"
                      accept="video/*"
                      {...register("video3")}
                    />
                  </Form.Group>
                  {fileVideo3 ? (
                    <div className="image-item">
                      <video
                        className={"img-table img-thumbnail"}
                        controls
                        src={fileVideo3 ? fileVideo3 : ""}
                        crossOrigin={cross_origin ? "anonymous" : null}
                      />
                      <button
                        className={"btn common-btn"}
                        onClick={() => deleteVideo("video3")}
                      >
                        <TrashIcon />
                      </button>
                    </div>
                  ) : (
                    <div className="video-box"></div>
                  )}
                  <Form.Group className="common-input mt-3" controlId="title3">
                    <Form.Control
                      placeholder="Video Title"
                      type="text"
                      {...register("video_title3")}
                    />
                  </Form.Group>
                </Col>
                <Col md={3}>
                  <Form.Group
                    controlId="videoFile4"
                    onChange={(e) => handleChangeVideo4(e)}
                    className="custom-file-upload"
                  >
                    <Form.Label className={"btn common-btn"}>
                      Upload Video 4
                    </Form.Label>
                    <Form.Control
                      type="file"
                      accept="video/*"
                      {...register("video4")}
                    />
                  </Form.Group>
                  {fileVideo4 ? (
                    <div className="image-item">
                      <video
                        className={"img-table img-thumbnail"}
                        controls
                        src={fileVideo4 ? fileVideo4 : ""}
                        crossOrigin={cross_origin ? "anonymous" : null}
                      />

                      <button
                        className={"btn common-btn"}
                        onClick={() => deleteVideo("video4")}
                      >
                        <TrashIcon />
                      </button>
                    </div>
                  ) : (
                    <div className="video-box"></div>
                  )}
                  <Form.Group className="common-input mt-3" controlId="title4">
                    <Form.Control
                      placeholder="Video Title"
                      type="text"
                      {...register("video_title4")}
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col md={2}>
                  <Form.Group>
                    <Form.Label>Status</Form.Label>
                    <Form.Select
                      value={isComplete}
                      id={"isComplete"}
                      className="form-select exercise-dropdown"
                      aria-label="IsComplete"
                      {...register("is_complete", {
                        onChange: (e) => {
                          setIsComplete(e.target.value);
                        },
                      })}
                    >
                      <option value="0">Incomplete</option>
                      <option value="1">Complete</option>
                    </Form.Select>
                  </Form.Group>
                </Col>
                <Col md={7}>
                  <Form.Group>
                    <Form.Label>Internal Notes </Form.Label>
                    <Form.Control
                      as="textarea"
                      className="exercise-input"
                      placeholder="Internal Notes"
                      id={"internal_notes"}
                      {...register("internal_notes", {
                        minLength: {
                          value: 3,
                          message: "Min length is 3",
                        },
                        maxLength: {
                          value: 500,
                          message: "Max length is 500",
                        },
                        //   onChange: (e) => props.changed(true)
                      })}
                      type="text"
                    />

                    {errors.internal_notes && (
                      <Form.Text className="text-muted validationText hasError">
                        {errors.internal_notes.message}
                      </Form.Text>
                    )}
                  </Form.Group>
                </Col>
                <Col md={2} className="ms-auto">
                  {loader ? (
                    <FullPageLoader loading={loader} />
                  ) : (
                    <Button
                      type={"submit"}
                      className={"btn common-btn mt-4 w-100"}
                      value={"Create Muscles Anatomy"}
                    >
                      Save
                    </Button>
                  )}
                </Col>
              </Row>
            </Form>
          </div>
        </section>

        <ThemeModal
          title={"S3 Browser"}
          content={
            <S3Browser fileName={fileName} selectedMedia={selectedMedia} />
          }
          size={"xl"}
          show={modalShow}
          onHide={() => {
            setModalShow(false);
          }}
        />
      </div>
    </>
  );
}

export default EditMusclesAnatomy;
