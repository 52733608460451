import S3 from "react-aws-s3";
import { constants } from "./constants";
import { useMemo } from "react";
import Button from "@material-ui/core/Button";
import {
  getPresignedPostUrl,
  getPresignedPostUrlUpdated,
} from "../services/users.service";
import { api } from "../services/api.service";
import AWS from "aws-sdk";
window.Buffer = window.Buffer || require("buffer").Buffer;

export const MoneyFormat = (value) => {
  let currency = Intl.NumberFormat(constants.LOCALE, {
    style: "currency",
    currency: constants.CURRENCY,
    useGrouping: false,
    maximumFractionDigits: 2,
    minimumFractionDigits: 2,
  });

  return currency.format(value);
};

export const randomIdWithName = (userName) => {
  let randomNumber = Math.random().nextInt(100000);
  return userName + randomNumber;
};
export const baseName = (value) => {
  let data = value.split("/");
  return data[data.length - 1] != "" ? data[data.length - 1] : value;
};

export const ageCalculate = (birthday) => {
  // birthday is a date;
  let today = new Date(),
    //birthay has 'Dec 25 1998'
    dob = new Date(birthday),
    //difference in milliseconds
    diff = today.getTime() - dob.getTime(),
    //convert milliseconds into years
    years = Math.floor(diff / 31556736000);
  return years > 0 ? years : 0;
};

export const UploadImageToS3 = async (fileInput) => {
  const file = fileInput;
  const newFileName = file.name;
  const fileSize = file.size;

  //Check filesize
  if (fileSize > parseInt(constants.S3CREDENTIAL.fileSize, 10)) {
    return {
      success: false,
      message: constants.ERRORMESSAGES.fileSize,
    };
  }

  const config = {
    bucketName: constants.S3CREDENTIAL.bucketName,
    dirName: constants.S3CREDENTIAL.dirName,
    region: constants.S3CREDENTIAL.region,
    accessKeyId: constants.S3CREDENTIAL.accessKeyId,
    secretAccessKey: constants.S3CREDENTIAL.secretAccessKey,
    s3Url: constants.S3CREDENTIAL.s3EndPoint,
  };
  console.log("file", file);

  console.log("config", config);
  const ReactS3Client = new S3(config);
  let responseData = {};
  await ReactS3Client.uploadFile(file, newFileName).then((data) => {
    console.log("data", data);
    if (data && data.status === 204) {
      responseData = {
        success: true,
        message: constants.ERRORMESSAGES.fileSuccess,
        data: data.location,
      };
    } else {
      responseData = {
        success: false,
        message: constants.ERRORMESSAGES.fileError,
      };
    }
  });

  return responseData;
};

export const UploadBlobImageToS3 = async (blobData, fileName) => {
  const config = {
    bucketName: constants.S3CREDENTIAL.bucketName,
    dirName: constants.S3CREDENTIAL.dirName,
    region: constants.S3CREDENTIAL.region,
    accessKeyId: constants.S3CREDENTIAL.accessKeyId,
    secretAccessKey: constants.S3CREDENTIAL.secretAccessKey,
    s3Url: constants.S3CREDENTIAL.s3EndPoint,
  };
  const s3 = new AWS.S3(config);
  const params = {
    Bucket: constants.S3CREDENTIAL.bucketName,
    Key: `${"Exercise-Covers"}/${fileName}`,
    ACL: "public-read",
    Body: blobData,
  };

  var s3UploadPromise = new Promise(function (resolve, reject) {
    s3.upload(params, function (err, data) {
      if (err) {
        reject(err);
      } else {
        resolve(data);
      }
    });
  });
  return s3UploadPromise;
};

export const useStoryLinks = (links) => {
  return useMemo(
    () => {
      const components = [];
      for (let i = 0; i < links.length; ++i) {
        const link = links[i];
        let name = link.name;
        let href = link.url;
        if (link.docsUrl) {
          href = link.docsUrl;
          if (!name) name = "Relevant docs";
        } else if (!href) {
          throw new Error(`Link "${link.name}" has no URL specified!`);
        }

        components.push(
          <Button
            href={href}
            size="small"
            color="primary"
            target="_blank"
            variant="contained"
            key={`story-link-${i}`}
            rel="noreferrer noopener"
            style={{ marginBottom: 15, marginRight: 15 }}
          >
            {name}
          </Button>
        );
      }
      return components;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
    // We deliberately leave hook deps empty as we don't exepct links to change.
  );
};

export async function uploadToS3(
  fileType,
  fileName,
  fileContents,
  folder_name = "other"
) {
  console.log("folder_name", folder_name);
  const presignedPostUrl = await getPresignedPostUrl(fileType, fileName);

  const formData = new FormData();
  Object.entries(presignedPostUrl.fields).forEach(([k, v]) => {
    formData.append(k, v);
  });
  formData.append("file", fileContents); // The file has be the last element
  formData.append("folder_name", folder_name);

  /*    const response = await api.post(presignedPostUrl.url, formData, {
            headers: {'Content-Type': 'multipart/form-data'},
        });*/
  const response = await fetch(presignedPostUrl.url, {
    method: "POST",
    body: formData,
  });
  if (response.status == 200 || response.status == 204) {
    // return constants.S3CREDENTIAL.s3EndPoint + presignedPostUrl.fields.key;
    return presignedPostUrl.url + presignedPostUrl.fields.key;
  }
  return false;
}
export async function uploadToS3Updated(
  fileType,
  fileName,
  fileContents,
  folder_name = "other"
) {
  const presignedPostUrl = await getPresignedPostUrlUpdated(
    folder_name,
    fileType,
    fileName
  );

  const formData = new FormData();
  Object.entries(presignedPostUrl.fields).forEach(([k, v]) => {
    formData.append(k, v);
  });
  formData.append("file", fileContents); // The file has be the last element
  formData.append("folder_name", folder_name);

  /*    const response = await api.post(presignedPostUrl.url, formData, {
            headers: {'Content-Type': 'multipart/form-data'},
        });*/
  const response = await fetch(presignedPostUrl.url, {
    method: "POST",
    body: formData,
  });
  if (response.status == 200 || response.status == 204) {
    // return constants.S3CREDENTIAL.s3EndPoint + presignedPostUrl.fields.key;
    return presignedPostUrl.url + presignedPostUrl.fields.key;
  }
  return false;
}

export async function upload(file, folderName, extension, contentType) {
  try {
    console.log("extension", extension);
    console.log("contentType", contentType);
    const s3 = new AWS.S3({
      accessKeyId: constants.S3CREDENTIAL.accessKeyId,
      secretAccessKey: constants.S3CREDENTIAL.secretAccessKey,
    });

    const params = {
      Bucket: constants.S3CREDENTIAL.bucketName,
      Key: `${folderName}/${Date.now()}_${file.name}`,
      Body: file,
      // ContentType: extension ? contentType: 'image/png',
      ContentType: contentType,
      ACL: "public-read",
    };

    var response = await s3.upload(params).promise();
    return response;
  } catch (error) {
    console.error("Error uploading file to S3:", error);
  }
}

const GIT_BRANCH = "master";
export const getGitHubLink = (filePath) =>
  `https://github.com/TimboKZ/chonky-website/blob/${GIT_BRANCH}/${filePath}`;
