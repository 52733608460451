import React, { useEffect, useState } from "react";
import { Col, Form, Row, Button } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";
import { TrashIcon } from "../../components/SvgIcons";
import { EmptyLocalStorage } from "../../services/auth/auth.service";
import S3Browser from "../../components/S3Browser";
import ThemeModal from "../../components/ThemeModal";
import {
  CreateEquipment,
  GetEquipmentOrder,
} from "../../services/equipments.service";
import { GetEquipmentCategories } from "../../services/equipment-categories.service";
import { upload } from "../../utils/helpers";
import { cross_origin } from "../../utils/constants";
import Select from "react-select";
import { constants } from "../../utils/constants";
import FullPageLoader from "../../components/FullPageLoader";

function AddNewEquipment(props) {
  let history = useHistory();
  const [modalShow, setModalShow] = useState(false);
  const [fileName, setFileName] = useState(null);
  const [formData, setFormData] = useState({});
  const [loader, setLoader] = useState(false);
  const [weightKg, setWeightKg] = useState(0);
  const [weightLbs, setWeightLbs] = useState(0);
  const [isUpload, setIsUpload] = useState(false);
  const [referalLinkImage, setReferalLinkImage] = useState("");
  const [categories, setCategories] = useState([]);
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [latestOrder, setLatestOrder] = useState(null);

  const [equipmentImageFile, setEquipmentImageFile] = useState(null);
  const [equipmentImage, setEquipmentImage] = useState("");

  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(constants.PERPAGE);
  const [keyword, setKeyword] = useState("");
  const [rows, setRows] = useState([
    { id: 1, inputValue: "", logo: "", imageFile: "", selectValue: "" },
  ]);

  //State handling storing of the video
  const [image, setImage] = useState("");
  const {
    register,
    watch,
    handleSubmit,
    setError,
    clearErrors,
    formState: { errors },
  } = useForm({
    mode: "onBlur",
  });
  const selectedMedia = (fileName, file) => {
    // console.log(file, 'file Name')
    clearErrors(fileName);
    setFormData({ ...formData, ...{ [fileName]: file } });
    setModalShow(false);
  };

  const handleEquipmentImage = async (event) => {
    setEquipmentImageFile(URL.createObjectURL(event.target.files[0]));
    setEquipmentImage(event.target.files[0]);
  };

  const deleteEquipmentImage = (type) => {
    setEquipmentImageFile(null);
    setEquipmentImage(null);
  };

  const selectedBrandOpt = (selectedValue) => {
    //setSelectedLevels(selectedValue.value);
  };

  const onSubmit = async (data) => {
    setLoader(true);
    // if (!formData.image) {
    //     setError('image', { message: 'Image is required' });
    //     setLoader(false)
    //     return false;
    // }

    let tempImagePath = [];

    for (let i = 0; i < rows.length; i++) {
      let logoImg = "";
      let tempObj = {};
      if (rows[i].imageFile) {
        let s3Data = await upload(
          rows[i].imageFile,
          "equipments/logo",
          rows[i].imageFile.extension || "",
          rows[i].imageFile.type || ""
        );
        logoImg = s3Data.Location;
      } else {
        logoImg = "";
      }
      tempObj.id = rows[i].id;
      tempObj.logo = logoImg;
      tempObj.inputValue = rows[i].inputValue;
      tempObj.selectValue = rows[i].selectValue;

      tempImagePath.push(tempObj);
    }

    if (equipmentImage) {
      let s3Data = await upload(
        equipmentImage,
        "equipments/images",
        equipmentImage.extension || "",
        equipmentImage.type || ""
      );

      data.image = s3Data.Location;
    } else {
      data.image = null;
    }
    data.status = data.status;
    data.weight_kg = weightKg;
    data.weight_lb = weightLbs;
    data.equipment_category_id = selectedCategories;
    data.order_no = latestOrder;
    // data.referral_link2 = JSON.stringify(rows);
    data.referral_link2 = JSON.stringify(tempImagePath);

    await CreateEquipment(data)
      .then(async (data) => {
        setLoader(false);
        if (data.status) {
          toast.success(data.message);
          history.push("/equipments");
        } else {
          toast.error(data.message.replace(/_/g, " "));
        }
      })
      .catch((error) => {
        setLoader(false);
        if (error.response.status == 401) {
          EmptyLocalStorage();
          history.push("/");
        } else {
          return toast.error(error.response.data.message.replace(/_/g, " "));
        }
      });
  };

  const getLatestOrder = async (id) => {
    await GetEquipmentOrder(id)
      .then((result) => {
        if (result.data) {
          setLatestOrder(result?.data);
        }
      })
      .catch((error) => {
        // setLoading(false);
        toast.error(error.response.data.message.replace(/_/g, " "));
      });
  };
  const getCategories = async () => {
    // setLoading(true);
    await GetEquipmentCategories(keyword, page, perPage, 0, "", "", "")
      .then((result) => {
        if (result.status) {
          if (result.data) {
            setCategories(result.data);
          }
        } else {
          //   setLoading(false);
          toast.error(result.message);
        }
      })
      .catch((error) => {
        // setLoading(false);
        toast.error(error.response.data.message.replace(/_/g, " "));
      });
  };

  const selectedCategoryOpt = (selectedValue) => {
    setSelectedCategories(selectedValue.value);
    getLatestOrder(selectedValue.value);
  };

  const handleWeightKg = (event) => {
    const kg = event.target.value;
    const lbs = kg * 2.20462262185;
    setWeightKg(kg);
    setWeightLbs(lbs.toFixed(2));
  };
  const handleWeightLbs = (event) => {
    const lbs = event.target.value;
    const kg = lbs / 2.20462262185;
    setWeightLbs(lbs);
    setWeightKg(kg.toFixed(2));
  };

  const uploadFile = async (fileInput) => {
    let fileUrl = URL.createObjectURL(fileInput[0]);
    setReferalLinkImage(fileInput[0]);
    setImage(fileUrl);
    setIsUpload(true);
  };

  useEffect(() => {
    getCategories();
  }, []);

  const addRow = () => {
    // if (rows.length < 3) {
    const newRow = {
      id: rows.length + 1,
      inputValue: "",
      logo: "",
      imageFile: "",
      selectValue: "",
    };
    setRows([...rows, newRow]);
    // } else {
    //   toast.error("You may include up to 3 referral links");
    // }
  };

  const handleInputChange = (id, value) => {
    const updatedRows = rows.map((row) =>
      row.id === id ? { ...row, inputValue: value } : row
    );
    setRows(updatedRows);
  };

  const handleSelectChange = (id, event) => {
    console.log("event", event);
    const updatedRows = rows.map((row) =>
      row.id === id ? { ...row, selectValue: event.value } : row
    );
    console.log("updatedRows", updatedRows);
    setRows(updatedRows);
  };

  const deleteRow = (id) => {
    const updatedRows = rows.filter((row) => row.id !== id);
    setRows(updatedRows);
  };
  const handleChangeVideo = (e, index) => {
    const newEduFormValues = [...rows];
    newEduFormValues[index].logo = URL.createObjectURL(e.target.files[0]);
    newEduFormValues[index].imageFile = e.target.files[0];
    setRows(newEduFormValues);
  };

  const deleteRowImage = (selectedId) => {
    const updatedRows = rows.map((row) =>
      row.id === selectedId ? { ...row, logo: "", imageFile: "" } : row
    );
    setRows(updatedRows);
  };

  const BackEquipment = () => {
    history.push("/equipments");
  };

  return (
    <div className="equipment-index">
      <div className="page-title">
        <h1>Add New Equipment</h1>
        <Button onClick={BackEquipment} className="btn common-btn">
          Back
        </Button>
      </div>
      <div className="common-form">
        <Form
          className="formAddUser"
          autoComplete="off"
          role="presentation"
          onSubmit={handleSubmit(onSubmit)}
        >
          <Form.Group
            controlId="formFile"
            onChange={(e) => handleEquipmentImage(e)}
            className="custom-file-upload mb-3"
          >
            <Form.Label className={"btn common-btn"}>
              Upload Equipment Image
            </Form.Label>
            <Form.Control
              type="file"
              accept="image/png, image/jpeg, image/jpg, image/gif"
              {...register("image")}
            />
          </Form.Group>
          {equipmentImageFile ? (
            <>
              <div className="image-item">
                <img
                  crossOrigin={cross_origin ? "anonymous" : null}
                  className={"img-table img-thumbnail"}
                  onError={({ currentTarget }) => {
                    currentTarget.onerror = null; // prevents looping
                    currentTarget.src = constants.IMAGENOTFOUND;
                  }}
                  src={equipmentImageFile}
                  alt={"Image"}
                />
                <div className="image-item__btn-wrapper">
                  <button
                    type="button"
                    className="btn common-btn"
                    onClick={() => deleteEquipmentImage("image")}
                  >
                    <TrashIcon />
                  </button>
                </div>
              </div>
            </>
          ) : (
            ""
          )}
          <Form.Group className="mb-4" controlId="categories">
            <Form.Label className="d-block">Equipment Categories</Form.Label>
            <Select
              options={
                categories &&
                categories.map((e) => ({ label: e.name, value: e.id }))
              }
              className="basic-multi-select"
              classNamePrefix="select"
              onChange={selectedCategoryOpt}
            />
          </Form.Group>
          <Form.Group className="mb-4" controlId="euipment_name">
            <Form.Label>Equipment Name*</Form.Label>
            <Form.Control
              placeholder="Enter Equipment Name"
              {...register("title", {
                required: {
                  value: "required",
                  message: "Equipment Name is required",
                },
                minLength: {
                  value: 3,
                  message: "Min length is 3",
                },
              })}
              type="text"
            />
            {errors.title && (
              <Form.Text className="text-muted validationText hasError">
                {errors.title.message}
              </Form.Text>
            )}
          </Form.Group>
          <Row>
            <Col sm={6}>
              <Form.Group controlId="weightKgF">
                <Form.Label className="d-block">Weight (kg)</Form.Label>
                <Form.Control
                  placeholder="Weight (kg)"
                  value={weightKg}
                  {...register("weight_kg", {
                    required: false,
                    onChange: (e) => handleWeightKg(e),
                  })}
                  type="number"
                />
                {/*{errors.weight_kg && <Form.Text*/}
                {/*    className="text-muted validationText hasError">{errors.weight_kg.message}</Form.Text>}*/}
              </Form.Group>
              {/*<Form.Group className="mb-3" controlId="weightKgF">*/}
              {/*    <Form.Label className='d-block'>Weight (kg)</Form.Label>*/}
              {/*    <Form.Select className='formselect exercise-dropdown' aria-label="Weight kg" >*/}
              {/*        <option value="0">None</option>*/}
              {/*        <option value="1">2 Kg</option>*/}
              {/*        <option value="2">4 Kg</option>*/}
              {/*        <option value="3">6 Kg</option>*/}
              {/*        <option value="4">8 Kg</option>*/}
              {/*        <option value="5">10 Kg</option>*/}
              {/*    </Form.Select>*/}
              {/*</Form.Group>*/}
            </Col>
            <Col sm={6}>
              <Form.Group controlId="weightLbsF">
                <Form.Label className="d-block">Weight (lbs)</Form.Label>
                <Form.Control
                  placeholder="Weight (lbs)"
                  value={weightLbs}
                  {...register("weight_lb", {
                    required: false,
                    onChange: (e) => handleWeightLbs(e),
                  })}
                  type="number"
                />
                {/*{errors.weight_lb && <Form.Text*/}
                {/*    className="text-muted validationText hasError">{errors.weight_lb.message}</Form.Text>}*/}
              </Form.Group>
              {/*<Form.Group className="mb-3" controlId="weightLbsF">*/}
              {/*    <Form.Label className='d-block'>Weight (lbs)</Form.Label>*/}
              {/*    <Form.Select className='formselect exercise-dropdown' aria-label="Weight lbs">*/}
              {/*        <option value="0">None</option>*/}
              {/*        <option value="1">2 lbs</option>*/}
              {/*        <option value="2">4 lbs</option>*/}
              {/*        <option value="3">6 lbs</option>*/}
              {/*        <option value="4">8 lbs</option>*/}
              {/*        <option value="5">10 lbs</option>*/}
              {/*    </Form.Select>*/}
              {/*</Form.Group>*/}
            </Col>
          </Row>
          <Row className={"mb-4"}>
            <Col md={1}>
              <Form.Group className="mt-2 float-right" controlId="categories">
                <button
                  type={"button"}
                  className={"btn common-btn px-0 w-100 mt-3"}
                  onClick={addRow}
                >
                  Add
                </button>
              </Form.Group>
            </Col>
          </Row>
          {rows.map((row, index) => (
            <Row key={row.id}>
              <Col xl={5} xxl={3}>
                <Form.Group
                  className="mb-3"
                  controlId={`referralLink_${row.id}`}
                >
                  <Form.Label className="d-block">Referral Link</Form.Label>
                  <Form.Control
                    placeholder="Enter Referral Link"
                    {...register(`referral_link_${row.id}`)}
                    defaultValue={row.inputValue}
                    type="text"
                    onChange={(e) => handleInputChange(row.id, e.target.value)}
                  />
                  {errors[`referral_link_${row.id}`] && (
                    <Form.Text className="text-muted validationText hasError">
                      {errors[`referral_link_${row.id}`].message}
                    </Form.Text>
                  )}
                </Form.Group>
              </Col>
              <Col xl={5} xxl={2}>
                <Form.Group className="mb-3" controlId={`brands_${row.id}`}>
                  <Form.Label className="d-block">Brand Categories</Form.Label>
                  <Form.Select
                    placeholder="Select Brand"
                    id={"brand"}
                    className="formselect program-dropdown"
                    // aria-label="brand"
                    onChange={(e) => handleSelectChange(row.id, e.target)}
                  >
                    <option value={""} selected={true}>
                      Select Brand
                    </option>
                    <option value="1">Amazon</option>
                    <option value="2">Walmart</option>
                    <option value="3">Target</option>
                    <option value="4">Rogue</option>
                    <option value="5">eBay</option>
                    <option value="6">IKEA</option>
                    <option value="7">AliExpress</option>
                    <option value="8">BestBuy</option>
                    <option value="9">Joom</option>
                    <option value="10">Rakuten</option>
                  </Form.Select>
                  {/* <Select
                      options={
                        brands &&
                        brands.map((e) => ({
                          label: e.name,
                          value: e.id,
                        }))
                      }
                      className="basic-multi-select"
                      classNamePrefix="select"
                      onChange={(e) => handleSelectChange(row.id, e)}
                    /> */}
                </Form.Group>
              </Col>
              <Col xl={1} xxl={1} style={{ marginRight: "20px" }}>
                <div className="mt-4">
                  <Form.Group
                    controlId={`formFile_${index}`}
                    onChange={(e) => handleChangeVideo(e, index)}
                    className="custom-file-upload mb-3"
                  >
                    <Form.Label className={"btn common-btn"}>
                      Upload Image
                    </Form.Label>
                    <Form.Control
                      type="file"
                      accept="image/png, image/jpeg, image/jpg, image/gif"
                      // {...register(`image${index}`)}
                    />
                  </Form.Group>
                </div>
              </Col>

              {row?.logo ? (
                <>
                  <div className="image-item-logo">
                    <img
                      crossOrigin={cross_origin ? "anonymous" : null}
                      className={"image-thumbnail-logo"}
                      src={row?.logo}
                      alt={"Image"}
                    />
                  </div>
                  <div className="delete-logo-img">
                    <button
                      type="button"
                      className="common-delete-btn"
                      onClick={() => deleteRowImage(row.id)}
                    >
                      <TrashIcon />
                    </button>
                  </div>
                </>
              ) : (
                ""
              )}
              <Col xl={2} xxl={1}>
                <Form.Group className="mt-4" controlId={`categories_${row.id}`}>
                  <button
                    className={"btn common-btn px-0 w-100"}
                    onClick={() => deleteRow(row.id)}
                  >
                    Delete
                  </button>
                </Form.Group>
              </Col>
            </Row>
          ))}

          <Row>
            {/* <Col className="mt-3" xs={12} sm={12} md={12} lg={12} xl={12}>
                <Form.Group className="mb-3" controlId="referalLink">
                  <Form.Label className="d-block">
                    Referral Link (Amazon)
                  </Form.Label>
                  <Form.Control
                    placeholder="Enter Referral Link"
                    {...register("referral_link", {
                      onChange: (e) => {
                        //getBrand(e.target.value);
                      },
                    })}
                    type="text"
                  />
                  {errors.referalLink && (
                    <Form.Text className="text-muted validationText hasError">
                      {errors.referalLink.message}
                    </Form.Text>
                  )}
                </Form.Group>
              </Col>
              <Col className="mt-3" xs={12} sm={12} md={12} lg={12} xl={12}>
                <Form.Group className="mb-3" controlId="referalLink">
                  <Form.Label className="d-block">
                    Referral Link (Ebay)
                  </Form.Label>
                  <Form.Control
                    placeholder="Enter Referral Link"
                    {...register("referral_link2", {
                      onChange: (e) => {
                      },
                    })}
                    type="text"
                  />
                  {errors.referalLink && (
                    <Form.Text className="text-muted validationText hasError">
                      {errors.referalLink.message}
                    </Form.Text>
                  )}
                </Form.Group>
              </Col> */}
            {/* <Col xs={12} sm={12} md={8} lg={6} xl={6}>
                <Form.Group className="mb-3" controlId="full_name">
                  <Form.Label>Referral Link Image</Form.Label>
                  <input
                    {...register("referral_link_image")}
                    onChange={(e) => uploadFile(e.target.files)}
                    type="file"
                    id="referral_link_image"
                  />
                  {errors.referral_link_image && (
                    <Form.Text className="text-muted validationText hasError">
                      {errors.referral_link_image.message}
                    </Form.Text>
                  )}
                  {isUpload ? (
                    <img
                      src={image}
                      className={"img-table img-thumbnail"}
                      crossOrigin={cross_origin ? "anonymous" : null}
                    />
                  ) : (
                    ""
                  )}
                </Form.Group>
              </Col> */}
            <Col className="mt-3" sm={12}>
              <Form.Group controlId="equipmentdescription">
                <Form.Label>Equipment Description</Form.Label>
                <Form.Control
                  as="textarea"
                  rows={3}
                  {...register("description")}
                />
              </Form.Group>
            </Col>
            <Col className="mt-3" sm={12}>
              <Form.Group controlId="comments">
                <Form.Label>Comments</Form.Label>
                <Form.Control
                  className={"angelas-comments"}
                  placeholder={"Angela’s comments"}
                  as="textarea"
                  rows={3}
                  {...register("note")}
                />
              </Form.Group>
            </Col>
            <Form.Group className="mt-3" controlId="status">
              <Form.Label className="d-block">Select Status</Form.Label>
              <Form.Select
                className="formselect"
                aria-label="status"
                {...register("status", {
                  required: {
                    value: "required",
                    message: "Status is required",
                  },
                })}
              >
                <option value="10">Active</option>
                <option value="20">Inactive</option>
              </Form.Select>
              {errors.status && (
                <Form.Text className="text-muted validationText hasError">
                  {errors.weight.message}
                </Form.Text>
              )}
            </Form.Group>
          </Row>

          <Row>
            <Col className="mt-3 ms-auto" xl={3} xxl={2}>
              {loader ? (
                <FullPageLoader loading={loader} />
              ) : (
                <Button
                  type={"submit"}
                  className={"btn common-btn w-100 px-0 mt-3"}
                  value={"Create Equipment"}
                >
                  Create Equipment
                </Button>
              )}
            </Col>
          </Row>
        </Form>
      </div>

      <ThemeModal
        title={"S3 Browser"}
        content={
          <S3Browser
            fileName={fileName}
            selectedMedia={selectedMedia}
            fileType={"images"}
          />
        }
        size={"xl"}
        show={modalShow}
        onHide={() => {
          setModalShow(false);
        }}
      />
    </div>
  );
}

export default AddNewEquipment;
