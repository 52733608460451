import React, { useState, useEffect } from "react";
import swal from "sweetalert";
import { useHistory } from "react-router-dom";
import {
  Button,
  Col,
  Form,
  Row,
  FormControl,
  InputGroup,
} from "react-bootstrap";
import {
  AddIconOutline,
  DeleteUpdated,
  SearchIcon,
} from "../../components/SvgIcons";
import { MdEdit, MdDelete } from "react-icons/md";
import { toast } from "react-toastify";
import ThemeModal from "../../components/ThemeModal";
import { useForm } from "react-hook-form";
import { EmptyLocalStorage } from "../../services/auth/auth.service";
import {
  CreateOccupation,
  DeleteOccupation,
  GetOccupationById,
  GetOccupations,
  UpdateOccupation,
  DeleteMultipleOccupations,
} from "../../services/occupations.service";
import { DataGridPro, GridToolbar, useGridApiRef } from "@mui/x-data-grid-pro";
import Pagination from "@material-ui/lab/Pagination";
import Stack from "@mui/material/Stack";
import { MenuItem, Select } from "@mui/material";
import {
  ColumnHideShow,
  GetColumnHideShow,
} from "../../services/users.service";
import FullPageLoader from "../../components/FullPageLoader";
import { styled } from "@mui/material/styles";

const StyledDataGrid = styled(DataGridPro)(({ theme }) => ({
  position: "relative",
  "& .MuiDataGrid-toolbarContainer": {
    position: "absolute",
    top: "-43px",
    marginLeft: "50px",
  },
  "& .MuiDataGrid-columnHeaders": {
    fontWeight: 600,
    fontSize: "1.05em",
    fontFamily: "Arial, sans-serif",
  },
  "& .MuiDataGrid-columnHeaderTitle": {
    fontWeight: 600,
    fontSize: "1.05em",
  },
  "& .mui-aqpgxn-MuiFormLabel-root-MuiInputLabel-root": {
    visibility: "hidden !important",
  },
  "& .MuiDataGrid-cell": {
    fontSize: "0.9em",
    fontFamily: "Arial, sans-serif",
  },
  "& .MuiDataGrid-row": {
    minHeight: "30px",
    maxHeight: "30px",
  },
}));

function Occupation() {
  let history = useHistory();
  const apiRef = useGridApiRef();
  const [loading, setLoading] = useState(false);
  const [rows, setRows] = useState([]);
  const [keyword, setKeyword] = useState("");
  const [occupationId, setId] = useState(0);
  const [formData, setFormData] = useState({});
  console.log("formData",formData)
  const [status, setStatus] = useState("");
  const [showAddModal, setShowAddModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [loader, setLoader] = useState(false);
  const [editloader, setEditLoader] = useState(false);
  //Multiple Ids Delete
  const [gridApi, setGridApi] = useState(null);
  const [columns, setColumns] = useState([]);
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(100);
  const [countPage, setPageCount] = useState(0);
  const [columnVisibilityModel, setColumnVisibilityModel] = useState({});
  const [searchLoading, setSearchLoading] = useState(false);

  const handlePaginationChange = (event, value) => {
    setPage(value);
  };

  const handlePageSizeChange = (event) => {
    setPerPage(event.target.value);
  };



  useEffect(() => {
    const fetchData = async () => {
      await getOccupationsFunction(true);
    };

    fetchData();
  }, [status, page, perPage]);

  useEffect(() => {
    if (keyword) {
      getOccupationsFunction(false);
    } else {
      getOccupationsFunction(true);
    }
  }, [keyword, status, page, perPage]);

  useEffect(() => {
    const fetchData = async () => {
      await getColumnsHideShow("occupation", "column_hide_show");
      await getColumnsOrderChange();
    };

    fetchData();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [columns] = await Promise.all([getColumns()]);
        getColumnsWidth("occupation", columns);
      } catch (error) {}
    };

    fetchData();
  }, []);

  const getColumnsWidth = async (type, columns) => {
    await GetColumnHideShow(type, "column_width_change").then((result) => {
      if (result?.status && result?.data) {
        const data = result?.data;
        const updatedObj = columns.map((item) => {
          const foundItem = result?.data.find(
            (data) => data.field_name === item.field
          );
          if (foundItem) {
            return {
              ...item,
              width: foundItem.field_width,
            };
          }
          return item;
        });
        setColumns(updatedObj);
      } else {
        setColumns(columns);
      }
    });
  };

  const handleColumnWidthChange = async (newColumns) => {
    setLoading(true);
    try {
      const requestObj = {
        module_name: "occupation",
        type: "column_width_change",
        field_name: newColumns?.colDef?.field,
        field_width: newColumns?.colDef?.width,
      };
      const result = await ColumnHideShow(requestObj);
      if (result.status && result.data) {
        if (columns.length > 0) {
          const reorderedColumns = columns.map((column) => {
            if (column.field == result?.data?.field_name) {
              return { ...column, width: result?.data?.field_width };
            }
            return column;
          });
          setColumns(reorderedColumns);
        } else {
          console.log("no columns are found.");
        }
      } else {
        toast.error(result.message);
      }
    } catch (error) {
      toast.error(error.response.data.message.replace(/_/g, " "));
    } finally {
      setLoading(false);
    }
  };

  const getColumnsHideShow = async (moduleName, type) => {
    await GetColumnHideShow(moduleName, type).then((result) => {
      if (result?.status && result?.data && result.data.table_data !== null) {
        const tableData = JSON.parse(result?.data?.table_data);
        setColumnVisibilityModel(tableData);
      }
    });
  };

  const getColumnsOrderChange = async () => {
    await GetColumnHideShow("occupation", "column_order_change").then(
      (result) => {
        if (result.status && result.data) {
          const parsedArray = JSON.parse(result.data.table_data);
          const dataArray = Object.values(parsedArray);
          const completeColumns = dataArray.map((item) => {
            if (item.field === "actions") {
              return {
                field: "actions",
                headerName: "Actions",
                sortable: false,
                filterable: false,
                width: item.width,
                renderCell: (params) => {
                  const onDelete = () => {
                    const id = `${params.id}`;
                    swal({
                      title: "Are you sure?",
                      text: "Are you sure you want to delete this occupation?",
                      icon: "warning",
                      buttons: true,
                      dangerMode: true,
                    }).then(async (willShip) => {
                      // Delete Product API
                      if (willShip) {
                        await DeleteOccupation(id)
                          .then((result) => {
                            if (result.status) {
                              swal(result.message, {
                                icon: "success",
                              });
                              getOccupationsFunction();
                            } else {
                              toast.error(result.message);
                            }
                          })
                          .catch((error) => {
                            toast.error(
                              error.response.data.message.replace(/_/g, " ")
                            );
                          });
                      }
                    });
                  };

                  const onEdit = (data) => {
                    const id = data?.id;
                    reset({
                      name: "",
                    });
                    clearErrors("name");
                    setShowEditModal(true);
                    setId(id);
                    GetOccupationById(id)
                      .then((result) => {
                        if (result.status) {
                          if (result.data) {
                            setFormData(result.data);
                            setValue("name", result.data.name);
                          }
                        } else {
                          toast.error(result.message);
                        }
                      })
                      .catch((error) => {
                        toast.error(
                          error.response.data.message.replace(/_/g, " ")
                        );
                      });
                  };

                  return (
                    <div>
                      <button
                        className={"btn btn-primary btn-sm"}
                        onClick={(event) => {
                          event.stopPropagation();
                          onEdit(params.id);
                        }}
                      >
                        <MdEdit />
                      </button>
                      <button
                        className={"btn btn-danger btn-sm mx-2"}
                        onClick={(event) => {
                          event.stopPropagation();
                          onDelete(params.id);
                        }}
                      >
                        <MdDelete />
                      </button>
                    </div>
                  );
                },
              };
            } else {
              return item;
            }
          });
          setColumns(completeColumns);
        }
      }
    );
  };

  const saveColumnOrderToDatabase = async (newOrder) => {
    setLoading(true);
    try {
      const reorderedColumns = newOrder.map((fieldName) => {
        return columns.find((column) => column.field === fieldName);
      });

      const newArray = reorderedColumns.filter(
        (element) => element !== undefined
      );
      const requestObj = {
        table_data: JSON.stringify(newArray),
        module_name: "occupation",
        type: "column_order_change",
      };

      await ColumnHideShow(requestObj)
        .then((result) => {
          if (result.status) {
            if (result.data) {
              getColumnsOrderChange();
            }
          } else {
            setLoading(false);
            toast.error(result.message);
          }
        })
        .catch((error) => {
          setLoading(false);
          toast.error(error.response.data.message.replace(/_/g, " "));
        });
    } catch (error) {
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };

  const handleColumnOrderChange = () => {
    const newOrder = apiRef.current.getAllColumns().map((col) => col.field);
    saveColumnOrderToDatabase(newOrder);
  };

  const toggleCustomFunc = async (newModel) => {
    setLoading(true);
    const requestObj = {
      table_data: JSON.stringify(newModel),
      module_name: "occupation",
      type: "column_hide_show",
    };

    try {
      setColumnVisibilityModel(newModel);
      const result = await ColumnHideShow(requestObj);

      if (result.status) {
        if (result.data) {
          await getColumnsHideShow("occupation", "column_hide_show");
        }
      } else {
        toast.error(result.message);
      }
    } catch (error) {
      toast.error(
        error.response?.data?.message.replace(/_/g, " ") || "An error occurred"
      );
    } finally {
      setLoading(false);
    }
  };

  const alphanumericPattern = /^[a-zA-Z0-9\s]+$/;

  const {
    register,
    reset,
    setValue,
    handleSubmit,
    setError,
    clearErrors,
    formState: { errors },
  } = useForm({
    mode: "onBlur",
  });

  const getOccupationsFunction = async (isInitialLoad = true) => {
    if (isInitialLoad) {
      setLoading(true);
    } else {
      setSearchLoading(true);
    }
    await GetOccupations(keyword, page, perPage, 1, status)
      .then((result) => {
        if (result.status) {
          if (result.data) {
            const rowData = [];
            setPage(result?.data?.meta?.current_page);
            setPageCount(result?.data?.meta?.last_page)
            result?.data?.data?.map((dt) => {
              rowData.push({
                id: dt?.id,
                name: dt?.name,
              });
            });
            setRows(rowData);
          }

          if (isInitialLoad) {
            setLoading(false);
          } else {
            setSearchLoading(false);
          }
        } else {
          setLoading(false);
          toast.error(result.message);
        }
      })
      .catch((error) => {
        if (isInitialLoad) {
          setLoading(false);
        } else {
          setSearchLoading(false);
        }
        toast.error(error.response.data.message.replace(/_/g, " "));
      });
  };

  const onMultipleDelete = async () => {
    const rows = await gridApi.getSelectedRows();
    const dataArray = [...rows.values()];
    const dataArrayIds = dataArray.map((li) => li.id);
    swal({
      title: "Are you sure?",
      text: "Are you sure you want to delete this Exercise?",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then(async (willShip) => {
      //Delete Product API
      let data = {
        removeOccupations: dataArrayIds,
      };
      if (willShip) {
        await DeleteMultipleOccupations(data)
          .then((result) => {
            if (result.status) {
              swal(result.message, {
                icon: "success",
              });
              getOccupationsFunction();
            } else {
              toast.error(result.message);
            }
          })
          .catch((error) => {
            toast.error(error.response.data.message.replace(/_/g, " "));
          });
      }
    });
  };

  const getColumns = async () => {
    const obj = [
      { field: "id", headerName: "ID", width: 100 },
      { field: "name", headerName: "Name", width: 250 },
      {
        field: "actions",
        headerName: "Actions",
        width: 180,
        sortable: false,
        filterable: false,
        disableClickEventBubbling: true,
        renderCell: (params) => {
          const onDelete = () => {
            const id = `${params.id}`;
            swal({
              title: "Are you sure?",
              text: "Are you sure you want to delete this occupation?",
              icon: "warning",
              buttons: true,
              dangerMode: true,
            }).then(async (willShip) => {
              // Delete Product API
              if (willShip) {
                await DeleteOccupation(id)
                  .then((result) => {
                    if (result.status) {
                      swal(result.message, {
                        icon: "success",
                      });
                      getOccupationsFunction();
                    } else {
                      toast.error(result.message);
                    }
                  })
                  .catch((error) => {
                    toast.error(error.response.data.message.replace(/_/g, " "));
                  });
              }
            });
          };

          const onEdit = (data) => {
            const id = data.id;
            reset({
              name: "",
            });
            clearErrors("name");
            setShowEditModal(true);
            setId(id);
            GetOccupationById(id)
              .then((result) => {
                if (result.status) {
                  if (result.data) {
                    setFormData(result.data);
                    setValue("name", result.data.name);
                  }
                } else {
                  toast.error(result.message);
                }
              })
              .catch((error) => {
                toast.error(error.response.data.message.replace(/_/g, " "));
              });
          };

          return (
            <div>
              <button
                className={"btn btn-primary btn-sm"}
                onClick={(event) => {
                  event.stopPropagation();
                  onEdit(params);
                }}
              >
                <MdEdit />
              </button>
              <button
                className={"btn btn-danger btn-sm mx-2"}
                onClick={(event) => {
                  event.stopPropagation();
                  onDelete(params.id);
                }}
              >
                <MdDelete />
              </button>
            </div>
          );
        },
      },
    ];
    return obj;
  };

  const EditModal = (id) => {
    const onEditSubmit = async (data) => {
      setEditLoader(true);
      UpdateOccupation(occupationId, {
        name: data.name,
      })
        .then(async (data) => {
          setEditLoader(false);
          if (data.status) {
            toast.success(data.message);
            setShowEditModal(false);
            getOccupationsFunction();
            reset({
              name: "",
            });
          } else {
            setEditLoader(false);
            toast.error(data.message.replace(/_/g, " "));
          }
        })
        .catch((error) => {
          setLoader(false);
          if (error.response.status == 401) {
            EmptyLocalStorage();
            history.push("/");
          } else {
            return toast.error(error.response.data.message.replace(/_/g, " "));
          }
        });
    };
    return (
      <>
        <div className={"ViewModalContact"}>
          <Form onSubmit={handleSubmit(onEditSubmit)}>
            <Form.Group className={"mb-3"} controlId="bodyType">
              <Form.Label>Occupation Name*</Form.Label>
              <Form.Control
                {...register("name", {
                  required: {
                    value: "required",
                    message: "Occupation Name is required ",
                  },
                  minLength: {
                    value: 2,
                    message: "Min length is 2",
                  },
                  maxLength: {
                    value: 80,
                    message: "Max length is 80",
                  },
                  pattern: {
                    value: alphanumericPattern,
                    message: "Occupation Name must be alphanumeric only",
                  },
                })}
                type={"text"}
                placeholder={"Occupation Name"}
              />
              {errors.name && (
                <Form.Text className=" validationText hasError">
                  {errors.name.message}
                </Form.Text>
              )}
            </Form.Group>
            {editloader ? (
              <div className="spin-loader">
                  <FullPageLoader loading={editloader} />
              </div>
            ) : (
              <Button
                type={"submit"}
                className={"btn common-btn w-100 mt-3"}
                value={"submit"}
              >
                submit
              </Button>
            )}
          </Form>
        </div>
      </>
    );
  };

  const AddModal = () => {
    const [loader, setLoader] = useState(false);
    const {
      register,
      handleSubmit,
      formState: { errors },
    } = useForm({
      mode: "onBlur",
    });

    const onSubmit = async (data) => {
      setLoader(true);
      await CreateOccupation(data)
        .then(async (data) => {
          setLoader(false);
          if (data.status) {
            setShowAddModal(false);
            toast.success(data.message);
            reset({
              name: "",
            });
            getOccupationsFunction();
          } else {
            toast.error(data.message.replace(/_/g, " "));
          }
        })
        .catch((error) => {
          setLoader(false);
          if (error.response.status == 401) {
            EmptyLocalStorage();
            history.push("/");
          } else {
            return toast.error(error.response.data.message.replace(/_/g, " "));
          }
        });
    };

    return (
      <div className={"ViewModalContact"}>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Form.Group className={"mb-3"} controlId="bodyType">
            <Form.Label>Occupation Name*</Form.Label>
            <Form.Control
              {...register("name", {
                required: {
                  value: "required",
                  message: "Occupation Name is required ",
                },
                minLength: {
                  value: 2,
                  message: "Min length is 2",
                },
                maxLength: {
                  value: 80,
                  message: "Max length is 80",
                },
                pattern: {
                  value: alphanumericPattern,
                  message: "Occupation Name must be alphanumeric only",
                },
              })}
              type={"text"}
              placeholder={"Occupation Name"}
            />
            {errors.name && (
              <Form.Text className=" validationText hasError">
                {errors.name.message}
              </Form.Text>
            )}
          </Form.Group>
          {loader ? (
            <div className="spin-loader">
        <FullPageLoader loading={loader} />
            </div>
          ) : (
            <input
              type={"submit"}
              className={"btn common-btn w-100 mt-3"}
              value={"Add New Occupation"}
            />
          )}
        </Form>
      </div>
    );
  };

  return (
    <>
      {loading && <FullPageLoader loading={loading} />}
      <div className="user-index">
        <Row>
          <div className="occupation-index">
            <h1>Occupation</h1>
            <Row>
              <Col lg={1}></Col>
              <Col md={5} className="ms-auto">
                <div className="all-user">
                  <div className="search-bar">
                    <div className="common-search-bar">
                      <InputGroup>
                        <InputGroup.Text id="basic-addon1">
                          <SearchIcon />
                        </InputGroup.Text>
                        <FormControl
                          placeholder="Search"
                          aria-label="Search"
                          aria-describedby="Search"
                          onChange={(e) => {
                            e.target.value.length > 0
                              ? setKeyword(e.target.value.trim())
                              : setKeyword(null);
                          }}
                        />
                      </InputGroup>
                    </div>
                  </div>
                </div>
              </Col>
              <Col md={2} className="">
                <Button
                  className={"btn common-btn   w-100"}
                  onClick={() => setShowAddModal(true)}
                >
                  <AddIconOutline /> Add New
                </Button>
              </Col>
            </Row>
          </div>
        </Row>
        <section className="common-table mt-2 ">
          <div className="all-user">
            <div class="delete-icon">
              <Button className={"common-btn"} onClick={onMultipleDelete}>
                <DeleteUpdated />
              </Button>
            </div>
          </div>

          <div style={{ height: "99vh", width: "100%" }}>
            <StyledDataGrid
              rows={rows}
              loading={searchLoading}
              columnVisibilityModel={columnVisibilityModel}
              onColumnVisibilityModelChange={toggleCustomFunc}
              isColumnResizable
              onColumnWidthChange={handleColumnWidthChange}
              columns={columns}
              checkboxSelection
              disableSelectionOnClick
              unstable_headerFilters
              apiRef={apiRef}
              density="compact"
              pageSize={perPage}
              onPageChange={(newPage) => setPage(newPage)}
              onPageSizeChange={(newPageSize) => {
                setPerPage(newPageSize);
                setPage(1);
              }}
              paginationMode="server"
              slots={{
                toolbar: GridToolbar,
              }}
              initialState={{ pinnedColumns: { right: ["actions"] } }}
              onColumnOrderChange={handleColumnOrderChange}
            />
          </div>

          {loading === "true" ? (
            ""
          ) : (
            <>
              <div className="user-pagination">
                <Row>
                  <Col md={4}></Col>
                  <Col md={4}>
                    {" "}
                    <Stack spacing={2}>
                      <Pagination
                        count={countPage}
                        page={page}
                        onChange={handlePaginationChange}
                        size="large"
                      />
                    </Stack>
                  </Col>
                  <Col md={4}>
                    <Stack direction="row" spacing={2} alignItems="center">
                      <label>Rows per page:</label>
                      <Select value={perPage} onChange={handlePageSizeChange}>
                        <MenuItem value={50}>50</MenuItem>
                        <MenuItem value={100}>100</MenuItem>
                        <MenuItem value={200}>200</MenuItem>
                      </Select>
                    </Stack>
                  </Col>
                </Row>
              </div>
            </>
          )}
        </section>
        <div className="theme-dataGrid products-dataGrid">
          <ThemeModal
            title={"Add New Occupation"}
            show={showAddModal}
            onHide={() => setShowAddModal(false)}
            size={"md"}
            content={<AddModal />}
          />
          <ThemeModal
            title={"Edit Occupation"}
            show={showEditModal}
            onHide={() => setShowEditModal(false)}
            size={"md"}
            content={<EditModal />}
          />
        </div>
      </div>
    </>
  );
}

export default Occupation;
