import React, { useEffect, useState } from "react";
import { Col, Form, Row, Button } from "react-bootstrap";
import { Link, useHistory, Prompt } from "react-router-dom";
import { useForm } from "react-hook-form";
import Select from "react-select";
import { GetExerciseTypes } from "../../services/exercise_types.service";
import { GetMuscles } from "../../services/muscles.service";
import { GetCategories } from "../../services/categories.service";
import { GetBodyTypes } from "../../services/body_types.service";
import {
  CreateExercise,
  UpdateExercisePulseId,
  GetExerciseByPulseId,
} from "../../services/exercises.service";
import { toast } from "react-toastify";
import { EmptyLocalStorage } from "../../services/auth/auth.service";
import {
  confirmMessage,
  REACT_APP_MONDAY_ACCESS_TOKEN,
} from "../../utils/constants";
import FullPageLoader from "../../components/FullPageLoader";

function CreateExerciseStep1() {
  let history = useHistory();
  const [muscles, setMuscles] = useState([]);
  const [subCategories, setSubCategories] = useState([]);
  const [bodyTypes, setBodyTypes] = useState([]);
  const [exerciseType, setExerciseType] = useState([]);
  const [selectedBodyTypes, setSelectedBodyTypes] = useState([]);
  const [selectedSubCategories, setSelectedSubCategories] = useState([]);
  const [selectedExerciseTypes, setSelectedExerciseTypes] = useState([]);
  const [selectedMuscles, setSelectedMuscles] = useState([]);
  const [selectedLevels, setSelectedLevels] = useState(4);
  const [buttonType, setButtonType] = useState("");

  const [filteredBodyTypes, setFilteredBodyTypes] = useState([]);
  const [filteredSubCategories, setFilteredSubCategories] = useState([]);
  const [filteredExerciseTypes, setFilteredExerciseTypes] = useState([]);
  const [filteredMuscles, setFilteredMuscles] = useState([]);

  const [disableButton, setDisableButton] = useState(false);
  const [loader, setLoader] = useState(false);
  const [levels, setLevels] = useState([
    { name: "Level 0", id: 4 },
    { name: "Level 1", id: 1 },
    { name: "Level 2", id: 2 },
    { name: "Level 3", id: 3 },
  ]);
  const {
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: "onBlur",
  });
  const [name, setName] = useState("");

  const [isPageDirty, setPageDirty] = useState(false);
  const [pathname, setPathname] = useState(history.location.pathname);

  const handleNameChange = (event) => {
    setName(event.target.value);
    setPageDirty(false);
  };

  const handleSave = () => {
    setPageDirty(false);
  };

  const navigationPrompt = async (location) => {
    if (isPageDirty) {
      const userConfirmed = window.confirm(confirmMessage.message);
      if (userConfirmed) {
        setDisableButton(true);
        setLoader(true);
        await CreateExercise({
          name: name,
          is_compilation: pathname == "/add-compilation" ? 1 : 0,
        })
          .then(async (data) => {
            if (data.status) {
              return userConfirmed;
            } else {
              setDisableButton(false);
            }
          })
          .catch((error) => {
            setDisableButton(false);
            if (error.response.status == 401) {
              EmptyLocalStorage();
              history.push("/");
            } else {
              setDisableButton(false);
              setLoader(false);
              return toast.error(
                error.response.data.message.replace(/_/g, " ")
              );
            }
          });
      }
      // return userConfirmed;
    }
    return true;
  };

  useEffect(async () => {
    await bodyTypesList();
    await getSubCategories();
    await getExerciseTypes();
    await getMuscles();
  }, []);

  const getExerciseTypes = async () => {
    await GetExerciseTypes("", "", "", 0, "asc", "name").then((result) => {
      if (result.status && result.data) {
        setExerciseType(result.data);
      }
    });
  };

  const getMuscles = async () => {
    setLoader(true);
    await GetMuscles("", "", "", 0, "asc", "name").then((result) => {
      if (result.status && result.data) {
        setMuscles(result.data);
        setLoader(false);
      }
    });
  };

  const getSubCategories = async () => {
    setLoader(true);
    await GetCategories("", "", "", 0, "", "", 0, "asc", "name").then(
      (result) => {
        if (result.status && result.data) {
          setSubCategories(result.data);
        }
      }
    );
  };

  const bodyTypesList = async () => {
    setLoader(true);

    await GetBodyTypes("", "", "", 0, "asc", "name").then((result) => {
      if (result.status && result.data) {
        setBodyTypes(result.data);
      }
    });
  };

  const selectedBodyTypesOpt = (selectedValue) => {
    setFilteredBodyTypes(selectedValue);
    let data = [];
    selectedValue.map((item) => {
      data.push(item.value);
    });
    setSelectedBodyTypes(data);
  };

  const selectedSubCategoryOpt = (selectedValue) => {
    setFilteredSubCategories(selectedValue);
    let data = [];
    selectedValue.map((item) => {
      data.push(item.value);
    });
    setSelectedSubCategories(data);
  };

  const selectedExerciseTypeOpt = (selectedValue) => {
    setFilteredExerciseTypes(selectedValue);
    let data = [];
    selectedValue.map((item) => {
      data.push(item.value);
    });
    setSelectedExerciseTypes(data);
  };

  const selectedMusclesOpt = (selectedValue) => {
    setFilteredMuscles(selectedValue);
    let data = [];
    selectedValue.map((item) => {
      data.push(item.value);
    });
    setSelectedMuscles(data);
  };

  const selectedLevelOpt = (selectedValue) => {
    //let data = [];
    //data.push(selectedValue.value)
    // selectedValue.map((item) => {
    //     data.push(item.value)
    // });
    setSelectedLevels(selectedValue.value);
  };
  const handleClick = () => {
    history.push("/exercises");
  };

  const getDropdownOptions = async (boardId, columnId) => {
    let query = `{
      boards(ids: ${boardId}) {
        columns {
          id
          title
          settings_str
        }
      }
    }`;

    let res = await fetch("https://api.monday.com/v2", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: REACT_APP_MONDAY_ACCESS_TOKEN,
      },
      body: JSON.stringify({
        query: query,
      }),
    });

    let responseBody = await res.json();
    let columns = responseBody?.data?.boards[0]?.columns;
    let dropdownOptions = {};

    columns.forEach((column) => {
      if (column.id === columnId) {
        let settings = JSON.parse(column.settings_str);
        dropdownOptions = settings.labels;
      }
    });

    return dropdownOptions;
  };

  // Usage example

  const addExerciseFunc = async (data) => {
    let exerciseUrl = `http://adminstaging.mybodysolutions.com/edit-exercise/${data?.data?.id}`;
    let exerciseName = name;
    let linkValue = {
      url: exerciseUrl,
      text: exerciseName,
    };

    let levelLatest = await newExerciseLevel(selectedLevels);

    let maleObj = {
      text6__1: `${data?.data?.id}`,
      link__1: linkValue,
    };

    if (levelLatest) {
      maleObj.label__1 = levelLatest;
    }
    if (filteredBodyTypes) {
      // Body type
      let bodyTypeApiValue = await getDropdownOptions(
        7278133312,
        "dropdown4__1"
      );

      let joinedBodyType = filteredBodyTypes.map(
        (item) => `${item.label}-${item.value}`
      );

      const matchesBodyType = bodyTypeApiValue.filter((item2) =>
        joinedBodyType.some((item1) => item1 == item2.name)
      );

      const bodyTypeIds = matchesBodyType.map((item) => item.id);
      maleObj.dropdown4__1 = { ids: bodyTypeIds };
    }
    if (filteredSubCategories) {
      // Sub category
      let subCategoryApiValue = await getDropdownOptions(
        7278133312,
        "dup__of_dropdown__1"
      );

      let joinedSubCategory = filteredSubCategories.map(
        (item) => `${item.label}-${item.value}`
      );

      const matchesSubCategory = subCategoryApiValue.filter((item2) =>
        joinedSubCategory.some((item1) => item1 == item2.name)
      );

      const subCategoryIds = matchesSubCategory.map((item) => item.id);
      maleObj.dup__of_dropdown__1 = { ids: subCategoryIds };
    }
    if (filteredMuscles) {
      // Muscles
      let subMusclesApiValue = await getDropdownOptions(
        7278133312,
        "dup__of_sub_category__1"
      );

      let joinedMuscle = filteredMuscles.map(
        (item) => `${item.label}-${item.value}`
      );

      const matchesMuscle = subMusclesApiValue.filter((item2) =>
        joinedMuscle.some((item1) => item1 == item2.name)
      );

      const muscleIds = matchesMuscle.map((item) => item.id);
      maleObj.dup__of_sub_category__1 = { ids: muscleIds };
    }
    if (filteredExerciseTypes) {
      // Exercise Type
      let joinedExerciseType = filteredExerciseTypes.map(
        (item) => `${item.label}-${item.value}`
      );
      maleObj.label5__1 = joinedExerciseType[0];
    }

    let columnValues = JSON.stringify(maleObj)
      .replace(/\\/g, "\\\\")
      .replace(/"/g, '\\"');

    let queryExercise = `mutation { create_item (board_id: 7278133312, group_id: "1723185117_3333333__1", item_name: "${name}", column_values: "${columnValues}") { id }}`;

    let res = await fetch("https://api.monday.com/v2", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: REACT_APP_MONDAY_ACCESS_TOKEN,
      },
      body: JSON.stringify({
        query: queryExercise,
      }),
    });

    let responseBody = await res.json();
    return responseBody;
  };

  const newExerciseLevel = async (level) => {
    let levelText;
    switch (level) {
      case 1:
        levelText = "0";
        break;

      case 2:
        levelText = "1";
        break;

      case 3:
        levelText = "2";
        break;

      default:
        levelText = "0";
        break;
    }
    return levelText;
  };

  const onSubmit = async (data) => {
    setDisableButton(true);
    setLoader(true);
    setPageDirty(false);

    let response = await addExerciseFunc(data);
    if (response) {
      let res = await GetExerciseByPulseId(response?.data?.create_item?.id);
      // let res = await UpdateExercisePulseId(data?.data?.id, {
      //   pulseId: response?.data?.create_item?.id,
      // });
      if (pathname == "/add-compilation") {
        if (buttonType == "save") {
          history.push("/exercises");
        } else {
          history.push({
            pathname: "add-exercise-steps",
            state: {
              exercise: res.data,
            },
          });
        }
      } else {
        if (buttonType == "save") {
          history.push("/exercises");
        } else {
          history.push({
            pathname: "add-exercise-2",
            state: {
              exercise: res.data,
            },
          });
        }
      }
    }

    // await CreateExercise({
    //   name: name,
    //   body_types: selectedBodyTypes,
    //   exercise_categories: selectedSubCategories,
    //   exercise_types: selectedExerciseTypes,
    //   exercise_muscles: selectedMuscles,
    //   level_id: selectedLevels,
    //   is_compilation: pathname == "/add-compilation" ? 1 : 0,
    // })
    //   .then(async (data) => {
    //     if (data.status) {
    //     } else {
    //       setDisableButton(false);
    //     }
    //   })
    //   .catch((error) => {
    //     setDisableButton(false);
    //     if (error.response.status == 401) {
    //       EmptyLocalStorage();
    //       history.push("/");
    //     } else {
    //       setDisableButton(false);
    //       setLoader(false);
    //       return toast.error(error.response.data.message.replace(/_/g, " "));
    //     }
    //   });
  };

  return (
    <section className="exercise-menagement">
      <div className="page-title">
        <h1 className="page-heading">
          {pathname == "/add-compilation"
            ? "Create Exercise Compilation"
            : "Create Exercise"}
        </h1>

        <Button onClick={handleClick} className={"common-btn"}>
          Back
        </Button>
      </div>
      <div className="common-form">
        <Prompt when={isPageDirty} message={navigationPrompt} />
        <div>
          {loader ? (
            <div className={"text-center"}>
              <FullPageLoader loading={loader} />
            </div>
          ) : (
            <Form
              className="formAddUser"
              id="formAddUser"
              autoComplete="off"
              role="presentation"
              onSubmit={handleSubmit(onSubmit)}
            >
              <Row>
                <Col lg={6}>
                  <Form.Group
                    className="common-select"
                    controlId="exercise_name"
                  >
                    <Form.Label id={"formExerciseName"}>Name*</Form.Label>
                    <input
                      value={name}
                      onChange={handleNameChange}
                      placeholder="Name"
                      name="name"
                      type="text"
                      id="exercise_name"
                      class="form-control"
                    />

                    {errors.name && (
                      <Form.Text className="text-muted validationText hasError">
                        {errors.name.message}
                      </Form.Text>
                    )}
                  </Form.Group>
                </Col>
                <Col md={6}>
                  <Form.Group className="common-select" controlId="bodyType">
                    <Form.Label>Body Types</Form.Label>
                    <Select
                      isMulti
                      options={
                        bodyTypes &&
                        bodyTypes.map((e) => ({ label: e.name, value: e.id }))
                      }
                      className="basic-multi-select"
                      classNamePrefix="select"
                      onChange={selectedBodyTypesOpt}
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col md={6}>
                  <Form.Group className="common-select" controlId="subcategory">
                    <Form.Label>Sub Category</Form.Label>
                    <Select
                      isMulti
                      options={
                        subCategories &&
                        subCategories.map((e) => ({
                          label: e.name,
                          value: e.id,
                        }))
                      }
                      className="basic-multi-select"
                      classNamePrefix="select"
                      onChange={selectedSubCategoryOpt}
                    />
                  </Form.Group>
                </Col>
                <Col md={6}>
                  <Form.Group
                    className="common-select"
                    controlId="exerciseType"
                  >
                    <Form.Label>Exercise Type</Form.Label>
                    <Select
                      isMulti
                      options={
                        exerciseType &&
                        exerciseType.map((e) => ({
                          label: e.name,
                          value: e.id,
                        }))
                      }
                      className="basic-multi-select"
                      classNamePrefix="select"
                      onChange={selectedExerciseTypeOpt}
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col md={6}>
                  <Form.Group className="common-select" controlId="muscle">
                    <Form.Label>Muscles</Form.Label>
                    <Select
                      isMulti
                      options={
                        muscles &&
                        muscles.map((e) => ({ label: e.name, value: e.id }))
                      }
                      className="basic-multi-select"
                      classNamePrefix="select"
                      onChange={selectedMusclesOpt}
                    />
                  </Form.Group>
                </Col>
                {pathname != "/add-compilation" ? (
                  <>
                    <Col lg={6}>
                      <Form.Group className="common-select" controlId="levels">
                        <Form.Label>Levels</Form.Label>
                        <Select
                          options={
                            levels &&
                            levels.map((e) => ({
                              label: e.name,
                              value: e.id,
                            }))
                          }
                          className="basic-multi-select"
                          classNamePrefix="select"
                          onChange={selectedLevelOpt}
                        />
                      </Form.Group>
                    </Col>
                  </>
                ) : (
                  <></>
                )}
              </Row>
              <hr />
              <Row>
                <Col md={2} className="ms-auto d-flex">
                  {loader ? (
                    <FullPageLoader loading={loader} />
                  ) : (
                    <>
                      <Button
                        className={"btn common-btn w-100 mt-3"}
                        type={"submit"}
                        name={"next"}
                        onClick={() => setButtonType("next")}
                      >
                        Next
                      </Button>
                      <Button
                        className={"btn common-btn w-100 mt-3 ms-3"}
                        type={"submit"}
                        name={"next"}
                        onClick={() => setButtonType("save")}
                      >
                        Save
                      </Button>
                    </>
                  )}
                </Col>
              </Row>
            </Form>
          )}
        </div>
      </div>
    </section>
  );
}

export default CreateExerciseStep1;
