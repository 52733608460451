/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import Select from "react-select";
import { useForm } from "react-hook-form";
import { useHistory, useLocation } from "react-router-dom";
import { VideoImg } from "../video/VideoImg";
import VideoInput from "./VideoInput";
import { GetEquipments } from "../../services/equipments.service";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import {
  cross_origin,
  genders,
  REACT_APP_MONDAY_ACCESS_TOKEN,
} from "../../utils/constants";
import {
  UpdateExerciseSteps,
  GetExerciseLevelByIds,
  GetExerciseById,
} from "../../services/exercises.service";
import { GetBodyParts } from "../../services/body_parts";
import { EmptyLocalStorage } from "../../services/auth/auth.service";
import VideoEditorMale from "./video/VideoEditor/VideoEditorMale";
import VideoEditorFemale from "./video/VideoEditor/VideoEditorFemale";
import { TrashIcon } from "../../components/SvgIcons";
import { BiEdit, BiTrash } from "react-icons/bi";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPause, faPlay } from "@fortawesome/free-solid-svg-icons";
import { toast } from "react-toastify";
import FullPageLoader from "../../components/FullPageLoader";

function EditLevelDetails(props) {
  let history = useHistory();
  let location = useLocation();
  const VideoMaleRef = useRef();
  const VideoFemaleRef = useRef();
  const [videoMalePlaying, setVideoMalePlaying] = useState(false);
  const [videoFemalePlaying, setVideoFemalePlaying] = useState(false);
  const [disableButton, setDisableButton] = useState(false);
  const [loader, setLoader] = useState(false);
  const [exercise, setExercise] = useState();
  const [changed, setChanged] = useState(false);

  const [maleLevelId, setMaleLevelId] = useState("");
  const [femaleLevelId, setFemaleLevelId] = useState("");

  const [equipmentList, setEquipmentList] = useState([]);
  const [bodyParts, setBodyParts] = useState([]);
  const [metricUnitTypeM, setMetricUnitTypeM] = useState(0);
  const [metricUnitTypeF, setMetricUnitTypeF] = useState(0);
  const [metricTimerMale, setMetricTimerMale] = useState("");
  const [metricTimerFemale, setMetricTimerFemale] = useState("");
  const [suggestedWeightKgM, setSuggestedWeightKgM] = useState();
  const [suggestedWeightLbsM, setSuggestedWeightLbsM] = useState();
  const [suggestedWeightKgF, setSuggestedWeightKgF] = useState();
  const [suggestedWeightLbsF, setSuggestedWeightLbsF] = useState();
  const [maleEquipmentUsed, setMaleEquipmentUsed] = useState([]);
  const [femaleEquipmentUsed, setFemaleEquipmentUsed] = useState([]);
  const [maleFeelItHere, setMaleFeelItHere] = useState([]);
  const [femaleFeelItHere, setFemaleFeelItHere] = useState([]);

  const [editMaleFeelItHere, setEditMaleFeelItHere] = useState(null);
  const [editFemaleFeelItHere, setEditFemaleFeelItHere] = useState(null);

  const [editMaleSelectedEquipments, setEditMaleSelectedEquipments] = useState(
    []
  );
  const [editFemaleSelectedEquipments, setEditFemaleSelectedEquipments] =
    useState([]);
  const [estM, setEstM] = useState("");
  const [estF, setEstF] = useState("");
  const [repsF, setRepsF] = useState("");
  const [repsM, setRepsM] = useState("");

  const [videoUrlTempMale, setVideoUrlTempMale] = useState(null);
  const [videoUrlTemp2Male, setVideoUrlTemp2Male] = useState(null);
  const [videoUrlMale, setVideoUrlMale] = useState(null);
  const [videoEdMale, setVideoEdMale] = useState([]);
  const [videoEdFemale, setVideoEdFemale] = useState([]);
  const [maleTrimmedStartTime, setMaleTrimmedStartTime] = useState("");
  const [maleTrimmedEndTime, setMaleTrimmedEndTime] = useState("");
  const [maleVideoCover, setMaleVideoCover] = useState("");

  const [maleEducationVideos, setMaleEducationVideos] = useState([]);
  // const [trimmedDurationMale, setTrimmedDurationMale] = useState('')

  const [videoUrlTempFemale, setVideoUrlTempFemale] = useState(null);
  const [videoUrlTemp2Female, setVideoUrlTemp2Female] = useState(null);
  const [videoUrlFemale, setVideoUrlFemale] = useState(null);
  const [femaleTrimmedStartTime, setFemaleTrimmedStartTime] = useState("");
  const [femaleTrimmedEndTime, setFemaleTrimmedEndTime] = useState("");
  const [femaleVideoCover, setFemaleVideoCover] = useState("");

  const [femaleEducationVideos, setFemaleEducationVideos] = useState([]);
  // const [trimmedDurationFemale, setTrimmedDurationFemale] = useState('')

  const [resetVideoEditor, setResetVideoEditor] = useState(false);
  const [resetCoverViewer, setResetCoverViewer] = useState(false);

  const [maleData, setMaleData] = useState(null);
  const [femaleData, setFemaleData] = useState(null);

  const [ckeditorRef, setCkeditorRef] = useState([]);
  const [selectRef, setSelectRef] = useState([]);

  const [replicateMale, setReplicateMale] = useState(true);

  const [maleStartingPosition, setMaleStartingPosition] = useState("");
  const [femaleStartingPosition, setFemaleStartingPosition] = useState("");

  const [maleMovement, setMaleMovement] = useState("");
  const [femaleMovement, setFemaleMovement] = useState("");

  const [maleNotes, setMaleNotes] = useState("");
  const [femaleNotes, setFemaleNotes] = useState("");

  const [maleInternalNotes, setMaleInternalNotes] = useState("");
  const [femaleInternalNotes, setFemaleInternalNotes] = useState("");

  const [videoFlipMale, setVideoFlipMale] = useState(false);
  const [videoFlipFemale, setVideoFlipFemale] = useState(false);

  const [levelKey, setLevelKey] = useState("");
  const [isComplete, setIsComplete] = useState(0);

  const [filteredMEquipment, setFilteredMEquipment] = useState([]);
  const [filteredFEquipment, setFilteredFEquipment] = useState([]);

  const [filteredMFeetItHere, setFilteredMFeetItHere] = useState([]);
  const [filteredFFeetItHere, setFilteredFFeetItHere] = useState([]);

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    mode: "onBlur",
  });

  const editorConfiguration = {
    toolbar: [
      "bold",
      "italic",
      "link",
      "undo",
      "redo",
      "numberedList",
      "bulletedList",
    ],
  };

  useEffect(async () => {
    await resetForm();
    await getEquipmentList();
    await getBodyPartList();
    let id = props.match.params.id;

    await getExerciseById(id);
    //console.log("exercise", exercise);
    // await getEditLevelDetail(exercise.level_id, id, 0);

    if (exercise?.level_id == null) {
      await getEditLevelDetail(1, id, 0);
    }
    await getEditLevelDetail(exercise?.level_id, id, 0);
    //setLevelKey(props.activeStep + 1)
    // }, [props.levelNum, props.exerciseId, props.equipment, props.activeStep])
  }, []);

  const getExerciseById = async (id) => {
    await GetExerciseById(id).then((result) => {
      if (result.status) {
        if (result.data) {
          setExercise(result.data);
        } else {
          history.push("/add-exercise");
        }
      }
    });
  };
  if (location.state !== undefined && exercise === undefined) {
    setExercise(location.state.exercise);
    if (location.state.redirectToSummary) {
      location.state.redirectToSummary = false;
      //props.handleStep(12)
    }
  }

  // if (location.state === undefined && exercise === undefined) {
  //     let id = props.match.params.id;
  //     if (id !== undefined && id !== null) {
  //         getExerciseById(id);
  //     } else {
  //         history.push('/add-exercise')
  //     }
  // }

  const getEditLevelDetail = async (level_id, exercise_id, equipment_type) => {
    setDisableButton(true);
    setLoader(true);
    await GetExerciseLevelByIds(level_id, exercise_id, equipment_type).then(
      (result) => {
        if (result.status && result.data) {
          if (result?.data?.male[0]) {
            setMaleData(result?.data?.male[0]);
            setMaleLevelId(result?.data?.male[0]?.id);

            setMetricUnitTypeM(
              result?.data?.male ? result?.data?.male[0]?.metric : ""
            );

            let selectedEquipments = [];
            let setSelectedEquipments = [];
            if (
              result?.data?.male
                ? result?.data?.male[0]?.exerciseEquipmentUses
                : ""
            ) {
              result?.data?.male[0]?.exerciseEquipmentUses.map((item) => {
                selectedEquipments.push({ value: item.id, label: item.title });
                setSelectedEquipments.push(item.id);
              });
              setEditMaleSelectedEquipments(selectedEquipments);
              setMaleEquipmentUsed(setSelectedEquipments);
            }

            setValue(
              "male_feel_it_here",
              result?.data?.male[0]?.body_part_id
                ? result?.data?.male[0]?.body_part_id
                : ""
            );

            setSuggestedWeightLbsM(
              result?.data?.male[0]?.suggested_weight_lbs
                ? result?.data?.male[0]?.suggested_weight_lbs
                : ""
            );
            setValue(
              "male_suggested_weight_lbs",
              result?.data?.male[0]?.suggested_weight_lbs
                ? result?.data?.male[0]?.suggested_weight_lbs
                : ""
            );

            setSuggestedWeightKgM(
              result?.data?.male[0]?.suggested_weight_kg
                ? result?.data?.male[0]?.suggested_weight_kg
                : ""
            );
            setValue(
              "male_suggested_weight_kg",
              result?.data?.male[0]?.suggested_weight_kg
                ? result?.data?.male[0]?.suggested_weight_kg
                : ""
            );

            setMetricTimerMale(
              result?.data?.male[0]?.time ? result?.data?.male[0]?.time : ""
            );

            setRepsM(
              result?.data?.male[0]?.reps ? result?.data?.male[0]?.reps : ""
            );
            setValue(
              "male_metrics_raps",
              result?.data?.male[0]?.reps ? result?.data?.male[0]?.reps : ""
            );

            setEditMaleFeelItHere(result?.data?.male[0]?.body_part_id);
          }
          if (result?.data?.female[0]) {
            setFemaleData(result?.data?.female[0]);
            setFemaleLevelId(result?.data?.female[0]?.id);
            setMetricUnitTypeF(
              result?.data?.female ? result?.data?.female[0]?.metric : ""
            );

            let selectedFEquipments = [];
            let setSelectedFEquipments = [];
            if (
              result?.data?.female
                ? result?.data?.female[0]?.exerciseEquipmentUses
                : ""
            ) {
              result?.data?.female[0]?.exerciseEquipmentUses.map((item) => {
                selectedFEquipments.push({ value: item.id, label: item.title });
                setSelectedFEquipments.push(item.id);
              });
              setEditFemaleSelectedEquipments(selectedFEquipments);
              setFemaleEquipmentUsed(setSelectedFEquipments);
            }
            setValue(
              "female_feel_it_here",
              result?.data?.female[0]?.body_part_id
                ? result?.data?.female[0]?.body_part_id
                : ""
            );
            setSuggestedWeightLbsF(
              result?.data?.female[0]?.suggested_weight_lbs
                ? result?.data?.female[0]?.suggested_weight_lbs
                : ""
            );
            setValue(
              "female_suggested_weight_lbs",
              result?.data?.female[0]?.suggested_weight_lbs
                ? result?.data?.female[0]?.suggested_weight_lbs
                : ""
            );
            setSuggestedWeightKgF(
              result?.data?.female[0]?.suggested_weight_kg
                ? result?.data?.female[0]?.suggested_weight_kg
                : ""
            );
            setValue(
              "female_suggested_weight_kg",
              result?.data?.female[0]?.suggested_weight_kg
                ? result?.data?.female[0]?.suggested_weight_kg
                : ""
            );
            setMetricTimerFemale(
              result?.data?.female[0]?.time ? result?.data?.female[0]?.time : ""
            );
            setRepsF(
              result?.data?.female[0]?.reps ? result?.data?.female[0]?.reps : ""
            );
            setValue(
              "female_metrics_raps",
              result?.data?.female[0]?.reps ? result?.data?.female[0]?.reps : ""
            );
            setEditFemaleFeelItHere(result?.data?.female[0]?.body_part_id);
          }
          setIsComplete(result.data.isComplete);
        }
        setDisableButton(false);
        setLoader(false);
        setChanged(false);
      }
    );
  };

  const getEquipmentList = async () => {
    await GetEquipments("", "", "", 0, "asc", "title").then((result) => {
      if (result.status && result.data) {
        setEquipmentList(result.data);
      }
    });
  };

  const getBodyPartList = async () => {
    await GetBodyParts("", "", "", 0, "asc", "name").then((result) => {
      if (result.status && result.data) {
        setBodyParts(result.data);
      }
    });
  };

  const maleEquipmentUsedOpt = (selectedValue) => {
    let data = [];
    selectedValue.map((item) => {
      data.push(item.value);
    });
    setMaleEquipmentUsed(data);
    setEditMaleSelectedEquipments(selectedValue);
    if (replicateMale === true) {
      femaleEquipmentUsedOpt(selectedValue);
    }
    setChanged(true);
  };

  const femaleEquipmentUsedOpt = (selectedValue) => {
    let data = [];
    selectedValue.map((item) => {
      data.push(item.value);
    });
    setFemaleEquipmentUsed(data);
    setEditFemaleSelectedEquipments(selectedValue);
    setChanged(true);
  };

  useEffect(async () => {
    setChanged(false);
    if (maleData) {
      setVideoEdMale(maleData.exerciseEducationVideo);
      setMaleEducationVideosLoop(maleData.exerciseEducationVideo);
      setVideoUrlMale(maleData.video);
      setVideoFlipMale(maleData.video_flip ? maleData.video_flip : false);
      setVideoUrlTempMale(
        maleData.video
          ? maleData.video +
              "#t=" +
              maleData.video_start_time +
              "," +
              maleData.video_end_time
          : null
      );
      setMaleTrimmedStartTime(maleData.video_start_time);
      setMaleTrimmedEndTime(maleData.video_end_time);
      setMaleVideoCover(maleData.video_cover);
      setValue("male_file_name", maleData.file_name);
      setValue("male_estimated_time", maleData.suggested_time);
      setValue("male_instructions", maleData.instructions);
      setRepsM(maleData.reps ? maleData.reps : "");
    }
    setChanged(false);
  }, [maleData]);

  useEffect(async () => {
    if (femaleData) {
      console.log("femaleData", femaleData);
      setRepsF(femaleData.reps ? femaleData.reps : "");
      setValue("female_file_name", femaleData.file_name);
      setValue("female_estimated_time", femaleData.suggested_time);
      setValue("female_instructions", femaleData.instructions);
      setVideoEdFemale(femaleData.exerciseEducationVideo);
      setFemaleEducationVideosLoop(femaleData.exerciseEducationVideo);
      setVideoFlipFemale(femaleData.video_flip ? femaleData.video_flip : false);
      setVideoUrlTempFemale(
        femaleData.video
          ? femaleData.video +
              "#t=" +
              femaleData.video_start_time +
              "," +
              femaleData.video_end_time
          : null
      );
      setVideoUrlFemale(femaleData.video);
      setFemaleTrimmedStartTime(femaleData.video_start_time);
      setFemaleTrimmedEndTime(femaleData.video_end_time);
      setFemaleVideoCover(femaleData.video_cover);
    }
  }, [femaleData]);

  useEffect(async () => {
    if (editMaleFeelItHere !== "" && editMaleFeelItHere !== null) {
      let editMaleBodyParts = bodyParts.filter(
        (item, index) => item.id == editMaleFeelItHere
      );
      setMaleFeelItHere({
        value: editMaleBodyParts[0] ? editMaleBodyParts[0].id : "",
        label: editMaleBodyParts[0] ? editMaleBodyParts[0].name : "",
      });
      console.log({
        value: editMaleBodyParts[0] ? editMaleBodyParts[0].id : "",
        label: editMaleBodyParts[0] ? editMaleBodyParts[0].name : "",
      });
    }

    if (editFemaleFeelItHere !== "" && editFemaleFeelItHere !== null) {
      let editFemaleBodyParts = bodyParts.filter(
        (item, index) => item.id == editFemaleFeelItHere
      );
      setFemaleFeelItHere({
        value: editFemaleBodyParts[0] ? editFemaleBodyParts[0].id : "",
        label: editFemaleBodyParts[0] ? editFemaleBodyParts[0].name : "",
      });
      console.log({
        value: editFemaleBodyParts[0] ? editFemaleBodyParts[0].id : "",
        label: editFemaleBodyParts[0] ? editFemaleBodyParts[0].name : "",
      });
    }
  }, [editMaleFeelItHere, editFemaleFeelItHere, bodyParts]);

  const addExerciseFuncMale = async (maleData) => {
    let maleStr = "Male-10";
    // Equipment Used
    let equipmentApiValue = await getDropdownOptions(7278125981, "dropdown__1");

    // For Selected Male Equipment
    let joinedMEquipment = filteredMEquipment.map(
      (item) => `${item.label}-${item.value}`
    );

    const matchesMEquipment = equipmentApiValue.filter((item2) =>
      joinedMEquipment.some((item1) => item1 == item2.name)
    );

    const equipmentM = matchesMEquipment.map((item) => item.id);

    // For Selected Male Equipment
    let joinedFEquipment = filteredFEquipment.map(
      (item) => `${item.label}-${item.value}`
    );

    let feelItHereData = await getDropdownOptions(
      7278125981,
      "dup__of_dup__of_label__1"
    );
    let exerciseUrl = `http://adminstaging.mybodysolutions.com/edit-exercise-steps/${exercise.id}`;
    let exerciseName = maleData.file_name;
    let linkValue = {
      url: exerciseUrl,
      text: exerciseName,
    };

    let maleObj = {
      link__1: linkValue,
      text6__1: `${exercise.id}`,
      text__1: `${maleData?.id}`,
      label3__1: maleStr,
      text4__1: maleVideoCover,
      dup__of_video_cover__1: videoUrlMale,
      dup__of_rep_video__1: maleEducationVideos[0]?.video,
      text8__1: maleEducationVideos[1]?.video,
      text0__1: maleEducationVideos[2]?.video,
      text1__1: maleEducationVideos[3]?.video,
      connect_boards4__1: {
        item_ids: [exercise.pulse_id],
      },
    };

    if (maleData?.starting_position) {
      maleObj.long_text__1 = maleData.starting_position;
    }
    if (maleData?.movements) {
      maleObj.dup__of_long_text3__1 = maleData.movements;
    }
    if (maleData?.notes) {
      maleObj.dup__of_movements__1 = maleData.notes;
    }
    if (maleData?.internal_notes) {
      maleObj.dup__of_long_text__1 = maleData.internal_notes;
    }
    if (maleData?.instructions) {
      maleObj.long_text8__1 = maleData.instructions;
    }
    if (maleData?.reps) {
      maleObj.text5__1 = maleData.reps;
    }
    if (maleData?.time) {
      maleObj.text68__1 = maleData.time;
    }
    if (repsM) {
      maleObj.dup__of_label__1 = repsM == 10 ? "Reps" : "Time";
    }
    if (suggestedWeightKgM) {
      maleObj.numbers__1 = suggestedWeightKgM;
    }
    if (filteredMFeetItHere) {
      // Feel It Here For Male
      let joinedMFeetItHere = `${filteredMFeetItHere.label}-${filteredMFeetItHere.value}`;

      const matchingKeyM = Object.keys(feelItHereData).find(
        (key) => feelItHereData[key] === joinedMFeetItHere
      );
      maleObj.dup__of_dup__of_label__1 = { index: matchingKeyM };
    }
    if (equipmentM) {
      maleObj.dropdown__1 = { ids: equipmentM };
    }
    if (isComplete) {
      maleObj.status8__1 = isComplete == 1 ? { index: 3 } : { index: 4 };
    }
    let columnValuesMale = JSON.stringify(maleObj)
      .replace(/\\/g, "\\\\")
      .replace(/"/g, '\\"');

    // let queryMale = `mutation { create_item (board_id: 7278125981, group_id: "1723185117_3333333__1", item_name: "${maleData.file_name}", column_values: "${columnValuesMale}") { id }}`;

    var queryMale = `mutation {
      change_multiple_column_values (
        item_id: ${maleData.pulse_level_id},
        board_id: 7278125981,
         column_values: "${columnValuesMale}"
      ) {
        id
      }
    }`;

    let res = await fetch("https://api.monday.com/v2", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: REACT_APP_MONDAY_ACCESS_TOKEN,
      },
      body: JSON.stringify({
        query: queryMale,
      }),
    });

    let responseBody = await res.json();
    return responseBody;
  };
  const getDropdownOptions = async (boardId, columnId) => {
    let query = `{
      boards(ids: ${boardId}) {
        columns {
          id
          title
          settings_str
        }
      }
    }`;

    let res = await fetch("https://api.monday.com/v2", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: REACT_APP_MONDAY_ACCESS_TOKEN,
      },
      body: JSON.stringify({
        query: query,
      }),
    });

    let responseBody = await res.json();
    let columns = responseBody?.data?.boards[0]?.columns;
    let dropdownOptions = {};

    columns.forEach((column) => {
      if (column.id === columnId) {
        let settings = JSON.parse(column.settings_str);
        dropdownOptions = settings.labels;
      }
    });
    console.log("dropdownOptions", dropdownOptions);
    return dropdownOptions;
  };
  const addExerciseFuncFemale = async (femaleData, internalNotes) => {
    let femaleStr = "Female-20";

    let feelItHereData = await getDropdownOptions(
      7278125981,
      "dup__of_dup__of_label__1"
    );

    let equipmentApiValue = await getDropdownOptions(7278125981, "dropdown__1");
    let joinedFEquipment = filteredFEquipment.map(
      (item) => `${item.label}-${item.value}`
    );
    const matchesFEquipment = equipmentApiValue.filter((item2) =>
      joinedFEquipment.some((item1) => item1 == item2.name)
    );

    const equipmentF = matchesFEquipment.map((item) => item.id);
    let exerciseUrl = `http://adminstaging.mybodysolutions.com/edit-exercise-steps/${exercise.id}`;
    let exerciseName = femaleData.file_name;
    let linkValue = {
      url: exerciseUrl,
      text: exerciseName,
    };
    let feMaleObj = {
      link__1: linkValue,
      text6__1: `${exercise.id}`,
      text__1: `${femaleData?.id}`,
      label3__1: femaleStr,
      dup__of_video_cover__1: videoUrlFemale,
      dup__of_rep_video__1: femaleEducationVideos[0]?.video,
      text8__1: femaleEducationVideos[1]?.video,
      text0__1: femaleEducationVideos[2]?.video,
      text1__1: femaleEducationVideos[3]?.video,
      connect_boards4__1: {
        item_ids: [exercise.pulse_id],
      },
    };

    if (femaleData?.starting_position) {
      feMaleObj.long_text__1 = femaleData.starting_position;
    }
    if (femaleData?.movements) {
      feMaleObj.dup__of_long_text3__1 = femaleData.movements;
    }
    if (femaleData?.notes) {
      feMaleObj.dup__of_movements__1 = femaleData.notes;
    }
    if (internalNotes) {
      feMaleObj.dup__of_long_text__1 = internalNotes;
    }
    if (femaleData?.instructions) {
      feMaleObj.long_text8__1 = femaleData.instructions;
    }
    if (femaleData?.reps) {
      feMaleObj.text5__1 = femaleData.reps;
    }
    if (femaleData?.time) {
      feMaleObj.text68__1 = femaleData.time;
    }
    if (filteredFFeetItHere) {
      // Feel It Here For Female
      let joinedFFeetItHere = `${filteredFFeetItHere.label}-${filteredFFeetItHere.value}`;

      const matchingKeyF = Object.keys(feelItHereData).find(
        (key) => feelItHereData[key] === joinedFFeetItHere
      );
      feMaleObj.dup__of_dup__of_label__1 = { index: matchingKeyF };
    }
    if (isComplete) {
      feMaleObj.status8__1 = isComplete == 1 ? { index: 3 } : { index: 4 };
    }
    if (equipmentF) {
      feMaleObj.dropdown__1 = { ids: equipmentF };
    }
    if (suggestedWeightKgF) {
      feMaleObj.numbers__1 = suggestedWeightKgF;
    }
    if (repsF) {
      feMaleObj.dup__of_label__1 = repsF == 10 ? "Reps" : "Time";
    }

    let columnValuesFemale = JSON.stringify(feMaleObj)
      .replace(/\\/g, "\\\\")
      .replace(/"/g, '\\"');

    // let queryFemale = `mutation { create_item (board_id: 7278125981, group_id: "1723185117_3333333__1", item_name: "${femaleData.file_name}", column_values: "${columnValuesFemale}") { id }}`;

    var queryFemale = `mutation {
      change_multiple_column_values (
        item_id: ${femaleData.pulse_level_id},
        board_id: 7278125981,
         column_values: "${columnValuesFemale}"
      ) {
        id
      }
    }`;

    let res = await fetch("https://api.monday.com/v2", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: REACT_APP_MONDAY_ACCESS_TOKEN,
      },
      body: JSON.stringify({
        query: queryFemale,
      }),
    });

    let responseBody = await res.json();
    return responseBody;
  };

  const onSubmit = async (data) => {
    if (
      !videoUrlMale ||
      videoUrlMale === "" ||
      !videoUrlFemale ||
      videoUrlFemale === ""
    ) {
      toast.error("Please select videos for male and female.");
    } else if (
      !maleVideoCover ||
      maleVideoCover === "" ||
      !femaleVideoCover ||
      femaleVideoCover === ""
    ) {
      toast.error("Please upload video cover for male and female.");
    }
    // else if (maleEducationVideos.length < 1 || femaleEducationVideos.length < 1) {
    //     toast.error('Please select at least one educational video for male and female.');
    // }
    else {
      setDisableButton(true);
      setLoader(true);
      setChanged(true);
      let levelData = [];

      //male level data
      let maleData = {
        file_name: data.male_file_name,
        level_id: exercise.level_id,
        exercise_id: exercise.id,
        equipment_exercise_type: 0,
        suggested_time: data.male_estimated_time,
        body_part_id: data.male_feel_it_here ? data.male_feel_it_here : null,
        gender: genders.MALE,
        image: "http://someurl",
        reps: repsM,
        sets: "0",
        time: metricTimerMale,
        metric: metricUnitTypeM,
        suggested_weight_kg: suggestedWeightKgM,
        suggested_weight_lbs: suggestedWeightLbsM,
        video_cover: maleVideoCover,
        video: videoUrlMale,
        video_flip: videoFlipMale,
        video_start_time: maleTrimmedStartTime,
        video_end_time: maleTrimmedEndTime,
        equipment_uses: maleEquipmentUsed,
        instructions: data.male_instructions,
        starting_position: data.male_starting_position,
        movements: data.male_movement,
        notes: data.male_notes,
        internal_notes: data.male_internal_notes,
        education_video: maleEducationVideos,
      };
      //female level data
      let femaleData = {
        file_name: data.female_file_name,
        level_id: exercise.level_id,
        exercise_id: exercise.id,
        equipment_exercise_type: 0,
        suggested_time: data.female_estimated_time,
        body_part_id: data.female_feel_it_here
          ? data.female_feel_it_here
          : null,
        gender: genders.FEMALE,
        image: "http://someurl",
        reps: repsF,
        sets: "0",
        time: metricTimerFemale,
        metric: metricUnitTypeF,
        suggested_weight_kg: suggestedWeightKgF,
        suggested_weight_lbs: suggestedWeightLbsF,
        video_cover: femaleVideoCover,
        video: videoUrlFemale,
        video_flip: videoFlipFemale,
        video_start_time: femaleTrimmedStartTime,
        video_end_time: femaleTrimmedEndTime,
        equipment_uses: femaleEquipmentUsed,
        instructions: data.female_instructions,
        starting_position: data.female_starting_position,
        movements: data.female_movement,
        notes: data.female_notes,
        internal_notes: data.female_internal_notes,
        education_video: femaleEducationVideos,
      };

      levelData.push(maleData);
      levelData.push(femaleData);
      if (metricUnitTypeF && metricUnitTypeM) {
        setEstM("");
        setEstF("");
      }

      await UpdateExerciseSteps(exercise.id, {
        level_id: exercise.level_id,
        exercise_id: exercise.id,
        equipment_id: 0,
        male_data: maleData,
        male_id: maleLevelId ? maleLevelId : "",
        female_data: femaleData,
        female_id: femaleLevelId ? femaleLevelId : "",
        exercise_levels: levelData,
        isComplete: isComplete,
      })
        .then(async (data) => {
          if (data.status) {
            // await resetForm()
            let responseMale = await addExerciseFuncMale(data.data[0]);
            let responseFemale = await addExerciseFuncFemale(
              data.data[1],
              data.data[0]?.internal_notes
            );

            setResetVideoEditor(true);
            setResetCoverViewer(true);
            setDisableButton(false);
            setLoader(false);
            toast.success(data.message);
            setChanged(false);
            history.push("/exercises");
          } else {
            setDisableButton(false);
            setLoader(false);
            toast.success(data.message);
          }
        })
        .catch((error) => {
          setDisableButton(false);
          setLoader(false);
          console.log(error);
          toast.error("Something went wrong. Please try again.");
          if (error.response.status == 401) {
            EmptyLocalStorage();
            history.push("/");
          }
        });
    }
  };

  const setMaleVideoUrlFunc = (data) => {
    // if (replicateMale === true) {
    //   setVideoUrlTemp2Female((iniState) => data);
    //   setVideoFlipFemale(false);
    //   setFemaleVideoUrlFunc(data);
    // }
    setVideoUrlMale((iniState) => data);
    setChanged(true);
  };

  const setMaleTrimmedTimingsFunc = (data) => {
    setMaleTrimmedStartTime((iniState) => data.startTime);
    setMaleTrimmedEndTime((iniState) => data.endTime);
    if (replicateMale === true) {
      setFemaleTrimmedStartTime((iniState) => data.startTime);
      setFemaleTrimmedEndTime((iniState) => data.endTime);
    }
  };

  const setMaleTrimmedDurationFunc = (data) => {
    // console.log('setMaleTrimmedDurationFunc', data)
  };

  const setFemaleTrimmedDurationFunc = (data) => {
    // console.log('setMaleTrimmedDurationFunc', data)
  };

  const setMaleVideoCoverFunc = (data) => {
    if (maleVideoCover !== data && data !== null && data !== "") {
      setChanged(true);
    }
    // if (replicateMale === true) {
    //   setFemaleVideoCoverFunc(data);
    // }
    setMaleVideoCover(data);
  };

  const setMaleVideoFileNameFunc = (data) => {
    // if (replicateMale === true) {
    //   setValue("female_file_name", data);
    // }
    setValue("male_file_name", data);
    setChanged(true);
  };

  const setMaleEducationVideosFunc = (data) => {
    if (data !== null) {
      setChanged(true);
      setMaleEducationVideos((maleEducationVideos) => [
        ...maleEducationVideos,
        {
          video: data,
        },
      ]);
      setVideoEdMaleFunc(data);
      // if (replicateMale === true) {
      //   setFemaleEducationVideosFunc(data);
      // }
    }
  };

  const setMaleEducationVideosLoop = (data) => {
    if (data.length > 0) {
      data.map((item, index) =>
        setMaleEducationVideos((maleEducationVideos) => [
          ...maleEducationVideos,
          {
            video: item.video,
          },
        ])
      );
    }
  };

  const setFemaleEducationVideosLoop = (data) => {
    if (data.length > 0) {
      data.map((item, index) =>
        setFemaleEducationVideos((femaleEducationVideos) => [
          ...femaleEducationVideos,
          {
            video: item.video,
          },
        ])
      );
    }
  };

  const setFemaleVideoUrlFunc = (data) => {
    setVideoUrlFemale((iniState) => data);
    setChanged(true);
  };

  const setFemaleTrimmedTimingsFunc = (data) => {
    setFemaleTrimmedStartTime((iniState) => data.startTime);
    setFemaleTrimmedEndTime((iniState) => data.endTime);
  };

  const setFemaleVideoFileNameFunc = (data) => {
    setValue("female_file_name", data);
    setChanged(true);
  };

  const setFemaleVideoCoverFunc = (data) => {
    if (femaleVideoCover !== data && data !== null && data !== "") {
      setChanged(true);
    }
    setFemaleVideoCover(data);
  };

  const setFemaleEducationVideosFunc = (data) => {
    if (data !== null) {
      setChanged(true);
      setFemaleEducationVideos((femaleEducationVideos) => [
        ...femaleEducationVideos,
        {
          video: data,
        },
      ]);
      setVideoEdFemaleFunc(data);
    }
  };

  const setVideoEdFemaleFunc = (data) => {
    if (data !== null) {
      setVideoEdFemale((videoEdFemale) => [
        ...videoEdFemale,
        {
          video: data,
        },
      ]);
    }
  };

  const setVideoEdMaleFunc = (data) => {
    if (data !== null) {
      setVideoEdMale((videoEdMale) => [
        ...videoEdMale,
        {
          video: data,
        },
      ]);
    }
  };

  const resetForm = async () => {
    await setChanged(false);
    // await props.setNextStepFunc(null)
    setMaleLevelId("");
    setFemaleLevelId("");

    setResetVideoEditor(false);
    setResetCoverViewer(false);

    setReplicateMale((iniState) => true);
    setMetricUnitTypeM((iniState) => 0);
    setMetricUnitTypeF((iniState) => 0);

    // Male Data
    setMaleData((maleData) => null);
    setVideoUrlMale((iniState) => null);
    setVideoUrlTempMale((iniState) => null);
    setMaleTrimmedStartTime((iniState) => null);
    setMaleTrimmedEndTime((iniState) => null);
    setMaleVideoCover((iniState) => null);
    setVideoEdMale([]);
    setMaleEducationVideos([]);

    setMaleEquipmentUsed((iniState) => "");
    setMaleFeelItHere((iniState) => []);

    setMetricTimerMale((iniState) => "");
    setValue("male_file_name", "");
    setValue("male_feel_it_here", "");

    setSuggestedWeightKgM((iniState) => "");
    setSuggestedWeightLbsM((iniState) => "");

    // Female Data
    setFemaleData((maleData) => null);
    setVideoUrlFemale((iniState) => null);
    setVideoUrlTempFemale((iniState) => null);
    setFemaleTrimmedStartTime((iniState) => null);
    setFemaleTrimmedEndTime((iniState) => null);
    setFemaleVideoCover((iniState) => null);
    setVideoEdFemale([]);
    setFemaleEducationVideos([]);

    setFemaleEquipmentUsed((iniState) => "");
    setFemaleFeelItHere((iniState) => []);

    setMetricTimerFemale((iniState) => "");
    setValue("female_file_name", "");

    setSuggestedWeightKgF((iniState) => "");
    setSuggestedWeightLbsF((iniState) => "");
    setIsComplete(true);

    resetCKEditorInstances();
    resetSelectInstances();

    document.getElementById("formUpdateExercise").reset();
    document.getElementById("male_feel_it_here").value = "";
    document.getElementById("female_feel_it_here").value = "";
    await setChanged(false);
    // await props.setNextStepFunc(null)
  };

  const resetCKEditorInstances = () => {
    ckeditorRef.map((item, index) =>
      item.setData("", function () {
        this.updateElement();
      })
    );
  };

  const resetSelectInstances = () => {
    selectRef.map((item) => {
      if (item !== null) {
        item.clearValue();
      }
    });
    setSelectRef([null]);
  };

  const setMetricUnitTypeMFunc = (data) => {
    if (metricUnitTypeM !== data && data !== "" && data !== null) {
      setChanged(true);
    }
    if (replicateMale === true) {
      setMetricUnitTypeM(data);
      setMetricUnitTypeF(data);
      setValue("female_metrics_option", data);
    } else {
      setMetricUnitTypeM(data);
    }
  };

  const setMaleEstimatedTimeFunc = (data) => {
    if (replicateMale === true) {
      setChanged(true);
      setValue("female_estimated_time", data);
    }
  };

  const setRepsMFunc = (data) => {
    if (repsM !== data && data !== "" && data !== null) {
      setChanged(true);
    }
    if (replicateMale === true) {
      setRepsM(data);
      setRepsF(data);
      setValue("female_metrics_raps", data);
    } else {
      setRepsM(data);
    }
  };

  const setMetricTimerMaleFunc = (data) => {
    if (metricTimerMale !== data && data !== "" && data !== null) {
      setChanged(true);
    }
    if (replicateMale === true) {
      setMetricTimerMale(data);
      setMetricTimerFemale(data);
      setValue("female_metrics_timer", data);
    } else {
      setMetricTimerMale(data);
    }
  };

  const setSuggestedWeightKgMFunc = (data) => {
    if (replicateMale === true) {
      setSuggestedWeightKgM(data);
      setSuggestedWeightKgF(data);
      setValue("female_suggested_weight_kg", data);
    } else {
      setSuggestedWeightKgM(data);
    }
  };

  const setSuggestedWeightLbsMFunc = (data) => {
    if (replicateMale === true) {
      setSuggestedWeightLbsM(data);
      setSuggestedWeightLbsF(data);
      setValue("female_suggested_weight_lbs", data);
    } else {
      setSuggestedWeightLbsM(data);
    }
  };

  const setMaleFeelItHereFunc = (selectedValue) => {
    if (selectedValue !== undefined && selectedValue !== null) {
      setChanged(true);
      if (replicateMale === true) {
        setValue("male_feel_it_here", selectedValue ? selectedValue.value : "");
        setMaleFeelItHere(selectedValue);
        setFemaleFeelItHereFunc(selectedValue);
      } else {
        setMaleFeelItHere(selectedValue);
        setValue("male_feel_it_here", selectedValue ? selectedValue.value : "");
      }
    }
  };

  const setFemaleFeelItHereFunc = (selectedValue) => {
    if (selectedValue !== undefined && selectedValue !== null) {
      setChanged(true);
      setValue("female_feel_it_here", selectedValue ? selectedValue.value : "");
      setFemaleFeelItHere(selectedValue);
    }
  };

  const setMaleInstructionsFunc = (data) => {
    setChanged(true);
    if (replicateMale === true) {
      setValue("female_instructions", data);
    }
  };

  const setMaleStartingPositionFunc = (data) => {
    if (replicateMale === true) {
      setMaleStartingPosition(data);
      setValue("male_starting_position", data);

      setFemaleStartingPosition(data);
      setValue("female_starting_position", data);
    } else {
      setMaleStartingPosition(data);
      setValue("male_starting_position", data);
    }
  };

  const setMaleMovementFunc = (data) => {
    if (replicateMale === true) {
      setMaleMovement(data);
      setValue("male_movement", data);

      setFemaleMovement(data);
      setValue("female_movement", data);
    } else {
      setMaleMovement(data);
      setValue("male_movement", data);
    }
  };

  const setMaleNotesFunc = (data) => {
    if (replicateMale === true) {
      setMaleNotes(data);
      setValue("male_notes", data);

      setFemaleNotes(data);
      setValue("female_notes", data);
    } else {
      setMaleNotes(data);
      setValue("male_notes", data);
    }
  };

  const setMaleInternalNotesFunc = (data) => {
    if (replicateMale === true) {
      setMaleInternalNotes(data);
      setValue("male_internal_notes", data);
      setFemaleInternalNotes(data);
      setValue("female_internal_notes", data);
    } else {
      setMaleInternalNotes(data);
      setValue("male_internal_notes", data);
    }
  };

  const setRemoveEdVideoMaleFunc = (data) => {
    if (data !== undefined && data !== "" && data !== null) {
      setChanged(true);
      setMaleEducationVideos((videos) =>
        videos.filter(
          (_, index) => index !== data && data !== "" && data !== null
        )
      );
      setVideoEdMale((videos) =>
        videos.filter(
          (_, index) => index !== data && data !== "" && data !== null
        )
      );
      // if (replicateMale === true) {
      //   setRemoveEdVideoFemaleFunc(data);
      // }
    }
  };

  const setRemoveEdVideoFemaleFunc = (data) => {
    if (data !== undefined && data !== "" && data !== null) {
      setChanged(true);
      setFemaleEducationVideos((videos) =>
        videos.filter(
          (_, index) => index !== data && data !== "" && data !== null
        )
      );
      setVideoEdFemale((videos) =>
        videos.filter(
          (_, index) => index !== data && data !== "" && data !== null
        )
      );
    }
  };

  const resetMaleVideoFunc = () => {
    setVideoUrlTempMale((currentState) => null);
    // if (replicateMale === true) {
    //   resetFemaleVideoFunc();
    // }
    setChanged(true);
  };

  const resetFemaleVideoFunc = () => {
    setVideoUrlTempFemale((currentState) => null);
    setChanged(true);
  };

  const editMaleVideoFunc = () => {
    setVideoUrlTemp2Male(maleData ? maleData.video : null);
    setVideoUrlTempMale(null);
    // if (replicateMale === true) {
    //   editFemaleVideoFunc();
    // }
  };

  const editFemaleVideoFunc = () => {
    setVideoUrlTemp2Female(femaleData ? femaleData.video : null);
    setVideoUrlTempFemale(null);
  };

  const handleWeightKgMale = (data) => {
    if (suggestedWeightKgM !== data && data !== "" && data !== null) {
      setChanged(true);
    }
    if (data !== "" && data !== null) {
      const lbs = data * 2.20462262185;
      if (replicateMale === true) {
        setSuggestedWeightKgM(data);
        setSuggestedWeightLbsM(lbs.toFixed(2));
        setValue("male_suggested_weight_lbs", lbs.toFixed(2));

        setSuggestedWeightKgF(data);
        setValue("female_suggested_weight_kg", data);

        setSuggestedWeightLbsF(lbs.toFixed(2));
        setValue("female_suggested_weight_lbs", lbs.toFixed(2));
      } else {
        setSuggestedWeightKgM(data);
        setSuggestedWeightLbsM(lbs.toFixed(2));
        setValue("male_suggested_weight_lbs", lbs.toFixed(2));
      }
    } else {
      if (replicateMale === true) {
        setSuggestedWeightKgM("");
        setSuggestedWeightLbsM("");
        setValue("male_suggested_weight_lbs", "");

        setSuggestedWeightKgF("");
        setValue("female_suggested_weight_kg", "");

        setSuggestedWeightLbsF("");
        setValue("female_suggested_weight_lbs", "");
      }
      setSuggestedWeightKgM("");
      setSuggestedWeightLbsM("");
      setValue("male_suggested_weight_lbs", "");
    }
  };

  const handleWeightLbsMale = (data) => {
    if (suggestedWeightLbsM !== data && data !== "" && data !== null) {
      setChanged(true);
    }
    if (data !== "" && data !== null) {
      const kg = data / 2.20462262185;
      if (replicateMale === true) {
        setSuggestedWeightLbsM(data);
        setSuggestedWeightKgM(kg.toFixed(2));
        setValue("male_suggested_weight_kg", kg.toFixed(2));

        setSuggestedWeightLbsF(data);
        setValue("female_suggested_weight_lbs", data);

        setSuggestedWeightKgF(kg.toFixed(2));
        setValue("female_suggested_weight_kg", kg.toFixed(2));
      } else {
        setSuggestedWeightLbsM(data);
        setSuggestedWeightKgM(kg.toFixed(2));
        setValue("male_suggested_weight_kg", kg.toFixed(2));
      }
    } else {
      if (replicateMale === true) {
        setSuggestedWeightLbsM("");
        setSuggestedWeightKgM("");
        setValue("male_suggested_weight_kg", "");

        setSuggestedWeightLbsF(data);
        setValue("female_suggested_weight_lbs", "");

        setSuggestedWeightKgF("");
        setValue("female_suggested_weight_kg", "");
      }
      setSuggestedWeightLbsM("");
      setSuggestedWeightKgM("");
      setValue("male_suggested_weight_kg", "");
    }
  };

  const handleWeightKgFemale = (data) => {
    if (suggestedWeightKgF !== data && data !== "" && data !== null) {
      setChanged(true);
    }
    if (data !== "" && data !== null) {
      const lbs = data * 2.20462262185;
      setSuggestedWeightKgF(data);
      setSuggestedWeightLbsF(lbs.toFixed(2));
      setValue("female_suggested_weight_lbs", lbs.toFixed(2));
    } else {
      setSuggestedWeightKgF("");
      setSuggestedWeightLbsF("");
      setValue("female_suggested_weight_lbs", "");
    }
  };

  const handleWeightLbsFemale = (data) => {
    if (suggestedWeightLbsF !== data && data !== "" && data !== null) {
      setChanged(true);
    }
    if (data !== "" && data !== null) {
      const kg = data / 2.20462262185;
      setSuggestedWeightLbsF(data);
      setSuggestedWeightKgF(kg.toFixed(2));
      setValue("female_suggested_weight_kg", kg.toFixed(2));
    } else {
      setSuggestedWeightLbsF("");
      setSuggestedWeightKgF("");
      setValue("female_suggested_weight_kg", "");
    }
  };

  const setVideoImageUrlMaleFunc = (data) => {
    setMaleVideoCoverFunc(data);
    // if (replicateMale === true) {
    //   setVideoImageUrlFemaleFunc(data);
    // }
  };

  const setVideoImageUrlFemaleFunc = (data) => {
    if (data !== null && data !== "") {
      setFemaleVideoCoverFunc(data);
    }
  };

  const setFlipMaleFunc = (data) => {
    if (data !== null && data !== "") {
      if (videoFlipMale !== data && data !== "" && data !== null) {
        setChanged(true);
      }
      setVideoFlipMale(data);
      // if (replicateMale === true) {
      //   setFlipFemaleFunc(data);
      // }
    }
  };

  const setFlipFemaleFunc = (data) => {
    if (data !== null && data !== "") {
      if (videoFlipFemale !== data && data !== "" && data !== null) {
        setChanged(true);
      }
      setVideoFlipFemale(data);
    }
  };

  const isMaleEditorClose = (data) => {
    if (replicateMale === true && data === true) {
      setResetVideoEditor(true);
    }
  };

  const previousMaleNotes = useRef(maleNotes);
  const previousMaleStartingPosition = useRef(maleStartingPosition);
  const previousMaleInternalNotes = useRef(maleInternalNotes);
  const previousMaleMovement = useRef(maleMovement);

  const previousFemaleNotes = useRef(femaleNotes);
  const previousFemaleStartingPosition = useRef(femaleStartingPosition);
  const previousFemaleInternalNotes = useRef(femaleInternalNotes);
  const previousFemaleMovement = useRef(femaleMovement);

  return (
    <>
      <section className="exercise-step-2">
        <h5>{props.level}</h5>
        {/* <div className="common-tital">
          <Button
            onClick={() =>
              history.push("/edit-exercise/" + props.match.params.id)
            }
            className="common-btn"
          >
            {" "}
            Back            
          </Button>
        </div> */}
        <Form
          id="formUpdateExercise"
          className="formUpdateExercise"
          autoComplete="off"
          role="presentation"
          onSubmit={handleSubmit(onSubmit)}
        >
          <Row>
            <Col md={6}>
              <div className="common-form">
                <div className="common-title">
                  <h6>For Male</h6>
                </div>
                <Form.Group className="common-input">
                  <Form.Label>Male File Name</Form.Label>
                  <Form.Control
                    placeholder="Paste or type text here"
                    id={"male_file_name"}
                    {...register("male_file_name", {
                      required: {
                        value: "required",
                        message: "Male File Name is required",
                      },
                      minLength: {
                        value: 3,
                        message: "Min length is 3",
                      },
                      maxLength: {
                        value: 255,
                        message: "Max length is 255",
                      },
                      onChange: (e) => setMaleVideoFileNameFunc(e.target.value),
                    })}
                    type="text"
                  />
                  {errors.male_file_name && (
                    <Form.Text className="text-muted validationText hasError">
                      {errors.male_file_name.message}
                    </Form.Text>
                  )}
                </Form.Group>

                {videoUrlTempMale ? (
                  <div className="VideoInput">
                    <div className={"vidContainer"}>
                      <video
                        className={`${
                          videoFlipMale ? "video flip-video" : "video"
                        } VideoInput_video`}
                        // controls
                        src={videoUrlTempMale}
                        ref={VideoMaleRef}
                        autoload="metadata"
                        onClick={() => {
                          if (VideoMaleRef.current.paused) {
                            VideoMaleRef.current.play();
                            setVideoMalePlaying(true);
                          } else {
                            VideoMaleRef.current.pause();
                            setVideoMalePlaying(false);
                          }
                        }}
                        crossOrigin={cross_origin ? "anonymous" : null}
                      />
                      <button
                        className="btn common-btn"
                        type="button"
                        onClick={() => resetMaleVideoFunc()}
                      >
                        <TrashIcon />
                      </button>
                      {/* <span
                        className={"vidDeleteBtn"}
                        onClick={() => resetMaleVideoFunc()}
                      >
                        <TrashIcon />
                      </span> */}
                      {/* <span
                        className={"vidEditBtn"}
                        onClick={() => editMaleVideoFunc()}
                      >
                        <BiEdit />
                      </span> */}
                      <div
                        className="editplaypause"
                        onClick={() => {
                          if (VideoMaleRef.current.paused) {
                            VideoMaleRef.current.play();
                            setVideoMalePlaying(true);
                          } else {
                            VideoMaleRef.current.pause();
                            setVideoMalePlaying(false);
                          }
                        }}
                      >
                        {videoMalePlaying ? (
                          <FontAwesomeIcon icon={faPause} />
                        ) : (
                          <FontAwesomeIcon icon={faPlay} />
                        )}
                      </div>
                    </div>
                  </div>
                ) : videoUrlTemp2Male ? (
                  <VideoEditorMale
                    s3VideoUrl={setMaleVideoUrlFunc}
                    trimmedTimings={setMaleTrimmedTimingsFunc}
                    trimmedDuration={setMaleTrimmedDurationFunc}
                    videoFileName={setMaleVideoFileNameFunc}
                    resetEditor={resetVideoEditor}
                    levelKey={levelKey}
                    levelVideoUrl={videoUrlTemp2Male ? videoUrlTemp2Male : null}
                    videoImageUrl={setVideoImageUrlMaleFunc}
                    videoFlip={setFlipMaleFunc}
                    videoFlipped={videoFlipMale}
                    isMaleEditorClose={isMaleEditorClose}
                  />
                ) : (
                  <VideoEditorMale
                    s3VideoUrl={setMaleVideoUrlFunc}
                    trimmedTimings={setMaleTrimmedTimingsFunc}
                    trimmedDuration={setMaleTrimmedDurationFunc}
                    videoFileName={setMaleVideoFileNameFunc}
                    resetEditor={resetVideoEditor}
                    levelKey={levelKey}
                    videoImageUrl={setVideoImageUrlMaleFunc}
                    videoFlip={setFlipMaleFunc}
                    videoFlipped={videoFlipMale}
                    isMaleEditorClose={isMaleEditorClose}
                  />
                )}
                <Col md={8} xl={6}>
                  <Form.Label></Form.Label>
                  <VideoImg
                    imageUrl={setMaleVideoCoverFunc}
                    videoCover={maleVideoCover ? maleVideoCover : ""}
                    resetCoverViewer={resetCoverViewer}
                    levelKey={levelKey}
                  />
                </Col>
                <Col md={8} xl={6}>
                  <Form.Group controlId="email">
                    <Form.Label className="common-input">
                      Educational Video # 1
                    </Form.Label>
                    {videoEdMale[0] ? (
                      <div className="VideoInput" width={300} height={200}>
                        <div className={"vidContainer"}>
                          <video
                            className="VideoInput_video"
                            controls
                            src={
                              videoEdMale[0].video ? videoEdMale[0].video : ""
                            }
                            crossOrigin={cross_origin ? "anonymous" : null}
                          />
                          {/* <span
                          className={"vidDeleteBtn"}
                          onClick={() => setRemoveEdVideoMaleFunc(0)}
                        >
                          <TrashIcon />
                        </span> */}
                          <button
                            className="btn common-btn"
                            type="button"
                            onClick={() => setRemoveEdVideoMaleFunc(0)}
                          >
                            <TrashIcon />
                          </button>
                        </div>
                      </div>
                    ) : (
                      <VideoInput
                        width={300}
                        height={200}
                        videoUrl={setMaleEducationVideosFunc}
                        // preVideoUrl={videoEdMale[0] ? videoEdMale[0].video : ''}
                        resetImageViewer={resetCoverViewer}
                        onCloseDataIndex={0}
                        onCloseRemoveData={setRemoveEdVideoMaleFunc}
                        levelKey={levelKey}
                      />
                    )}
                  </Form.Group>
                </Col>
                <Col md={8} xl={6}>
                  <Form.Group controlId="email">
                    <Form.Label className="common-input">
                      Educational Video # 2 (Optional)
                    </Form.Label>
                    {videoEdMale[1] ? (
                      <div className="VideoInput" width={300} height={200}>
                        <div className={"vidContainer"}>
                          <video
                            className="VideoInput_video"
                            controls
                            src={
                              videoEdMale[1].video ? videoEdMale[1].video : ""
                            }
                            crossOrigin={cross_origin ? "anonymous" : null}
                          />
                          {/* <span
                          className={"vidDeleteBtn"}
                          onClick={() => setRemoveEdVideoMaleFunc(1)}
                        >
                          <TrashIcon />
                        </span> */}
                          <button
                            className="btn common-btn"
                            type="button"
                            onClick={() => setRemoveEdVideoMaleFunc(1)}
                          >
                            <TrashIcon />
                          </button>
                        </div>
                      </div>
                    ) : (
                      <VideoInput
                        width={300}
                        height={200}
                        videoUrl={setMaleEducationVideosFunc}
                        // preVideoUrl={videoEdMale[1] ? videoEdMale[1].video : ''}
                        resetImageViewer={resetCoverViewer}
                        onCloseDataIndex={1}
                        onCloseRemoveData={setRemoveEdVideoMaleFunc}
                        levelKey={levelKey}
                      />
                    )}
                  </Form.Group>
                </Col>
                <Col md={8} xl={6}>
                  <Form.Group controlId="email">
                    <Form.Label className="common-input">
                      Educational Video # 3 (Optional)
                    </Form.Label>
                    {videoEdMale[2] ? (
                      <div className="VideoInput" width={300} height={200}>
                        <div className={"vidContainer"}>
                          <video
                            className="VideoInput_video"
                            controls
                            src={
                              videoEdMale[2].video ? videoEdMale[2].video : ""
                            }
                            crossOrigin={cross_origin ? "anonymous" : null}
                          />
                          {/* <span
                          className={"vidDeleteBtn"}
                          onClick={() => setRemoveEdVideoMaleFunc(2)}
                        >
                          <TrashIcon />
                        </span> */}
                          <button
                            className="btn common-btn"
                            type="button"
                            onClick={() => setRemoveEdVideoMaleFunc(2)}
                          >
                            <TrashIcon />
                          </button>
                        </div>
                      </div>
                    ) : (
                      <VideoInput
                        width={300}
                        height={200}
                        videoUrl={setMaleEducationVideosFunc}
                        // preVideoUrl={videoEdMale[2] ? videoEdMale[2].video : ''}
                        resetImageViewer={resetCoverViewer}
                        onCloseDataIndex={2}
                        onCloseRemoveData={setRemoveEdVideoMaleFunc}
                        levelKey={levelKey}
                      />
                    )}
                  </Form.Group>
                </Col>
                <Col md={8} xl={6}>
                  <Form.Group controlId="email">
                    <Form.Label className="common-input">
                      Educational Video # 4 (Optional)
                    </Form.Label>
                    {videoEdMale[3] ? (
                      <div className="VideoInput" width={300} height={200}>
                        <div className={"vidContainer"}>
                          <video
                            className="VideoInput_video"
                            controls
                            src={
                              videoEdMale[3].video ? videoEdMale[3].video : ""
                            }
                            crossOrigin={cross_origin ? "anonymous" : null}
                          />
                          {/* <span
                          className={"vidDeleteBtn"}
                          onClick={() => setRemoveEdVideoMaleFunc(3)}
                        >
                          <TrashIcon />
                        </span> */}
                          <button
                            className="btn common-btn"
                            type="button"
                            onClick={() => setRemoveEdVideoMaleFunc(3)}
                          >
                            <TrashIcon />
                          </button>
                        </div>
                      </div>
                    ) : (
                      <VideoInput
                        width={300}
                        height={200}
                        videoUrl={setMaleEducationVideosFunc}
                        // preVideoUrl={videoEdMale[3] ? videoEdMale[3].video : ''}
                        resetImageViewer={resetCoverViewer}
                        onCloseDataIndex={3}
                        onCloseRemoveData={setRemoveEdVideoMaleFunc}
                        levelKey={levelKey}
                      />
                    )}
                  </Form.Group>
                </Col>

                <Form.Check
                  className={"form-check-reverse"}
                  id={"replica_check"}
                  label="Replicate details same as Male"
                  name="group1"
                  checked={replicateMale}
                  type={"checkbox"}
                  onChange={() => setReplicateMale(!replicateMale)}
                />
                <hr></hr>
                <Form.Group className="common-select">
                  <Form.Label className="d-block">
                    Select Metric Option
                  </Form.Label>
                  <Form.Select
                    className="form-select exercise-dropdown"
                    id={"male_metrics_option"}
                    aria-label="metric options"
                    {...register("male_metrics_option", {
                      onChange: (e) => setMetricUnitTypeMFunc(e.target.value),
                    })}
                  >
                    <option value="" selected={true} disabled={true}>
                      Select Metric Option
                    </option>
                    <option
                      value="0"
                      selected={
                        maleData
                          ? maleData.metric === 0
                            ? true
                            : false
                          : false
                      }
                    >
                      None
                    </option>
                    <option
                      value="10"
                      selected={
                        maleData
                          ? maleData.metric === 10
                            ? true
                            : false
                          : false
                      }
                    >
                      Reps.
                    </option>
                    <option
                      value="20"
                      selected={
                        maleData
                          ? maleData.metric === 20
                            ? true
                            : false
                          : false
                      }
                    >
                      Time
                    </option>
                  </Form.Select>
                </Form.Group>

                <Form.Group
                  className={`mb-3 ${
                    metricUnitTypeM == 10 ? "d-block" : "d-none"
                  }`}
                >
                  <Form.Label>Reps.</Form.Label>
                  <Form.Control
                    placeholder="Enter Reps."
                    id={"male_metrics_raps"}
                    {...register("male_metrics_raps", {
                      minLength: {
                        value: 1,
                        message: "Min length is 1",
                      },
                      maxLength: {
                        value: 3,
                        message: "Max length is 3",
                      },
                      onChange: (e) => setRepsMFunc(e.target.value),
                    })}
                    type="number"
                  />
                  {errors.male_metrics_raps && (
                    <Form.Text className="text-muted validationText hasError">
                      {errors.male_metrics_raps.message}
                    </Form.Text>
                  )}
                </Form.Group>

                <Form.Group
                  className={`mb-3 ${
                    metricUnitTypeM == 20 ? "d-block" : "d-none"
                  }`}
                >
                  <Form.Label>Timer</Form.Label>
                  <Form.Select
                    name={"male_metrics_timer"}
                    id={"male_metrics_timer"}
                    className="form-select exercise-dropdown"
                    aria-label="Timer"
                    {...register("male_metrics_timer", {
                      onChange: (e) => setMetricTimerMaleFunc(e.target.value),
                    })}
                  >
                    <option value="" selected={true} disabled={true}>
                      Select Timer
                    </option>
                    <option
                      value="0.25"
                      selected={
                        maleData
                          ? maleData.time === "0.25"
                            ? true
                            : false
                          : false
                      }
                    >
                      15sec
                    </option>
                    <option
                      value="0.33"
                      selected={
                        maleData
                          ? maleData.time === "0.33"
                            ? true
                            : false
                          : false
                      }
                    >
                      20sec
                    </option>
                    <option
                      value="0.41"
                      selected={
                        maleData
                          ? maleData.time === "0.41"
                            ? true
                            : false
                          : false
                      }
                    >
                      25sec
                    </option>
                    <option
                      value="0.5"
                      selected={
                        maleData
                          ? maleData.time === "0.5"
                            ? true
                            : false
                          : false
                      }
                    >
                      30sec
                    </option>
                    <option
                      value="0.58"
                      selected={
                        maleData
                          ? maleData.time === "0.58"
                            ? true
                            : false
                          : false
                      }
                    >
                      35sec
                    </option>
                    <option
                      value="0.66"
                      selected={
                        maleData
                          ? maleData.time === "0.66"
                            ? true
                            : false
                          : false
                      }
                    >
                      40sec
                    </option>
                    <option
                      value="0.75"
                      selected={
                        maleData
                          ? maleData.time === "0.75"
                            ? true
                            : false
                          : false
                      }
                    >
                      45sec
                    </option>
                    <option
                      value="0.83"
                      selected={
                        maleData
                          ? maleData.time === "0.83"
                            ? true
                            : false
                          : false
                      }
                    >
                      50sec
                    </option>
                    <option
                      value="0.91"
                      selected={
                        maleData
                          ? maleData.time === "0.91"
                            ? true
                            : false
                          : false
                      }
                    >
                      55sec
                    </option>
                    <option
                      value="1"
                      selected={
                        maleData
                          ? maleData.time === "1"
                            ? true
                            : false
                          : false
                      }
                    >
                      1min
                    </option>
                    <option
                      value="1.25"
                      selected={
                        maleData
                          ? maleData.time === "1.25"
                            ? true
                            : false
                          : false
                      }
                    >
                      1min 15sec
                    </option>
                    <option
                      value="1.33"
                      selected={
                        maleData
                          ? maleData.time === "1.33"
                            ? true
                            : false
                          : false
                      }
                    >
                      1min 20sec
                    </option>
                    <option
                      value="1.41"
                      selected={
                        maleData
                          ? maleData.time === "1.41"
                            ? true
                            : false
                          : false
                      }
                    >
                      1min 25sec
                    </option>
                    <option
                      value="1.5"
                      selected={
                        maleData
                          ? maleData.time === "1.5"
                            ? true
                            : false
                          : false
                      }
                    >
                      1min 30sec
                    </option>
                    <option
                      value="1.58"
                      selected={
                        maleData
                          ? maleData.time === "1.58"
                            ? true
                            : false
                          : false
                      }
                    >
                      1min 35sec
                    </option>
                    <option
                      value="1.66"
                      selected={
                        maleData
                          ? maleData.time === "1.66"
                            ? true
                            : false
                          : false
                      }
                    >
                      1min 40sec
                    </option>
                    <option
                      value="1.75"
                      selected={
                        maleData
                          ? maleData.time === "1.75"
                            ? true
                            : false
                          : false
                      }
                    >
                      1min 45sec
                    </option>
                    <option
                      value="1.83"
                      selected={
                        maleData
                          ? maleData.time === "1.83"
                            ? true
                            : false
                          : false
                      }
                    >
                      1min 50sec
                    </option>
                    <option
                      value="1.91"
                      selected={
                        maleData
                          ? maleData.time === "1.91"
                            ? true
                            : false
                          : false
                      }
                    >
                      1min 55sec
                    </option>
                    <option
                      value="2"
                      selected={
                        maleData
                          ? maleData.time === "2"
                            ? true
                            : false
                          : false
                      }
                    >
                      2min
                    </option>
                  </Form.Select>
                </Form.Group>
                {/*<Form.Group className="mb-3">*/}
                {/*    <Form.Label>Enter Suggested Time</Form.Label>*/}
                {/*    <Form.Control*/}
                {/*        className="p-2"*/}
                {/*        placeholder="Paste or type text here"*/}
                {/*        id={"male_estimated_time"}*/}
                {/*        {...register('male_estimated_time', {*/}
                {/*            required: {*/}
                {/*                value: "required",*/}
                {/*                message: "Estimated Time is required"*/}
                {/*            },*/}
                {/*            minLength: {*/}
                {/*                value: 3,*/}
                {/*                message: "Min length is 3"*/}
                {/*            },*/}
                {/*            maxLength: {*/}
                {/*                value: 25,*/}
                {/*                message: "Max length is 25"*/}
                {/*            },*/}
                {/*            onChange: (e) => setMaleEstimatedTimeFunc(e.target.value),*/}
                {/*        })}*/}
                {/*        type="text"*/}
                {/*    />*/}
                {/*    {estM && <Form.Text*/}
                {/*        className="text-muted validationText hasError">Estimated Time is required</Form.Text>}*/}
                {/*</Form.Group>*/}

                <Row>
                  <Col xl={6}>
                    <Form.Group className="common-select">
                      <Form.Label>Suggested Weight (kg)</Form.Label>
                      {/* <Form.Select className='form-select exercise-dropdown'
                                        id={'male_suggested_weight_kg'} aria-label="Weight kg"
                                        {...register("male_suggested_weight_kg", {
                                            onChange: (e) => setSuggestedWeightKgMFunc(e.target.value),
                                        }
                                        )}>
                                        <option value="0"
                                            selected={maleData ? maleData.suggested_weight_kg === "0" ? true : false : false}>None
                                        </option>
                                        <option value="2"
                                            selected={maleData ? maleData.suggested_weight_kg === "2" ? true : false : false}>2
                                            Kg
                                        </option>
                                        <option value="4"
                                            selected={maleData ? maleData.suggested_weight_kg === "4" ? true : false : false}>4
                                            Kg
                                        </option>
                                        <option value="6"
                                            selected={maleData ? maleData.suggested_weight_kg === "6" ? true : false : false}>6
                                            Kg
                                        </option>
                                        <option value="8"
                                            selected={maleData ? maleData.suggested_weight_kg === "8" ? true : false : false}>8
                                            Kg
                                        </option>
                                        <option value="10"
                                            selected={maleData ? maleData.suggested_weight_kg === "10" ? true : false : false}>10
                                            Kg
                                        </option>
                                    </Form.Select> */}
                      <Form.Control
                        className="p-2"
                        placeholder="Suggested Weight (kg)"
                        id={"male_suggested_weight_kg"}
                        {...register("male_suggested_weight_kg", {
                          onChange: (e) => handleWeightKgMale(e.target.value),
                        })}
                        type="number"
                        step=".01"
                        min="0"
                      />
                    </Form.Group>
                  </Col>

                  <Col xl={6}>
                    <Form.Group className="common-select">
                      <Form.Label>Suggested Weight (lbs)</Form.Label>
                      <Form.Control
                        className="p-2"
                        placeholder="Suggested Weight (lbs)"
                        id={"male_suggested_weight_lbs"}
                        {...register("male_suggested_weight_lbs", {
                          onChange: (e) => handleWeightLbsMale(e.target.value),
                        })}
                        type="number"
                        step=".01"
                        min="0"
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col md={6}>
                    <Form.Group className="common-select">
                      <Form.Label>Equipment Used</Form.Label>
                      <Select
                        isMulti
                        id={"male_equipment_used"}
                        value={editMaleSelectedEquipments}
                        ref={(ref) => {
                          let selectRefs = selectRef;
                          selectRefs.push(ref);
                          setSelectRef(selectRefs);
                        }}
                        options={
                          equipmentList &&
                          equipmentList.map((e) => ({
                            label: e.title,
                            value: e.id,
                          }))
                        }
                        className="basic-multi-select"
                        classNamePrefix="select"
                        onChange={maleEquipmentUsedOpt}
                      />
                    </Form.Group>
                  </Col>
                  <Col md={6}>
                    {" "}
                    <Form.Group className="common-select">
                      <Form.Label>Feel it Here</Form.Label>
                      <Select
                        id={"male_feel_it_here"}
                        value={maleFeelItHere}
                        ref={(ref) => {
                          let selectRefs = selectRef;
                          selectRefs.push(ref);
                          setSelectRef(selectRefs);
                        }}
                        options={
                          bodyParts &&
                          bodyParts.map((e) => ({ label: e.name, value: e.id }))
                        }
                        className="basic-multi-select"
                        classNamePrefix="select"
                        onChange={setMaleFeelItHereFunc}
                      />
                      {errors.male_feel_it_here && (
                        <Form.Text className="text-muted validationText hasError">
                          {errors.male_feel_it_here.message}
                        </Form.Text>
                      )}
                    </Form.Group>
                  </Col>
                </Row>

                <Form.Group className="common-input">
                  <Form.Label>Instructions</Form.Label>
                  <Form.Control
                    as="textarea"
                    rows={4}
                    className="p-2"
                    id={"male_instructions"}
                    placeholder="instructions"
                    {...register("male_instructions", {
                      maxLength: {
                        value: 500,
                        message: "Max length is 500",
                      },
                      onChange: (e) => setMaleInstructionsFunc(e.target.value),
                    })}
                    type="text"
                  />
                  {errors.male_instructions && (
                    <Form.Text className="text-muted validationText hasError">
                      {errors.male_instructions.message}
                    </Form.Text>
                  )}
                </Form.Group>

                <Form.Group className="common-input">
                  <Form.Label>Starting Position</Form.Label>
                  <div className={"ckeditor-container"}>
                    <CKEditor
                      id={"male_starting_position"}
                      {...register("male_starting_position", {})}
                      editor={ClassicEditor}
                      config={editorConfiguration}
                      onReady={(editor) => {
                        let editorRefs = ckeditorRef;
                        editorRefs.push(editor);
                        setCkeditorRef(editorRefs);
                      }}
                      onChange={(event, editor) => {
                        const data = editor.getData();
                        if (data !== previousMaleStartingPosition.current) {
                          setMaleStartingPositionFunc(data);
                          setChanged(true);
                          previousMaleStartingPosition.current = data;
                        }
                      }}
                      onBlur={(event, editor) => {
                        // console.log( 'Blur.', editor );
                      }}
                      onFocus={(event, editor) => {
                        // console.log( 'Focus.', editor );
                      }}
                      data={
                        maleStartingPosition
                          ? maleStartingPosition
                          : maleData
                          ? maleData.starting_position
                            ? maleData.starting_position
                            : ""
                          : ""
                      }
                    />
                    {errors.male_starting_position && (
                      <Form.Text className="text-muted validationText hasError">
                        {errors.male_starting_position.message}
                      </Form.Text>
                    )}
                  </div>
                </Form.Group>

                <Form.Group className="common-input">
                  <Form.Label>Movements</Form.Label>
                  <div className={"ckeditor-container"}>
                    <CKEditor
                      id={"male_movement"}
                      {...register("male_movement", {})}
                      editor={ClassicEditor}
                      config={editorConfiguration}
                      onReady={(editor) => {
                        let editorRefs = ckeditorRef;
                        editorRefs.push(editor);
                        setCkeditorRef(editorRefs);
                      }}
                      onChange={(event, editor) => {
                        const data = editor.getData();
                        setMaleMovementFunc(data);
                        if (data !== previousMaleMovement.current) {
                          setMaleMovementFunc(data);
                          setChanged(true);
                          previousMaleMovement.current = data;
                        }
                      }}
                      onBlur={(event, editor) => {
                        // console.log( 'Blur.', editor );
                      }}
                      onFocus={(event, editor) => {
                        // console.log( 'Focus.', editor );
                      }}
                      data={
                        maleMovement
                          ? maleMovement
                          : maleData
                          ? maleData.movements
                            ? maleData.movements
                            : ""
                          : ""
                      }
                    />
                    {errors.male_movement && (
                      <Form.Text className="text-muted validationText hasError">
                        {errors.male_movement.message}
                      </Form.Text>
                    )}
                  </div>
                </Form.Group>

                <Form.Group className="common-input">
                  <Form.Label>Notes</Form.Label>
                  <div className={"ckeditor-container"}>
                    <CKEditor
                      id={"male_notes"}
                      {...register("male_notes", {})}
                      editor={ClassicEditor}
                      config={editorConfiguration}
                      onReady={(editor) => {
                        let editorRefs = ckeditorRef;
                        editorRefs.push(editor);
                        setCkeditorRef(editorRefs);
                      }}
                      onChange={(event, editor) => {
                        const data = editor.getData();
                        if (data !== previousMaleNotes.current) {
                          setMaleNotesFunc(data);
                          setChanged(true);
                          previousMaleNotes.current = data;
                        }
                      }}
                      onBlur={(event, editor) => {
                        // console.log( 'Blur.', editor );
                      }}
                      onFocus={(event, editor) => {
                        // console.log( 'Focus.', editor );
                      }}
                      data={
                        maleNotes
                          ? maleNotes
                          : maleData
                          ? maleData.notes
                            ? maleData.notes
                            : ""
                          : ""
                      }
                    />
                    {errors.male_notes && (
                      <Form.Text className="text-muted validationText hasError">
                        {errors.male_notes.message}
                      </Form.Text>
                    )}
                  </div>
                </Form.Group>

                <Form.Group className="common-input">
                  <Form.Label>Internal Notes</Form.Label>
                  <div className={"ckeditor-container"}>
                    <CKEditor
                      id={"male_internal_notes"}
                      {...register("male_internal_notes", {})}
                      editor={ClassicEditor}
                      config={editorConfiguration}
                      onReady={(editor) => {
                        let editorRefs = ckeditorRef;
                        editorRefs.push(editor);
                        setCkeditorRef(editorRefs);
                      }}
                      onChange={(event, editor) => {
                        const data = editor.getData();
                        if (data !== previousMaleInternalNotes.current) {
                          setMaleInternalNotesFunc(data);
                          setChanged(true);
                          previousMaleInternalNotes.current = data;
                        }
                      }}
                      onBlur={(event, editor) => {
                        // console.log( 'Blur.', editor );
                      }}
                      onFocus={(event, editor) => {
                        // console.log( 'Focus.', editor );
                      }}
                      data={
                        maleInternalNotes
                          ? maleInternalNotes
                          : maleData
                          ? maleData.internal_notes
                            ? maleData.internal_notes
                            : ""
                          : ""
                      }
                    />
                    {errors.male_internal_notes && (
                      <Form.Text className="text-muted validationText hasError">
                        {errors.male_internal_notes.message}
                      </Form.Text>
                    )}
                  </div>
                </Form.Group>
              </div>
            </Col>
            <Col md={6}>
              <div className="common-form">
                <dvi className="common-title">
                  <h6>For Female</h6>
                </dvi>

                <Form.Group className="common-input">
                  <Form.Label>Female File Name</Form.Label>
                  <Form.Control
                    className="p-2"
                    placeholder="Paste or type text here"
                    id={"female_file_name"}
                    {...register("female_file_name", {
                      required: {
                        value: "required",
                        message: "Female File Name is required",
                      },
                      minLength: {
                        value: 3,
                        message: "Min length is 3",
                      },
                      maxLength: {
                        value: 255,
                        message: "Max length is 255",
                      },
                      onChange: (e) =>
                        setFemaleVideoFileNameFunc(e.target.value),
                    })}
                    type="text"
                  />
                  {errors.female_file_name && (
                    <Form.Text className="text-muted validationText hasError">
                      {errors.female_file_name.message}
                    </Form.Text>
                  )}
                </Form.Group>
                <div className={"female-videos"}>
                  {videoUrlTempFemale ? (
                    <div className="VideoInput">
                      <div className={"vidContainer"}>
                        <video
                          className={`${
                            videoFlipFemale ? "video flip-video" : "video"
                          } VideoInput_video`}
                          src={videoUrlTempFemale}
                          ref={VideoFemaleRef}
                          autoload="metadata"
                          onClick={() => {
                            if (VideoFemaleRef.current.paused) {
                              VideoFemaleRef.current.play();
                              setVideoFemalePlaying(true);
                            } else {
                              VideoFemaleRef.current.pause();
                              setVideoFemalePlaying(false);
                            }
                          }}
                          crossOrigin={cross_origin ? "anonymous" : null}
                        />
                        <button
                          className="btn common-btn"
                          type="button"
                          onClick={() => resetFemaleVideoFunc()}
                        >
                          <TrashIcon />
                        </button>
                        {/* <span
                          className={"vidDeleteBtn"}
                          onClick={() => resetFemaleVideoFunc()}
                        >
                          <TrashIcon />
                        </span> */}
                        {/* <span
                          className={"vidEditBtn"}
                          onClick={() => editFemaleVideoFunc()}
                        >
                          <BiEdit />
                        </span> */}
                        <div
                          className="editplaypause"
                          onClick={() => {
                            if (VideoFemaleRef.current.paused) {
                              VideoFemaleRef.current.play();
                              setVideoMalePlaying(true);
                            } else {
                              VideoFemaleRef.current.pause();
                              setVideoMalePlaying(false);
                            }
                          }}
                        >
                          {videoFemalePlaying ? (
                            <FontAwesomeIcon icon={faPause} />
                          ) : (
                            <FontAwesomeIcon icon={faPlay} />
                          )}
                        </div>
                      </div>
                    </div>
                  ) : videoUrlTemp2Female ? (
                    <VideoEditorFemale
                      s3VideoUrl={setFemaleVideoUrlFunc}
                      trimmedTimings={setFemaleTrimmedTimingsFunc}
                      trimmedDuration={setFemaleTrimmedDurationFunc}
                      videoFileName={setFemaleVideoFileNameFunc}
                      resetEditor={resetVideoEditor}
                      levelKey={levelKey}
                      levelVideoUrl={
                        videoUrlTemp2Female ? videoUrlTemp2Female : null
                      }
                      videoImageUrl={setVideoImageUrlFemaleFunc}
                      videoFlip={setFlipFemaleFunc}
                      videoFlipped={videoFlipFemale}
                    />
                  ) : (
                    <VideoEditorFemale
                      s3VideoUrl={setFemaleVideoUrlFunc}
                      trimmedTimings={setFemaleTrimmedTimingsFunc}
                      trimmedDuration={setFemaleTrimmedDurationFunc}
                      videoFileName={setFemaleVideoFileNameFunc}
                      resetEditor={resetVideoEditor}
                      levelKey={levelKey}
                      videoImageUrl={setVideoImageUrlFemaleFunc}
                      videoFlip={setFlipFemaleFunc}
                      videoFlipped={videoFlipFemale}
                    />
                  )}
                  <Col md={8} xl={6}>
                    <Form.Label></Form.Label>
                    <VideoImg
                      imageUrl={setFemaleVideoCoverFunc}
                      videoCover={femaleVideoCover ? femaleVideoCover : ""}
                      resetCoverViewer={resetCoverViewer}
                      levelKey={levelKey}
                    />
                  </Col>
                  <Col md={8} xl={6}>
                    <Form.Group controlId="email">
                      <Form.Label className="common-input">
                        Educational Video # 1
                      </Form.Label>
                      {videoEdFemale[0] ? (
                        <div className="VideoInput" width={300} height={200}>
                          <div className={"vidContainer"}>
                            <video
                              className="VideoInput_video"
                              controls
                              src={
                                videoEdFemale[0].video
                                  ? videoEdFemale[0].video
                                  : ""
                              }
                              crossOrigin={cross_origin ? "anonymous" : null}
                            />
                            {/* <span
                          className={"vidDeleteBtn"}
                          onClick={() => setRemoveEdVideoFemaleFunc(0)}
                        >
                          <TrashIcon />
                        </span> */}
                            <button
                              className="btn common-btn"
                              type="button"
                              onClick={() => setRemoveEdVideoFemaleFunc(0)}
                            >
                              <TrashIcon />
                            </button>
                          </div>
                        </div>
                      ) : (
                        <VideoInput
                          width={300}
                          height={200}
                          videoUrl={setFemaleEducationVideosFunc}
                          // preVideoUrl={videoEdFemale[0] ? videoEdFemale[0].video : ''}
                          resetImageViewer={resetCoverViewer}
                          onCloseDataIndex={0}
                          onCloseRemoveData={setRemoveEdVideoFemaleFunc}
                          levelKey={levelKey}
                        />
                      )}
                    </Form.Group>
                  </Col>
                  <Col md={8} xl={6}>
                    <Form.Group controlId="email">
                      <Form.Label className="common-input">
                        Educational Video # 2 (Optional)
                      </Form.Label>
                      {videoEdFemale[1] ? (
                        <div className="VideoInput" width={300} height={200}>
                          <div className={"vidContainer"}>
                            <video
                              className="VideoInput_video"
                              controls
                              src={
                                videoEdFemale[1].video
                                  ? videoEdFemale[1].video
                                  : ""
                              }
                              crossOrigin={cross_origin ? "anonymous" : null}
                            />
                            {/* <span
                          className={"vidDeleteBtn"}
                          onClick={() => setRemoveEdVideoFemaleFunc(1)}
                        >
                          <TrashIcon />
                        </span> */}
                            <button
                              className="btn common-btn"
                              type="button"
                              onClick={() => setRemoveEdVideoFemaleFunc(1)}
                            >
                              <TrashIcon />
                            </button>
                          </div>
                        </div>
                      ) : (
                        <VideoInput
                          width={300}
                          height={200}
                          videoUrl={setFemaleEducationVideosFunc}
                          // preVideoUrl={videoEdFemale[1] ? videoEdFemale[1].video : ''}
                          resetImageViewer={resetCoverViewer}
                          onCloseDataIndex={1}
                          onCloseRemoveData={setRemoveEdVideoFemaleFunc}
                          levelKey={levelKey}
                        />
                      )}
                    </Form.Group>
                  </Col>
                  <Col md={8} xl={6}>
                    <Form.Group controlId="email">
                      <Form.Label className="common-input">
                        Educational Video # 3 (Optional)
                      </Form.Label>
                      {videoEdFemale[2] ? (
                        <div className="VideoInput" width={300} height={200}>
                          <div className={"vidContainer"}>
                            <video
                              className="VideoInput_video"
                              controls
                              src={
                                videoEdFemale[2].video
                                  ? videoEdFemale[2].video
                                  : ""
                              }
                              crossOrigin={cross_origin ? "anonymous" : null}
                            />
                            {/* <span
                          className={"vidDeleteBtn"}
                          onClick={() => setRemoveEdVideoFemaleFunc(2)}
                        >
                          <TrashIcon />
                        </span> */}
                            <button
                              className="btn common-btn"
                              type="button"
                              onClick={() => setRemoveEdVideoFemaleFunc(2)}
                            >
                              <TrashIcon />
                            </button>
                          </div>
                        </div>
                      ) : (
                        <VideoInput
                          width={300}
                          height={200}
                          videoUrl={setFemaleEducationVideosFunc}
                          // preVideoUrl={videoEdFemale[2] ? videoEdFemale[2].video : ''}
                          resetImageViewer={resetCoverViewer}
                          onCloseDataIndex={2}
                          onCloseRemoveData={setRemoveEdVideoFemaleFunc}
                          levelKey={levelKey}
                        />
                      )}
                    </Form.Group>
                  </Col>
                  <Col md={8} xl={6}>
                    <Form.Group controlId="email">
                      <Form.Label className="d-block mt-3">
                        Educational Video # 4 (Optional)
                      </Form.Label>
                      {videoEdFemale[3] ? (
                        <div className="VideoInput" width={300} height={200}>
                          <div className={"vidContainer"}>
                            <video
                              className="VideoInput_video"
                              controls
                              src={
                                videoEdFemale[3].video
                                  ? videoEdFemale[3].video
                                  : ""
                              }
                              crossOrigin={cross_origin ? "anonymous" : null}
                            />
                            {/* <span
                          className={"vidDeleteBtn"}
                          onClick={() => setRemoveEdVideoFemaleFunc(3)}
                        >
                          <TrashIcon />
                        </span> */}
                            <button
                              className="btn common-btn"
                              type="button"
                              onClick={() => setRemoveEdVideoFemaleFunc(3)}
                            >
                              <TrashIcon />
                            </button>
                          </div>
                        </div>
                      ) : (
                        <VideoInput
                          width={300}
                          height={200}
                          videoUrl={setFemaleEducationVideosFunc}
                          // preVideoUrl={videoEdFemale[2] ? videoEdFemale[2].video : ''}
                          resetImageViewer={resetCoverViewer}
                          onCloseDataIndex={3}
                          onCloseRemoveData={setRemoveEdVideoFemaleFunc}
                          levelKey={levelKey}
                        />
                      )}
                    </Form.Group>
                  </Col>
                </div>
                {/* FeMale Video Section ends */}
                {/* use same data as male check and disable female inputs */}
                <Form.Check
                  className={"form-check-reverse"}
                  id={"replica_check"}
                  style={{ visibility: "hidden" }}
                  label="Replicate details same as Male"
                  name="group1"
                  checked={replicateMale}
                  type={"checkbox"}
                  onChange={() => setReplicateMale(!replicateMale)}
                />
                <hr></hr>
                <Form.Group className="common-select">
                  <Form.Label>Select Metric Option</Form.Label>
                  <Form.Select
                    id={"female_metrics_option"}
                    className="form-select exercise-dropdown"
                    aria-label="metric options"
                    {...register("female_metrics_option", {
                      onChange: (e) => {
                        setMetricUnitTypeF(e.target.value);
                        setChanged(true);
                      },
                    })}
                  >
                    <option value="" selected={true} disabled={true}>
                      Select Metric Option
                    </option>
                    <option
                      value="0"
                      selected={
                        femaleData
                          ? femaleData.metric === 0
                            ? true
                            : false
                          : false
                      }
                    >
                      None
                    </option>
                    <option
                      value="10"
                      selected={
                        femaleData
                          ? femaleData.metric === 10
                            ? true
                            : false
                          : false
                      }
                    >
                      Reps.
                    </option>
                    <option
                      value="20"
                      selected={
                        femaleData
                          ? femaleData.metric === 20
                            ? true
                            : false
                          : false
                      }
                    >
                      Time
                    </option>
                  </Form.Select>
                </Form.Group>

                {/*time or reps selection*/}
                <Form.Group
                  className={`mb-3 ${
                    metricUnitTypeF == 10 ? "d-block" : "d-none"
                  }`}
                >
                  <Form.Label>Reps.</Form.Label>
                  <Form.Control
                    className="p-2"
                    id={"female_metrics_raps"}
                    placeholder="Enter Reps."
                    {...register("female_metrics_raps", {
                      minLength: {
                        value: 1,
                        message: "Min length is 1",
                      },
                      maxLength: {
                        value: 3,
                        message: "Max length is 3",
                      },
                      onChange: (e) => {
                        setRepsF(e.target.value);
                        setChanged(true);
                      },
                    })}
                    type="number"
                  />
                  {errors.female_metrics_raps && (
                    <Form.Text className="text-muted validationText hasError">
                      {errors.female_metrics_raps.message}
                    </Form.Text>
                  )}
                </Form.Group>

                <Form.Group
                  className={`mb-3 ${
                    metricUnitTypeF == 20 ? "d-block" : "d-none"
                  }`}
                >
                  <Form.Label>Timer</Form.Label>
                  <Form.Select
                    id={"female_metrics_timer"}
                    className="form-select exercise-dropdown"
                    aria-label="Timer"
                    {...register("female_metrics_timer", {
                      onChange: (e) => {
                        setMetricTimerFemale(e.target.value);
                        setChanged(true);
                      },
                    })}
                  >
                    <option value="" selected={true} disabled={true}>
                      Select Timer
                    </option>
                    <option
                      value="0.25"
                      selected={
                        femaleData
                          ? femaleData.time === "0.25"
                            ? true
                            : false
                          : false
                      }
                    >
                      15sec
                    </option>
                    <option
                      value="0.33"
                      selected={
                        femaleData
                          ? femaleData.time === "0.33"
                            ? true
                            : false
                          : false
                      }
                    >
                      20sec
                    </option>
                    <option
                      value="0.41"
                      selected={
                        femaleData
                          ? femaleData.time === "0.41"
                            ? true
                            : false
                          : false
                      }
                    >
                      25sec
                    </option>
                    <option
                      value="0.5"
                      selected={
                        femaleData
                          ? femaleData.time === "0.5"
                            ? true
                            : false
                          : false
                      }
                    >
                      30sec
                    </option>
                    <option
                      value="0.58"
                      selected={
                        femaleData
                          ? femaleData.time === "0.58"
                            ? true
                            : false
                          : false
                      }
                    >
                      35sec
                    </option>
                    <option
                      value="0.66"
                      selected={
                        femaleData
                          ? femaleData.time === "0.66"
                            ? true
                            : false
                          : false
                      }
                    >
                      40sec
                    </option>
                    <option
                      value="0.75"
                      selected={
                        femaleData
                          ? femaleData.time === "0.75"
                            ? true
                            : false
                          : false
                      }
                    >
                      45sec
                    </option>
                    <option
                      value="0.83"
                      selected={
                        femaleData
                          ? femaleData.time === "0.83"
                            ? true
                            : false
                          : false
                      }
                    >
                      50sec
                    </option>
                    <option
                      value="0.91"
                      selected={
                        femaleData
                          ? femaleData.time === "0.91"
                            ? true
                            : false
                          : false
                      }
                    >
                      55sec
                    </option>
                    <option
                      value="1"
                      selected={
                        femaleData
                          ? femaleData.time === "1"
                            ? true
                            : false
                          : false
                      }
                    >
                      1min
                    </option>
                    <option
                      value="1.25"
                      selected={
                        femaleData
                          ? femaleData.time === "1.25"
                            ? true
                            : false
                          : false
                      }
                    >
                      1min 15sec
                    </option>
                    <option
                      value="1.33"
                      selected={
                        femaleData
                          ? femaleData.time === "1.33"
                            ? true
                            : false
                          : false
                      }
                    >
                      1min 20sec
                    </option>
                    <option
                      value="1.41"
                      selected={
                        femaleData
                          ? femaleData.time === "1.41"
                            ? true
                            : false
                          : false
                      }
                    >
                      1min 25sec
                    </option>
                    <option
                      value="1.5"
                      selected={
                        femaleData
                          ? femaleData.time === "1.5"
                            ? true
                            : false
                          : false
                      }
                    >
                      1min 30sec
                    </option>
                    <option
                      value="1.58"
                      selected={
                        femaleData
                          ? femaleData.time === "1.58"
                            ? true
                            : false
                          : false
                      }
                    >
                      1min 35sec
                    </option>
                    <option
                      value="1.66"
                      selected={
                        femaleData
                          ? femaleData.time === "1.66"
                            ? true
                            : false
                          : false
                      }
                    >
                      1min 40sec
                    </option>
                    <option
                      value="1.75"
                      selected={
                        femaleData
                          ? femaleData.time === "1.75"
                            ? true
                            : false
                          : false
                      }
                    >
                      1min 45sec
                    </option>
                    <option
                      value="1.83"
                      selected={
                        femaleData
                          ? femaleData.time === "1.83"
                            ? true
                            : false
                          : false
                      }
                    >
                      1min 50sec
                    </option>
                    <option
                      value="1.91"
                      selected={
                        femaleData
                          ? femaleData.time === "1.91"
                            ? true
                            : false
                          : false
                      }
                    >
                      1min 55sec
                    </option>
                    <option
                      value="2"
                      selected={
                        femaleData
                          ? femaleData.time === "2"
                            ? true
                            : false
                          : false
                      }
                    >
                      2min
                    </option>
                  </Form.Select>
                </Form.Group>
                <Row>
                  <Col xl={6}>
                    <Form.Group className="common-select">
                      <Form.Label>Suggested Weight (kg)</Form.Label>
                      <Form.Control
                        className="p-2"
                        placeholder="Suggested Weight (kg)"
                        id={"female_suggested_weight_kg"}
                        {...register("female_suggested_weight_kg", {
                          onChange: (e) => handleWeightKgFemale(e.target.value),
                        })}
                        type="number"
                        step=".01"
                        min="0"
                      />
                    </Form.Group>
                  </Col>

                  <Col xl={6}>
                    <Form.Group className="common-select">
                      <Form.Label>Suggested Weight (lbs)</Form.Label>
                      <Form.Control
                        className="p-2"
                        placeholder="Suggested Weight (lbs)"
                        id={"female_suggested_weight_lbs"}
                        {...register("female_suggested_weight_lbs", {
                          onChange: (e) =>
                            handleWeightLbsFemale(e.target.value),
                        })}
                        type="number"
                        step=".01"
                        min="0"
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col md={6}>
                    <Form.Group
                      className="common-select"
                      controlId="female_equipment_used"
                    >
                      <Form.Label className="d-block">
                        Equipment Used
                      </Form.Label>
                      <Select
                        isMulti
                        value={editFemaleSelectedEquipments}
                        ref={(ref) => {
                          let selectRefs = selectRef;
                          selectRefs.push(ref);
                          setSelectRef(selectRefs);
                        }}
                        options={
                          equipmentList &&
                          equipmentList.map((e) => ({
                            label: e.title,
                            value: e.id,
                          }))
                        }
                        className="basic-multi-select"
                        classNamePrefix="select"
                        onChange={femaleEquipmentUsedOpt}
                      />
                    </Form.Group>
                  </Col>
                  <Col md={6}>
                    <Form.Group className="common-select">
                      <Form.Label>Feel it Here</Form.Label>
                      <Select
                        id={"female_feel_it_here"}
                        value={femaleFeelItHere}
                        ref={(ref) => {
                          let selectRefs = selectRef;
                          selectRefs.push(ref);
                          setSelectRef(selectRefs);
                        }}
                        options={
                          bodyParts &&
                          bodyParts.map((e) => ({ label: e.name, value: e.id }))
                        }
                        className="basic-multi-select"
                        classNamePrefix="select"
                        onChange={setFemaleFeelItHereFunc}
                      />
                      {errors.female_feel_it_here && (
                        <Form.Text className="text-muted validationText hasError">
                          {errors.female_feel_it_here.message}
                        </Form.Text>
                      )}
                    </Form.Group>
                  </Col>
                </Row>

                <Form.Group className="common-input">
                  <Form.Label>Instructions</Form.Label>
                  <Form.Control
                    as="textarea"
                    rows={4}
                    className="p-2"
                    placeholder="instructions"
                    id={"female_instructions"}
                    {...register("female_instructions", {
                      maxLength: {
                        value: 500,
                        message: "Max length is 500",
                      },
                      onChange: (e) => {
                        setChanged(true);
                      },
                    })}
                    type="text"
                  />
                  {errors.female_instructions && (
                    <Form.Text className="text-muted validationText hasError">
                      {errors.female_instructions.message}
                    </Form.Text>
                  )}
                </Form.Group>

                <Form.Group className="common-input">
                  <Form.Label>Starting Position</Form.Label>
                  <div className={"ckeditor-container"}>
                    <CKEditor
                      id={"female_starting_position"}
                      {...register("female_starting_position", {})}
                      editor={ClassicEditor}
                      config={editorConfiguration}
                      onReady={(editor) => {
                        let editorRefs = ckeditorRef;
                        editorRefs.push(editor);
                        setCkeditorRef(editorRefs);
                      }}
                      onChange={(event, editor) => {
                        const data = editor.getData();
                        if (data !== previousFemaleStartingPosition.current) {
                          setFemaleStartingPosition(data);
                          setValue("female_starting_position", data);
                          setChanged(true);
                          previousFemaleStartingPosition.current = data;
                        }
                      }}
                      onBlur={(event, editor) => {
                        // console.log( 'Blur.', editor );
                      }}
                      onFocus={(event, editor) => {
                        // console.log( 'Focus.', editor );
                      }}
                      data={
                        femaleStartingPosition
                          ? femaleStartingPosition
                          : femaleData
                          ? femaleData.starting_position
                            ? femaleData.starting_position
                            : ""
                          : ""
                      }
                    />
                    {errors.female_starting_position && (
                      <Form.Text className="text-muted validationText hasError">
                        {errors.female_starting_position.message}
                      </Form.Text>
                    )}
                  </div>
                </Form.Group>

                <Form.Group className="common-input">
                  <Form.Label>Movements</Form.Label>
                  <div className={"ckeditor-container"}>
                    <CKEditor
                      id={"female_movement"}
                      {...register("female_movement", {})}
                      editor={ClassicEditor}
                      config={editorConfiguration}
                      onReady={(editor) => {
                        let editorRefs = ckeditorRef;
                        editorRefs.push(editor);
                        setCkeditorRef(editorRefs);
                      }}
                      onChange={(event, editor) => {
                        const data = editor.getData();
                        if (data !== previousFemaleMovement.current) {
                          setValue("female_movement", data);
                          setFemaleMovement(data);
                          setChanged(true);
                          previousFemaleMovement.current = data;
                        }
                      }}
                      onBlur={(event, editor) => {
                        // console.log( 'Blur.', editor );
                      }}
                      onFocus={(event, editor) => {
                        // console.log( 'Focus.', editor );
                      }}
                      data={
                        femaleMovement
                          ? femaleMovement
                          : femaleData
                          ? femaleData.movements
                            ? femaleData.movements
                            : ""
                          : ""
                      }
                    />
                    {errors.female_movement && (
                      <Form.Text className="text-muted validationText hasError">
                        {errors.female_movement.message}
                      </Form.Text>
                    )}
                  </div>
                </Form.Group>

                <Form.Group className="common-input">
                  <Form.Label>Notes</Form.Label>
                  <div className={"ckeditor-container"}>
                    <CKEditor
                      id={"female_notes"}
                      {...register("female_notes", {})}
                      editor={ClassicEditor}
                      config={editorConfiguration}
                      onReady={(editor) => {
                        let editorRefs = ckeditorRef;
                        editorRefs.push(editor);
                        setCkeditorRef(editorRefs);
                      }}
                      onChange={(event, editor) => {
                        const data = editor.getData();
                        if (data !== previousFemaleNotes.current) {
                          setValue("female_notes", data);
                          setFemaleNotes(data);
                          setChanged(true);
                          previousFemaleNotes.current = data;
                        }
                      }}
                      onBlur={(event, editor) => {
                        // console.log( 'Blur.', editor );
                      }}
                      onFocus={(event, editor) => {
                        // console.log( 'Focus.', editor );
                      }}
                      data={
                        femaleNotes
                          ? femaleNotes
                          : femaleData
                          ? femaleData.notes
                            ? femaleData.notes
                            : ""
                          : ""
                      }
                    />
                    {errors.female_notes && (
                      <Form.Text className="text-muted validationText hasError">
                        {errors.female_notes.message}
                      </Form.Text>
                    )}
                  </div>
                </Form.Group>

                <Form.Group className="common-input">
                  <Form.Label>Internal Notes</Form.Label>
                  <div className={"ckeditor-container"}>
                    <CKEditor
                      id={"female_internal_notes"}
                      {...register("female_internal_notes", {})}
                      editor={ClassicEditor}
                      config={editorConfiguration}
                      onReady={(editor) => {
                        let editorRefs = ckeditorRef;
                        editorRefs.push(editor);
                        setCkeditorRef(editorRefs);
                      }}
                      onChange={(event, editor) => {
                        const data = editor.getData();
                        if (data !== previousFemaleInternalNotes.current) {
                          setValue("female_internal_notes", data);
                          setFemaleInternalNotes(data);

                          setChanged(true);

                          previousFemaleInternalNotes.current = data;
                        }
                      }}
                      onBlur={(event, editor) => {
                        // console.log( 'Blur.', editor );
                      }}
                      onFocus={(event, editor) => {
                        // console.log( 'Focus.', editor );
                      }}
                      data={
                        maleInternalNotes
                          ? maleInternalNotes
                          : maleData
                          ? maleData.internal_notes
                            ? maleData.internal_notes
                            : ""
                          : ""
                      }
                    />
                    {errors.female_internal_notes && (
                      <Form.Text className="text-muted validationText hasError">
                        {errors.female_internal_notes.message}
                      </Form.Text>
                    )}
                  </div>
                </Form.Group>
              </div>
            </Col>
            <Form.Group className="common-input">
              <Form.Label>Status</Form.Label>
              <Form.Select
                value={isComplete}
                id={"isComplete"}
                className="form-select exercise-dropdown"
                aria-label="IsComplete"
                {...register("isComplete", {
                  onChange: (e) => {
                    setIsComplete(e.target.value);
                    setChanged(true);
                  },
                })}
              >
                <option value="0">Incomplete</option>
                <option value="1">Complete</option>
              </Form.Select>
            </Form.Group>
          </Row>
          {loader ? (
            <FullPageLoader loading={loader} />
          ) : (
            <Row className="mt-4 pb-3">
              <Col md={6} className={"text-start"}>
                <Button
                  className="btn common-btn"
                  color="inherit"
                  onClick={() =>
                    history.push("/edit-exercise/" + props.match.params.id)
                  }
                  //onClick={() => props.handleBack()}
                  sx={{ mr: 1 }}
                >
                  Back
                </Button>
              </Col>
              <Col md={{ span: 2, offset: 4 }} className={"text-end"}>
                <Button
                  className="common-btn"
                  type={"submit"}
                  id={"formUpdateExerciseSubmitBtn"}
                  disabled={disableButton}
                >
                  Save
                </Button>
              </Col>
            </Row>
          )}
        </Form>
      </section>
    </>
  );
}

export default EditLevelDetails;
