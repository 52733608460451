import React, { useEffect, useRef, useState } from "react";
import { Row, Col, Form, Button } from "react-bootstrap";
import { TrashIcon } from "../../../components/SvgIcons";

function VideoCommon({
  bodyMultiTypeFileVideo,
  setBodyMultiTypeFileVideo,
  setBodyMultiTypeVideos,
  bodyMultiTypeVideos,
  currentPlayingIndex,
  setCurrentPlayingIndex,
}) {
  // console.log("currentPlayingIndex", currentPlayingIndex);
  const videoRefs = useRef([]);
  const [videoUrls, setVideoUrls] = useState([]);

  useEffect(() => {
    // Create URLs for each file and update state
    const urls = bodyMultiTypeFileVideo.map(file => URL.createObjectURL(file));
    setVideoUrls(urls);

    // Cleanup: Revoke URLs when component unmounts or files change
    return () => {
      urls.forEach(URL.revokeObjectURL);
    };
  }, [bodyMultiTypeFileVideo]);
  //Multiple BodyType Video Upload  Function
  const handleBodyTypeVideo = (event) => {
    const files = event.target.files;
    const videos = [];
    const fileVideos = [];
    const maxVideos = Math.min(files.length, 12);
    for (let i = 0; i < maxVideos; i++) {
      const file = files[i];
      const reader = new FileReader();

      reader.onload = (e) => {
        const fileUrl = e.target.result;
        videos.push(fileUrl);
        fileVideos.push(file);

        if (videos.length === files.length) {
          setBodyMultiTypeVideos([...bodyMultiTypeVideos, ...videos]);
          setBodyMultiTypeFileVideo([...bodyMultiTypeFileVideo, ...fileVideos]);
          setCurrentPlayingIndex(-1);
        }
      };

      reader.readAsDataURL(file);
    }
  };

  const handleVideoClick = (index) => {
   
    const videoElement = videoRefs.current[index];
    if (videoElement) {
      videoElement.play();
    }
  };

  //Multiple Delete BodyType Video Upload  Function
  const deleteMultipleVideo = (index) => {
    const updatedVideos = [...bodyMultiTypeFileVideo];
    updatedVideos.splice(index, 1);
    setBodyMultiTypeFileVideo(updatedVideos);
  };


  return (
    <Row>
      <Col md={12}>
        <span>Body Type Video (up to 12)</span>
        <Form.Group
          className="custom-file-upload mt-0"
          controlId="bodyMultiTypeVideos"
          onChange={handleBodyTypeVideo}
        >
          <Form.Label className={"common-btn"}>Upload Video</Form.Label>
          <Form.Control hidden type="file" accept="video/*" multiple />
        </Form.Group>
        <div className="upload-video">
          {videoUrls && videoUrls.length > 0 ? (
            videoUrls.map((url, index) => (
              <div className="image-item" key={index}>
                <video
                  className={"img-table img-thumbnail"}
                  controls
                  title={`Video ${index + 1}`}
                  autoPlay={currentPlayingIndex === index}
                  src={url}
                  ref={el => videoRefs.current[index] = el}
                  onClick={() => handleVideoClick()}
                ></video>

                <div className="image-item__btn-wrapper">
                  <Button
                    type="Button"
                    className="common-btn"
                    onClick={() => deleteMultipleVideo(index)}
                  >
                    <TrashIcon />
                  </Button>
                </div>
              </div>
            ))
          ) : (
            <span>No videos uploaded</span>
          )}
        </div>
      </Col>
    </Row>
  );
}

export default VideoCommon;
